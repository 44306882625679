import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { useGetPublicOrderMutation } from 'store/reducer/services/order';
import { OrderTimeline } from './track';

function PublicTrack() {
  const { orderId } = useParams();
  const [getOrder, { data }] = useGetPublicOrderMutation();

  useEffect(() => {
    if (orderId) {
      getOrder({ payload: { key: orderId } });
    }
  }, [getOrder, orderId])

  return (
    <>
      <OrderTimeline isSignedIn={false} data={data} />
    </>
  )
}

export default PublicTrack