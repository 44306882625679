import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const DOMAIN = `${process.env.REACT_APP_BACKEND_HOST}`;

export const STATUS_CONSTANTS = {
  1: 'active',
  2: 'trial',
  3: 'suspended',
  4: 'deactivated'
}

const initialState = {
  status: null,
  agents: null,
  subscriptionEndDate: null,
  notifications: null,
  isSubscriptionOver: null,
  trialDaysLeft: 0,
  agentsConsumed: 1,
}

export const getSubscriptionDetails = createAsyncThunk(
  'subscription/info',
  async () => {
    try {
      const res = await axios({
        url: `${DOMAIN}/api/subscription/company`, method: 'GET',
        withCredentials: true,
      });
      return res.data;
    } catch (e) {
      console.log(e)
    }
  }
)

export const updateNotifications = createAsyncThunk(
  'notifications/update',
  async (payload) => {
    console.log("PAYLOAD", payload)
    try {
      const res = await axios({
        url: `${DOMAIN}/api/subscription/manage/notifications`, method: 'PUT',
        withCredentials: true,
        data: payload
      });
      return res.data;
    } catch (e) {
      console.log(e)
    }
  }
)

export const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getSubscriptionDetails.fulfilled, (state, action) => {
      if (action.payload) {
        const { status, agents, subscriptionEndDate, notifications, agentsConsumed } = action.payload;
        state.agents = agents;
        state.notifications = notifications;
        state.status = STATUS_CONSTANTS[status]
        state.subscriptionEndDate = subscriptionEndDate;
        state.agentsConsumed = agentsConsumed;
        const today = new Date();
        const tempSubscriptionEndDate = new Date(subscriptionEndDate);
        const diffTime = (tempSubscriptionEndDate - today);
        state.isSubscriptionOver = diffTime < 0;
        if (status === 2) {
          state.trialDaysLeft = diffTime < 0 ? 0 : Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        }
      }
    }).addCase(getSubscriptionDetails.rejected, (state) => {
      state = initialState
    }).addCase(updateNotifications.fulfilled, (state, action) => {
      const { notifications } = action.payload;
      state.notifications = notifications;
    })
  }
});

export default subscriptionSlice.reducer;