import React from 'react';
import { styled } from '@mui/system';
import { InputLabel } from '@mui/material';

const TextAreaStyles = styled('textarea')(
  ({ theme }) => `
  width: 100%;
  border: 0;
  border-radius: ${theme.shape.borderRadius}px;
  background-color: rgba(0, 0, 0, 0.06);
  resize: none;
  padding: 12px;
  box-sizing: border-box;
`
);

function TextArea({
  value = '',
  placeholder = '',
  rows = 3,
  handleChange,
  label,
  className = '',
  id = '',
}) {
  const [ipValue, setIpValue] = React.useState(value);
  const localHandleChange = (event) => {
    setIpValue(event.target.value);
  };
  return (
    <div className={className}>
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <TextAreaStyles
        rows={rows}
        placeholder={placeholder}
        value={value || ipValue}
        onChange={handleChange || localHandleChange}
      />
    </div>
  );
}

export { TextArea };
