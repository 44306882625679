import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setHeader, setBottomSheetComponent } from 'store/reducer/layout';
import styles from './billing.module.scss';
import AgentIcon from 'assets/icons/agent.svg'
import EmailIcon from 'assets/icons/mail.svg'
import SMSIcon from 'assets/icons/sms.svg'
import { ButtonGroup, Button } from '@tt/fe-common';
import { Drawer } from '@mui/material';
import Pricing from './pricing';
import { getSubscriptionDetails } from '../../../store/reducer/subscription';
import { useMediaQuery } from '@mui/material';
import Purchase from './purchase';
import { useCurrentUserQuery } from '../../../store/reducer/services/signin';

export default function PlansAndBilling() {
  const dispatch = useDispatch();
  const [openPurchaseModal, setOpenPurchaseModal] = useState(false);
  const [isAddOn, setIsAddOn] = useState(false);
  const { status, agents, notifications, isSubscriptionOver, trialDaysLeft, agentsConsumed } = useSelector((state) => state?.subscription)
  const { data: userDetails } = useCurrentUserQuery();
  useEffect(() => {
    dispatch(getSubscriptionDetails());
    dispatch(setHeader('Plans And Billing'));
  }, []);
  const forMobile = useMediaQuery('(max-width:576px)');
  PlansAndBilling.BottomDrawerChild = [
    () => (
      <Purchase
        isAddOn={isAddOn}
        userDetails={userDetails}
        setOpenPurchaseModal={setOpenPurchaseModal} />
    )
  ];
  return <>
    <span>Explore plan features, buy add-ons and upgrade to higher plans from here</span>
    <div className={`${styles.PlanDescription} px-16 py-16 mt-16 rounded-xl font-medium md:px-20 md:flex md:justify-between`}>
      <div>
        <span className='opacity-90'>Current Plan</span>
        <div className="flex items-center mt-16 md:justify-between">
          <div className="flex">
            <span className="font-bold mr-3 text-2xl">Starter</span>
            <div className={`${styles.PlanName} font-semibold text-lg`}>
              <span >{status}</span>
            </div>
          </div>
        </div>
        <div className="md:flex">
          <div className="mt-16 flex items-center ">
            <img src={AgentIcon} className="mr-1.5" />
            <span className={`${styles.IconLabel} mr-1.5`}>Agents: </span>
            <span className="font-bold">{`${agentsConsumed} / ${agents}`}</span>
            <span className={`${styles.Dot} hidden mx-2 md:block`}></span>
          </div>
          <div className="mt-16 flex items-center">
            <img src={EmailIcon} className="mr-1.5" />
            <span className={`${styles.IconLabel} mr-1.5`}>Email: </span>
            <span className="font-bold">{notifications?.email.availableCount}</span>
            <span className={`${styles.Dot} hidden mx-2 md:block`}></span>
          </div>
          <div className="mt-16 flex items-center">
            <img src={SMSIcon} className="mr-1.5" />
            <span className={`${styles.IconLabel} mr-1.5`} >SMS: </span>
            <span className="font-bold">{notifications?.sms.availableCount}</span>
          </div>
        </div>
      </div>
      <div className="mt-32 md:flex items-center">
        {status === 'trial' && <span className="flex items-center justify-content-center md:mr-2">Trial Expires in {trialDaysLeft} days</span>}
        {status !== 'active' && <ButtonGroup
          primaryCta="Buy Plan"
          secondaryCta="Buy add-on"
          primaryFn={() => {
            setIsAddOn(false);
            setTimeout(() => {
              forMobile ? dispatch(setBottomSheetComponent(0)) : setOpenPurchaseModal(true)
            }, [10])
          }}
          secondaryFn={() => {
            setIsAddOn(true);
            setTimeout(() => {
              forMobile ? dispatch(setBottomSheetComponent(0)) : setOpenPurchaseModal(true)
            }, [10])
          }} />}
        {
          status === 'active' && <Button variant="contained" className="flex justify-center" onClick={() => {
            setIsAddOn(true);
            setTimeout(() => {
              forMobile ? dispatch(setBottomSheetComponent(0)) : setOpenPurchaseModal(true)
            }, [10])
          }}
            color="info" type="submit">Buy add-on</Button>

        }
      </div>
    </div>
    <Drawer
      anchor="right"
      open={openPurchaseModal}
      onClose={() => setOpenPurchaseModal(false)}
    >
      <Purchase
        isAddOn={isAddOn}
        userDetails={userDetails}
        setOpenPurchaseModal={setOpenPurchaseModal} />
    </Drawer>
    <div>
      <Pricing />
    </div>
  </>
};
PlansAndBilling.fab = true;