import { Button } from '@tt/fe-common';

export default function AddProcess() {
  return (
    <div className="display-flex justify-content-space-around">
      <Button size="full">Add process</Button>
      <Button size="full" variant="none">Cancel</Button>
    </div>
  );
}
