import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../axiosUtility';

export const productAPI = createApi({
  reducerPath: 'product',
  baseQuery: axiosBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_HOST}/api/product`,
  }),
  tagTypes: ['Product'],
  endpoints: (build) => ({
    getProducts: build.query({
      query: () => ({ url: '/company/listProducts', method: 'GET' }),
      providesTags: (result, error, arg) =>
        result
          ? [...result.map(({ id }) => ({ type: 'Product', id })), 'Product']
          : ['Product'],
    }),
    addProduct: build.mutation({
      query: (body) => ({
        url: `/create`,
        method: 'POST',
        data: body,
      }),
      invalidatesTags: ['Product'],
    }),
    updateProduct: build.mutation({
      query: ({ id, ...body }) => ({
        url: `/${id}`,
        method: 'PUT',
        data: body['payload'],
      }),
      invalidatesTags: ['Product'],
    }),
    deleteProduct: build.mutation({
      query: ({ id }) => ({
        url: `/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Product'],
    })
  }),
});

export const {
  useAddProductMutation,
  useGetProductsQuery,
  useUpdateProductMutation,
  useDeleteProductMutation
} = productAPI;
