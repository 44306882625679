.full-width {
  width: 100%;
}

.min-width {
  width: 30%;
}

@media only screen and (max-width: 640px) {
  .min-width {
    width: 40%;
  }
}

.exact-width {
  width: fit-content;
}