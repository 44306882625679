import React, { useEffect, useState } from 'react';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useMediaQuery } from '@mui/material';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { setHeader, setBottomSheetComponent } from 'store/reducer/layout';
import { fetchOrders, setDeviceType, setCurrentPage } from 'store/reducer/orders';
import Chip from '@mui/material/Chip';
import ChangeProcessStatus from './changeProcessStatus';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';import KfTable from '../../components/commom/KfTable';
import KfcPagination from '../../components/commom/kfPagination';
import { Ring } from '@tt/fe-common';
import Tooltip from '@mui/material/Tooltip';
import SearchComponent from './searchComponent';
import AsignProcessToEmployee from './assignProcessToEmployee';
import AsignProcessMobile from './assignProcessMobile';
import ProcessUpdate from './processUpdate';
import styles from './orders.module.scss';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Card } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Avatar from 'components/commom/Avatar';
import NoOrder from '../../assets/icons/no-order.svg';

export const ProcessStatus = Object.freeze({
  YET_TO_START: 0,
  IN_PROGRESS: 1,
  ON_HOLD: 2,
  COMPLETED: 3
});

const getChipStatus = (status) => {
  switch (status) {
    case 0:
      return <Chip label="Yet to start" color="error" variant="outlined" style={{ backgroundColor: '#FF6C6C10' }} />
    case 1:
      return <Chip label="In Progress" color="success" variant="outlined" style={{ backgroundColor: '#5C913B10' }} />
    case 2:
      return <Chip label="On Hold" color="warning" variant="outlined" style={{ backgroundColor: '#FFC70010' }} />
    default:
      return <Chip label="On Hold" color="info" variant="outlined" />
  }
}
export default function Orders() {
  const [orders, setOrders] = useState([]);
  const [searchObject, setSearchObject] = useState([]);
  const [rows, setRows] = useState([]);
  const showCards = useMediaQuery('(max-width:576px)');
  const [selectedProduct, setSelectedProduct] = useState({});
  const [selectOrder, setSelectOrder] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { docs, totalPages, totalDocs, currentPage, isLoading } = useSelector(state => state.orders);
  useEffect(() => {
    const payload = { page: currentPage }
    dispatch(fetchOrders(payload));
  }, []);

  useEffect(() => {
    setOrders([...docs]);
  }, [docs]);

  useEffect(() => {
    dispatch(setDeviceType(showCards));
  }, [showCards]);


  useEffect(() => {
    dispatch(setHeader('Orders'));
  });
  useEffect(async () => {
    const payload = { page: currentPage }
    if (searchObject?.length) {
      payload.query = [...searchObject]
    }
    dispatch(fetchOrders(payload));
    setOrders([...docs]);
  }, [currentPage]);

  const getFormattedSearchObject = () => {
    const cloneArray = JSON.parse(JSON.stringify(searchObject));
    const hasAssignedTo = cloneArray.find(({ field }) => field === 'assignedTo.employeeId');
    if (hasAssignedTo) {
      hasAssignedTo.criteria = hasAssignedTo.criteria.map(({ employeeId }) => employeeId);
      const index = cloneArray.findIndex(({ field }) => field === 'assignedTo.employeeId');
      cloneArray[index] = hasAssignedTo;
    }
    return cloneArray;
  }

  useEffect(async () => {
    const payload = searchObject?.length
      ? { query: [...getFormattedSearchObject()] }
      : {};
    dispatch(fetchOrders(payload));
    setOrders([...docs]);
  }, [searchObject]);

  useEffect(() => {
    getRows(orders);
  }, [orders]);

  const config = {
    estimatedEndTime: "Delivery date:",
    customerName: "Customer:",
    processName: "Process:",
  };

  const getNameIfProcessisCompleted = (plist, order) => {
    const { processName, status } = plist?.find(({ status }) => status !== ProcessStatus.COMPLETED);
    return <div className="flex items-center justify-between"><span>{processName} </span>
      <div className="flex items-center">{getChipStatus(status)}
        <ChangeProcessStatus order={order} /></div>
    </div>;
  };

  const handleOrderClick = (orderId) => {
    navigate(`/orders/track/${orderId}`)
  }

  const history = useNavigate();

  const columns = [
    {
      id: 'productName',
      numeric: false,
      disablePadding: true,
      label: 'Product Name',
      width: "300px",
      customComponent: (row) => {
        const { id, productName } = row;
        const url = `/orders/track/${id}`
        return <Link to={url} className="text-sky-500">{productName}</Link>
      }
    },
    {
      id: 'estimatedEndTime',
      numeric: false,
      disablePadding: false,
      label: 'Delivery Date',
      width: "300px",
    },
    {
      id: 'assignedTo',
      disablePadding: false,
      label: 'Assigned To',
      width: "250px",
      customComponent: (row) => {
        const { assignedTo, productName, id, processList } = row;
        const { processName } = processList?.find(({ status }) => status !== ProcessStatus.COMPLETED);
        if (assignedTo) {
          const { employeeId, avatar, name } = assignedTo
          return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} className={styles.assingToContainer}>
              <div className={styles.employee}>
                {!!name ? <Avatar name={name} size={24}></Avatar> : <>NA</>}
                <span>{name}</span>
              </div>
              <AsignProcessToEmployee productName={productName} orderId={id} rows={rows} setRows={setRows} processName={processName} />
            </div>)
        } else {
          return (<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <span>Not Assigned</span>
            <AsignProcessToEmployee productName={productName} orderId={id} rows={rows} setRows={setRows} processName={processName} />
          </div>)
        }
      },
    },
    {
      id: 'percentageCompleted',
      numeric: true,
      disablePadding: false,
      label: 'Progress',
      align: 'center',
      customComponent: (row) => {
        return (
          <div className="flex justify-center">
            <Tooltip
              title={`${row.percentageCompleted} % completed`}
              placement="bottom"
            >
              <span>
                <Ring percent={row.percentageCompleted} color="green" />
              </span>
            </Tooltip>
          </div>
        );
      },
    },
    {
      id: 'quantity',
      numeric: true,
      disablePadding: false,
      label: 'Quantity',
      align: 'center',
    },
    {
      id: 'process',
      numeric: false,
      disablePadding: false,
      label: 'Process',
      width: "300px",
      customComponent: (row) => {
        const { processList } = row
        return (
          <div>
            {processList?.length ? getNameIfProcessisCompleted(processList, row) : 'No process'}
          </div>
        );
      },
    },
    {
      id: 'customerName',
      numeric: false,
      disablePadding: false,
      label: 'Customer',
    },
    {
      id: 'email',
      numeric: false,
      disablePadding: false,
      label: 'Email',
    },
    {
      id: 'address',
      numeric: false,
      disablePadding: false,
      label: 'Address',
      width: "200px",
    },
  ];

  const getRows = (orders) => {
    const rows = orders.map(
      ({
        productName,
        estimatedEndTime,
        quantity,
        percentageCompleted,
        customerName,
        email,
        address,
        id,
        assignedTo, processList
      }) => {
        return {
          productName,
          estimatedEndTime: moment
            .utc(estimatedEndTime)
            .local()
            .format('ddd - MMM DD, hh:mm A'),
          customerName,
          email,
          quantity,
          percentageCompleted,
          address,
          id,
          assignedTo,
          processList
        };
      }
    );
    setRows(rows);
  };

  Orders.BottomDrawerChild = [
    () => (
      <ProcessUpdate
        selectedOrder={selectOrder}
      />
    ),
    () => (
      <AsignProcessMobile
        productName={selectedProduct.productName}
        processName={selectedProduct.processList?.find(({ status }) => status !== ProcessStatus.COMPLETED).processName}
        orderId={selectedProduct.id}
      />
    )
  ];

  const listAgents = (data, id) => {
    const payload = {
      productName: data,
      id: id
    }
    setSelectedProduct(payload);
    setTimeout(() => {
      dispatch(setBottomSheetComponent(1))
    }, 20);
  }

  const handleUpdateProcess = (order) => {
    setSelectOrder(order);
    setTimeout(() => {
      dispatch(setBottomSheetComponent(0))
    }, 20);
  }

  const setPage = (pageNumber) => {
    dispatch(setCurrentPage(pageNumber + 1));
  }

  const displayOrders = () => {
    if (showCards) {
      return orders.map((order) => {
        const {
          id,
          productName,
          percentageCompleted,
          estimatedEndTime,
          customerName,
          assignedTo
        } = order;
        const data = {
          estimatedEndTime: moment
            .utc(estimatedEndTime)
            .local()
            .format('ddd - MMM DD YYYY, hh:mm A'),
          processName: order.processList[0].processName,
          customerName: customerName,
        };
        return (
          <InfiniteScroll dataLength={totalDocs} next={() => {
            if (!isLoading) {
              setTimeout(() => {
                dispatch(setCurrentPage(currentPage + 1));
              }, 10);
            }
          }}
            hasMore={currentPage * 10 < totalDocs} >
            <div style={{ marginBottom: 16 }}>
              <Card variant="kfCard" >
                <div className={styles.cardContainer}>
                  <div className={styles.cardHeader}>
                    <div className={styles.left}>
                      <span className={styles.heading} onClick={() => {
                        handleOrderClick(id)
                      }} >{productName}</span>
                    </div>
                    <div className="flex items-center">

                      <div className={styles.right}>
                        <span>
                          <Ring percent={+percentageCompleted} color={Math.round(+percentageCompleted) < 50 ? "red" : "green"} />
                        </span>
                        <span className={styles.percent}>
                          {Math.round(+percentageCompleted)} %
                        </span>
                      </div>
                      <MoreHorizIcon onClick={() => handleUpdateProcess(order)} />
                    </div>
                  </div>

                  <div className={styles.cardBody}>
                    {
                      Object.keys(config).map((key, index) => (
                        <div key={key} className={styles.cards}>
                          <span className={styles.key}>{config[key]}</span>
                          {index === 2 ?
                            <div className={styles.specialBlock}>
                              <span className={styles.value}>{data[key]}</span>
                              <div className={styles.specialBlock} onClick={() => listAgents(productName, id)}>
                                <span className={styles.special}>{assignedTo?.name ? ` - ${assignedTo?.name}` : ' - Not Assigned'}</span>
                                <img src="/icons/svgs/edit.svg" className={styles.editIcon} />
                              </div>
                            </div>
                            :
                            <span className={styles.value}>{data[key]}</span>}
                        </div>
                      ))
                    }
                  </div>
                </div>
              </Card>
            </div>
          </InfiniteScroll>
        );
      });
    } else {
      return (
        <div>
          <KfTable columns={columns} rows={rows} ></KfTable>
          <KfcPagination count={totalPages} setPage={setPage} />
        </div>
      );
    }
  };
  return (
    <div>
      {orders.length > 0 ? <SearchComponent setSearchObject={setSearchObject} /> : <></>}
      {/* <SearchComponent setSearchObject={setSearchObject} /> */}
      {orders.length > 0 ? displayOrders() : 
      <>
        <div className={styles.noRecords}>
          <div><img src={NoOrder} /></div>
          <h1>No Orders Found</h1>
          <p>Looks like you haven't created any orders yet...</p>
          <Button
                variant="outlined"
                color="info"
                onClick={() => {
                  history('/order/new');
                }}
              >Create Order</Button>
        </div>
      </>
      }
    </div>
  );
}

Orders.fab = true;

