import React from 'react';
import { Backdrop, SpeedDial, SpeedDialIcon } from '@mui/material';
import { useNavigate, Link } from 'react-router-dom';
import cx from 'classnames';
import Box from '@mui/material/Box';
import { createStyles, makeStyles } from '@mui/styles';

import checkIcon from 'assets/icons/dark/check.svg';
import messageIcon from 'assets/icons/dark/message.svg';
import shoppingCartIcon from 'assets/icons/dark/shopping-cart.svg';
import userPlusIcon from 'assets/icons/dark/user-plus.svg';
import userIcon from 'assets/icons/dark/user.svg';
import NewProcess from '../product/process/newProcess';
import { setBottomSheetComponent } from 'store/reducer/layout';
import { useDispatch } from 'react-redux';
import AddCustomer from '../order/customer/new';

import './fab.scss';

const actions = [
  {
    link: '/order/new',
    iconSrc: messageIcon,
    text: 'New Order'
  },
  {
    link: '/product/new',
    iconSrc: shoppingCartIcon,
    text: 'New Product'
  },
  {
    drawerComponentIndex: 0,
    iconSrc: checkIcon,
    text: 'New Process'
  },
  {
    drawerComponentIndex: 1,
    iconSrc: userIcon,
    text: 'New Customer'
  },
]

const useStyles = makeStyles(theme => {
  return createStyles({
    container: {
      backgroundColor: theme.palette.info.main,
      borderRadius: theme.shape.borderRadius + 'px',
      '& *': {
        color: theme.palette.info.contrastText
      }
    }
  })
});

export default function KfFab() {
  const history = useNavigate();
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();

  KfFab.BottomDrawerChild = [
    () => <NewProcess fromLayout={true} />,
    () => <AddCustomer fromLayout={true} />
  ]

  return (
    <Box sx={{ display: { xs: 'block', md: 'none' } }}>
      <Backdrop open={open} />
      <SpeedDial
        ariaLabel="Add menu"
        sx={{ position: 'absolute', bottom: '1rem', right: '2.75rem' }}
        icon={<SpeedDialIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
      >
        <div className={cx('sd-container', 'px-24 py-8', classes.container)}>
          {actions.map(action => {
            if (action.link) {
              return (
                <Link to={action.link} onClick={handleClose}>
                  <div className="sd-item display-flex">
                    <img className="my-16" src={action.iconSrc} />
                    <p className="px-8">{action.text}</p>
                  </div>
                </Link>
              )
            } else {
              return (
                <div className="sd-item display-flex" onClick={() => dispatch(setBottomSheetComponent({ isFab: true, index: action.drawerComponentIndex }))}>
                  <img className="my-16" src={action.iconSrc} />
                  <p className="px-8">{action.text}</p>
                </div>
              )
            }
          })}
        </div>
      </SpeedDial>
    </Box>
  );
}
