
import { useRef, useState } from 'react';
import { SimpleInput, Button } from '@tt/fe-common';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import ErrorContainer from '../../utils/error';
import { useRequestPasswordResetMutation } from '../../store/reducer/services/employee';
const ForgotPassword = () => {
  const [isRequestSent, setIsRequestSent] = useState(false);
  const [resetPasswordRequest, { isLoading }] = useRequestPasswordResetMutation();
  const [passwordResetErrors, setPasswordResetErrors] = useState([]);
  const formRef = useRef();
  const initialValues = {
    email: '',
  }

  const validationSchema = Yup.object({
    email: Yup.string()
      .email('Invalid email format')
      .required('Email id is Required'),
  });


  const handleSubmit = async (values) => {
    try {
      const payload = { ...values };
      await resetPasswordRequest(payload).unwrap();
      formRef.current.resetForm();
      setIsRequestSent(true);
    } catch (e) {
      setPasswordResetErrors([...e.data.errors]);
    }
  }
  return (
    <section className="display-flex min-h-screen">
      <div className="hidden md:block bg-emerald-900 w-6/12 px-16">
        <img src="/icons/svgs/logo.svg" className="mt-16 " />
        <div className="display-flex justify-content-center items-center flex-col mt-16">
          <p className="text-2xl text-slate-50">Status Notified Easier</p>
          <p className="text-slate-50 mt-8 mb-32">Its free and easy</p>
        </div>
        <img src="/icons/svgs/signinillustration.svg" className="m-auto " />
      </div>
      <div className="w-full md:w-6/12 md:px-36 m-auto">
        <div className="display-flex flex-col items-center">
          <span >{!isRequestSent ? '🔒' : '🔐'}</span>
          <p className="text-2xl">{!isRequestSent ? 'Forgot Password' : 'Success'}</p>
        </div>
        {!isRequestSent && <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={validationSchema}
          innerRef={formRef}
          onSubmit={handleSubmit}
        >

          {({ errors, touched, values, handleChange, handleBlur, handleSubmit }) => (
            <Form className="px-24">
              <SimpleInput
                label="Enter Email Address"
                className="mt-32"
                size="full-width"
                name="email"
                value={values.email}
                onBlur={handleBlur('email')}
                handleChange={handleChange('email')}
              />
              {errors.email && touched.email ? (
                <ErrorContainer>{errors.email}</ErrorContainer>
              ) : null}
              <Button color='info' className='mt-5' size="full"
                onClick={() => {
                  formRef.current.handleSubmit();
                }}>
                Send Link
              </Button>
              <ErrorContainer className="mt-5">{passwordResetErrors.length > 0 && passwordResetErrors.map(({ message }) => message)}</ErrorContainer>
            </Form>
          )}
        </Formik>}

        {isRequestSent && (
          <div className="display-flex flex-col items-center">
            <img src="/icons/svgs/success.svg" className="mt-16 " />
            <span className="mt-16 px-16">A link to reset your password has been sent to your registered email ID. <span style={{ color: '#4C6FFF' }} onClick={() => { setIsRequestSent(false) }}>Click here </span>If you haven’t recieved the link.</span>
          </div>
        )}

      </div>
    </section >
  )
}

export default ForgotPassword;
