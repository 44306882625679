import faker from 'faker';
import format from 'date-fns/format'

export default function (processCount) {
  return {
    process: new Array(processCount)
      .fill(0)
      .map(() => {
        const timeStamp = format(faker.date.future(), 'ccc - PP');
        return {
          product: faker.commerce.productName(),
          name: faker.name.findName(),
          timeStamp,
          process: faker.commerce.productMaterial(),
          src: faker.image.avatar()
        };
      })
  }
}