import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/styles';
import { useMediaQuery, Typography, Badge } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import cx from 'classnames';
import NotificationsIcon from '@mui/icons-material/Notifications';

import KfBreadcrumb from 'components/KfBreadcrumb';
import { toggleMenu, LEFT_ACTION, setLeftAction } from 'store/reducer/layout';

import styles from './layout.module.scss';
import hamburgerIcon from 'assets/icons/light/hamburger.svg';
import backIcon from 'assets/icons/light/back.svg';
import CreateItems from './header/createComponent';

export default function Header({ setCreateNewProcess, setCreateNewCustomer, setUserInfo, userDetails }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const layoutState = useSelector(state => state.layout);
  const breakpointMd = useMediaQuery(theme.breakpoints.up('md'));
  const options = ['Product', 'Order', 'Customer', 'Process'];
  const showDropDown = useMediaQuery('(max-width:576px)');

  let LeftAction;
  switch (layoutState.leftAction) {
    case LEFT_ACTION.HAMBURGER:
      LeftAction = () => <img src={hamburgerIcon} onClick={() => dispatch(toggleMenu())} />;
      break;
    case LEFT_ACTION.BACK:
      LeftAction = () => <img src={backIcon} onClick={() => {
        dispatch(setLeftAction(LEFT_ACTION.HAMBURGER));
        navigate(-1)
      }} />;
  }

  return (
    <div className='shadow pb-2'>
      <div className={cx(styles.header, 'px-24 pt-16 flex md:flex-row-reverse')}>
        {
          !breakpointMd
          && <LeftAction />
        }
        {showDropDown &&
          <>
            <Typography variant="h1"><strong>{layoutState.head}</strong></Typography>
            <div>
              <Badge badgeContent={4} color="error">
                <NotificationsIcon color="info" />
              </Badge>
            </div>
          </>
        }
        {!showDropDown &&
          <CreateItems setUserInfo={setUserInfo}
            setCreateNewProcess={setCreateNewProcess}
            setCreateNewCustomer={setCreateNewCustomer}
            userDetails={userDetails} />
        }
        <div className="ml-4" > {!showDropDown && <Typography variant="h4"><strong>{layoutState.head}</strong></Typography>}</div>
      </div>
    </div>
  );
}
