import React from 'react';
import PropTypes from 'prop-types';
import { Button as MuiButton, InputLabel as MuiInputLabel } from '@mui/material';
import cx from 'classnames';
import { styled } from '@mui/system';
import { dottedBorder } from '../../utils/styling';
import styles from './styles.module.css';

const OPTS = {
  SIZE: {
    FULL: 'full',
    EXACT: 'exact',
    MIN: 'min'
  }
};

export const Button = function (props) {
  const { children, variant, label, size, dashed, className, noShadow, ...otherProps } = props;
  let classes = '';

  const StyledButton = styled(MuiButton)(({ theme }) => `
    box-sizing: border-box;
    background-image: ${dashed ? dottedBorder() : 'none'};
    min-width: ${size === 'full' ? '100%' : '150px'};
    box-shadow: ${noShadow ? 'none' : theme.shadows[1]};
  `);

  const InputLabel = styled(MuiInputLabel)`
    margin-bottom: 4px;
  `;

  switch (size) {
    case OPTS.SIZE.FULL:
      classes = cx(classes, styles['full-width']);
      break;
    case OPTS.SIZE.MIN:
      classes = (classes, styles['min-width']);
      break;
    case OPTS.SIZE.EXACT:
      classes = (classes, styles['exact-width']);
      break;
    default:
      break;
  }

  return (
    <div className={cx(classes, className)}>
      {label && <InputLabel>{label}</InputLabel>}
      <StyledButton
        variant={variant}
        {...otherProps}
      >
        {children}
      </StyledButton>
    </div>
  );
}

Button.muiName = MuiButton.muiName;

Button.propTypes = {
  variant: PropTypes.oneOf([
    'contained',
    'outlined',
    'none'
  ]),
  color: PropTypes.oneOf([
    'info',
    'cancel',
    'light'
  ]),
  dashed: PropTypes.bool,
  size: PropTypes.oneOf(Object.values(OPTS.SIZE)),
  noShadow: PropTypes.bool
}

Button.defaultProps = {
  variant: 'contained',
  color: 'light',
  dashed: false,
  noShadow: true
};

export { InputHybrid as InputHybridButton } from './inputHybrid';
