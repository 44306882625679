import React, { useState, useEffect } from 'react';
import './styles.scss';
import { useDispatch } from 'react-redux';
import { useMediaQuery, Drawer } from '@mui/material';
import { setHeader } from 'store/reducer/layout';
import { useGetAllProcessQuery, useDeleteProcessMutation, processAPI } from '../../store/reducer/services/process';
import { SimpleInput } from '@tt/fe-common';
import { Search } from '@mui/icons-material';
import RemoveIcon from 'assets/icons/remove.svg'
import EditIcons from 'assets/icons/edit.svg'
import styles from './styles.module.scss';
import NewProcess from 'pages/product/process/newProcess';
import { setBottomSheetComponent } from 'store/reducer/layout';
import NoProcess from '../../assets/icons/no-process.svg';
import Button from '@mui/material/Button';


export default function Process() {

  const { data, isFetching } = useGetAllProcessQuery();
  const [processList, setProcessList] = useState([]);
  const dispatch = useDispatch();
  const isMobile = useMediaQuery('(max-width:576px)');
  const [processToBeEdited, setProcessToBeEdited] = useState(null);
  const [deleteProcess] = useDeleteProcessMutation();
  const [editProces, setEditProcess] = useState(false);
  const { refetch } = processAPI.endpoints.getAllProcess.useQuerySubscription();


  useEffect(() => {
    dispatch(setHeader('Process'));
    refetch();
  }, []);

  Process.BottomDrawerChild = [
    () => <NewProcess fromLayout={true} isEdit={true} processToBeEdited={processToBeEdited} />
  ];

  useEffect(() => {
    setProcessList(data);
  }, [data]);

  useEffect(() => {
    return () => {
      setProcessList([]);
    }
  }, []);

  const removeProcess = async (id) => {
    await deleteProcess({ id }).unwrap();
  }


  const editProcess = (process) => {
    setProcessToBeEdited(process);
    if (isMobile) {
      setTimeout(() => {
        dispatch(setBottomSheetComponent(0));
      }, 100);
    } else {
      setEditProcess(true);
    }
  }


  const filterProcess = (searchText) => {
    if (searchText) {
      const searchedProcesses = data.filter((process) => {
        return process.processName
          .toLowerCase()
          .includes(searchText.toLowerCase());
      });
      setProcessList(searchedProcesses);
    } else {
      setProcessList(data);
    }
  }

  const searchProcess = () => {
    if(processList?.length) {
      return (
        <SimpleInput size="full-width"
          handleChange={filterProcess}
          variant="with-icon"
          placeholder="Search Process..."
          IconComponent={Search}
        />
      )
    }
  }

  const renderProcessList = () => {
    if (processList?.length) {
      return processList.map((process) => {
        const { processName, timeSpan, unit, id } = process
        return (
          <div className="flex my-16">
            <img src={RemoveIcon} alt="remove" className="mr-16 cursor-pointer" onClick={() => removeProcess(id)} />
            <div className={`${styles.processContainer} py-16 w-full px-16 flex justify-between`}>
              <div>
                <div>
                  <span className="font-semibold text-base">{processName}</span>
                </div>
                <div className="mt-8 text-sm">
                  <span className="font-normal">Time to complete: </span>
                  <span className="font-medium">{`${timeSpan} ${unit}`}</span>
                </div>
              </div>
              <div className={`${styles.editContainer} cursor-pointer flex 
              justify-around items-center font-medium text-sm`} onClick={() => editProcess(process)}>
                <img src={EditIcons} />
                <span>Edit</span>
              </div>
            </div>
          </div>
        )
      })
    }
    else{
    return(
      <div className={styles.noRecords}>
          <img src={NoProcess} />
          <h1>No Process Found</h1>
          <p>Looks like you haven't created any Process yet...</p>
          <Button
                variant="outlined"
                color="info"
                onClick={() => {
                  setEditProcess(true)
                }}
              >Create Process</Button>
        </div>
    )
    }
  }

  console.log(renderProcessList);

  return (
    <>
      <div className="xs:w-full sm:w-9/12 md:w-2/4 lg:w-1/3 xl:w-1/3 2xl:w-1/3">
        {searchProcess()}
      </div>
      <div className={styles.listContainer}>
        {renderProcessList()}
      </div>
      <Drawer
        anchor="right"
        open={editProces}
        onClose={() => {
          setEditProcess(false)
        }}
      >
        <div className={styles.modalContainer}>
          <NewProcess setCreateNewProcess={setEditProcess} fromLayout={true} isEdit={processList?.length ? true:false} processToBeEdited={processToBeEdited} />
        </div>
      </Drawer>
    </>
  );
}

Process.fab = true;