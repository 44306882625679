import React, { useEffect, useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import styles from './orders.module.scss';
import { useDispatch } from 'react-redux';
import { updateProcessStatus } from 'store/reducer/orders';
import { ProcessStatus } from './index'
export default function ChangeProcessStatus({ order }) {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const openStatus = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = ({ value }) => {
    if (value) {
      const id = order.id;
      const process = order.processList.find((selectedOrder) => !selectedOrder.isCompleted)
      const processId = process.id;
      const processStatus = ProcessStatus[value];

      const payload = {
        processId,
        processStatus
      }
      dispatch(updateProcessStatus({ id, payload }));
    }
    setAnchorEl(null);
  }
  let options = [{
    label: "Start to Work",
    value: "IN_PROGRESS"
  }, {
    label: "Put on Hold",
    value: "ON_HOLD"
  }, {
    label: "Mark as Completed",
    value: "COMPLETED"
  }]

  return (
    <div className={styles.assignContainer}>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls="long-menu"
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={openStatus}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        variant="kfAssign"
        onClose={handleClose}
      >
        {options.map((option) => {
          const { label, value } = option;
          return (<MenuItem key={label} selected={option === 'Pyxis'}
            variant="kfAssign"
            onClick={() => handleClose(option)}>
            <div className="text-sm font-normal">
              <span>{label}</span>
            </div>
          </MenuItem>)
        })}
      </Menu>
    </div>
  )
}


// return (<MenuItem key={option} selected={option === 'Pyxis'}
//   variant="kfAssign"
//   onClick={() => handleClose(option)}>
//   <div className={styles.employee}>
//     <span>{options}</span>
//   </div>
// </MenuItem>)