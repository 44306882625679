export function dottedBorder({
  stroke,
  strokeWidth,
  strokeDasharray
} = {
  stroke: '#505164',
  strokeWidth: 2,
  strokeDasharray: '6, 6'
}) {
  const svg = `<svg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><rect width='100%' height='100%' fill='none' stroke='${stroke}' stroke-width='${strokeWidth}' stroke-dasharray='${strokeDasharray}' stroke-dashoffset='0' stroke-linecap='square'/></svg>`;
  return `url("data:image/svg+xml,${encodeURIComponent(svg)}")`;
}
