import React from 'react';
import usePagination from '@mui/material/usePagination';
import { styled } from '@mui/material/styles';

export default function KfcPagination({ count, setPage }) {
  const { items } = usePagination({
    count,
  });
  const handleChange = (event, value) => {
    console.log('VALUE', event.target.value);
    //setPage(4);
  };

  const List = styled('ul')({
    listStyle: 'none',
    padding: 0,
    margin: 0,
    display: 'flex',
  });
  const Nav = styled('nav')({
    width: '40%',
    backgroundColor: '#F2F2F2',
    padding: 7,
    borderRadius: 4,
    marginTop: 12,
    fontSize: 12
  });
  const ActionSpan = styled('span')({
    color: '#000000',
    margin: '0 10px',
    cursor: 'pointer',
  });
  const ActionButton = styled('button')({
    border: 'none',
    fontSize: 12,
    cursor: 'pointer',
  });
  return (
    // <Stack spacing={8}>
    //   <Pagination count={count} variant="outlined" onChange={handleChange} />
    // </Stack>
    <Nav>
      <List>
        {items.map(({ page, type, selected, ...item }, index) => {
          let children = null;

          if (type === 'start-ellipsis' || type === 'end-ellipsis') {
            children = '…';
          } else if (type === 'page') {
            children = (
              <ActionSpan
                type="button"
                style={{
                  padding: '0 5px',
                  fontWeight: selected ? 'bold' : undefined,
                  borderBottom: selected ? '2px solid #FFC700' : '',
                }}
                {...item}
              >
                {page}
              </ActionSpan>
            );
          } else {
            children = (
              <ActionButton {...item} type="button" >
                {type === 'previous' ? '< Prev' : 'Next >'}
              </ActionButton>
            );
          }

          return <li key={index} onClick={() => setPage(page - 1)}>{children}</li>;
        })}
      </List>
    </Nav>
  );
}
