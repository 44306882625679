import { makeStyles } from '@mui/styles';

const flexColumn = {
  display: 'flex',
  flexDirection: 'column'
};

export default
  makeStyles(theme => {
    return {
      container: {
        ...flexColumn,
        padding: 36,
        width: 536,
        margin: 'auto',
        backgroundColor: 'whitesmoke'
      },
      form: {
        ...flexColumn
      },
      'primary-fields': {
        '& .MuiFormControl-root': {
          display: 'flex'
        },
        '& .MuiInputBase-input': {
          flex: 1,
        },
        paddingBottom: 12
      },
      'skill-row': {
        display: 'flex',
        alignItems: 'center',
        '& .MuiFormControl-root': {
          flexGrow: 1,
          marginRight: 14
        }
      }
    }
  });
