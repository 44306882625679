import React, { useRef, useState } from 'react';
import { Formik, Form } from 'formik';
import { Box, Modal } from '@mui/material';
import { SimpleInput, Button, ButtonGroup } from '@tt/fe-common';
import * as Yup from 'yup';
import ErrorContainer from '../../utils/error';
import cx from 'classnames';
import styles from './onboarding.module.scss';
import { useGetCompanyDetailsQuery, useUpdateCompanyDetailsMutation } from '../../store/reducer/services/company';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useMediaQuery } from '@mui/material';

export default function AddressComponent(
    {
        onboardingDetails,
        setOnboardingDetails,
        setOpenModal,
        setActiveStep
    }
) {

    const { data } = useGetCompanyDetailsQuery();
    const isMobileView = useMediaQuery('(max-width:576px)');
    const [addCompanyDetails] = useUpdateCompanyDetailsMutation();
    const formRef = useRef();
    const [companyDetails, setCompanyDetails] = useState({
        companyName: data?.companyName,
        isOnboardingCompleted: data?.isOnboardingCompleted,
        email: data?.email,
        companyDetails: {}
    });

    const validationSchema = Yup.object({
        doorNo: Yup.string()
            .required('Required'),
        street: Yup.string()
            .required('Street is required'),
        city: Yup.string()
            .required('City number is required'),
        state: Yup.string()
            .required('State is required'),
        country: Yup.string()
            .required('Country is required'),
        pinCode: Yup.string()
            .required('PinCode is required')
    })

    const initialValues = {
        doorNo: onboardingDetails?.address?.doorNo || '',
        street: onboardingDetails?.address?.street || '',
        city: onboardingDetails?.address?.city || '',
        state: onboardingDetails?.address?.state || '',
        country: onboardingDetails?.address?.country || '',
        pinCode: onboardingDetails?.address?.pinCode || ''
    }

    const addressDetailsSubmit = async (values) => {
        try {
            const address = values;
            onboardingDetails.address = address
            companyDetails.isOnboardingCompleted = true;
            companyDetails.companyDetails = onboardingDetails;
            setOnboardingDetails(onboardingDetails);
            setActiveStep(2);
        }
        catch (e) {
            console.log('submitting error', e);
        }
    }

    return (
        <div>
            <Formik
                initialValues={initialValues}
                innerRef={formRef}
                onSubmit={addressDetailsSubmit}
                validationSchema={validationSchema}
            >
                {({ errors, touched, values, handleChange, handleBlur }) => (
                    <Form className="px-24">
                        <div className={styles.door}>
                            <SimpleInput
                                label="Door No."
                                className={cx(styles.first, "mt-16")}
                                size="full-width"
                                name="doorNo"
                                value={values.doorNo}
                                onBlur={handleBlur('doorNo')}
                                handleChange={handleChange('doorNo')}
                            />

                            <SimpleInput
                                label="Street"
                                className="mt-16"
                                size="full-width"
                                name="street"
                                value={values.street}
                                onBlur={handleBlur('street')}
                                handleChange={handleChange('street')}
                            />
                        </div>

                        <div className={styles.door}>
                            {errors.doorNo && touched.doorNo ? (
                                <ErrorContainer>{errors.doorNo}</ErrorContainer>
                            ) : null}

                            {errors.street && touched.street ? (
                                <ErrorContainer>{errors.street}</ErrorContainer>
                            ) : null}

                        </div>

                        <SimpleInput
                            label="City"
                            className="mt-16"
                            size="full-width"
                            name="city"
                            value={values.city}
                            onBlur={handleBlur('city')}
                            handleChange={handleChange('city')}
                        />

                        {errors.city && touched.city ? (
                            <ErrorContainer>{errors.city}</ErrorContainer>
                        ) : null}

                        <SimpleInput
                            label="State"
                            className="mt-16"
                            size="full-width"
                            name="state"
                            value={values.state}
                            onBlur={handleBlur('state')}
                            handleChange={handleChange('state')}
                        />
                        {errors.state && touched.state ? (
                            <ErrorContainer>{errors.state}</ErrorContainer>
                        ) : null}

                        <SimpleInput
                            label="Country"
                            className="mt-16"
                            size="full-width"
                            name="country"
                            value={values.country}
                            onBlur={handleBlur('country')}
                            handleChange={handleChange('country')}
                        />
                        {errors.country && touched.country ? (
                            <ErrorContainer>{errors.country}</ErrorContainer>
                        ) : null}
                        <SimpleInput
                            label="Pincode"
                            className="mt-16"
                            size="full-width"
                            name="pinCode"
                            value={values.pinCode}
                            onBlur={handleBlur('pinCode')}
                            handleChange={handleChange('pinCode')}
                        />
                        {errors.country && touched.country ? (
                            <ErrorContainer>{errors.country}</ErrorContainer>
                        ) : null}
                        {
                            !isMobileView ?
                                <Button color='info' className='mt-16'
                                    onClick={() => formRef.current.handleSubmit()}>
                                    Next &nbsp;<ArrowForwardIcon fontSize="small" />
                                </Button> :
                                <div className={styles.btnGrp}>
                                    <ButtonGroup
                                        primaryCta="Next"
                                        secondaryCta="Skip"
                                        primaryFn={() => {
                                            formRef.current.handleSubmit();
                                        }}
                                        secondaryFn={() => console.log('Skip')} />
                                </div>
                        }
                    </Form>
                )}
            </Formik>
        </div>
    );
}