import React, { useState, useRef, useEffect } from "react";
import { setProfileDropDownState } from 'store/reducer/layout';
import { useDispatch } from 'react-redux';

export default function withClickOutside(WrappedComponent) {
  const Component = ({ userDetails, setUserInfo, setCreateNewCustomer, setCreateNewProcess }) => {
    const [dropDownState, setDropDownState] = useState(false);

    const ref = useRef();
    const dispatch = useDispatch();

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (!ref?.current?.contains(event.target)) {
          dispatch(setProfileDropDownState(false));
          setDropDownState(false);
        }
      };
      document.addEventListener("mousedown", handleClickOutside);
    }, [ref]);

    return <WrappedComponent userDetails={userDetails} setUserInfo={setUserInfo} ref={ref}
      dropDownState={dropDownState}
      setDropDownState={setDropDownState}
      setCreateNewProcess={setCreateNewProcess}
      setCreateNewCustomer={setCreateNewCustomer}
    />;
  };

  return Component;
}