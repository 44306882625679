import { io } from 'socket.io-client';
//import { API_LIST } from '../config';

const ENDPOINT = `${process.env.REACT_APP_BACKEND_HOST}/events/consumer`;
let socket = null;
export const connectToWebSocket = (companyId, setSocketConnected) => {
  socket = io(ENDPOINT, {
    headers: {
      'content-type': 'application/json',
    },
    withCredentials: true
  });
  socket.on(`${companyId}`, function (message) {
    console.log("SOCKET CONNECTED ", message);
    setSocketConnected(true);
  });
  socket.on('message', function (data) {
    console.log('Incoming message:', data);
  });
  socket.on('pendingOrderNotification', function (data) {
    console.log('Notification:', data);
  });
};

export const getSocket = () => {
  return socket;
};

/**
 * We need to have a pattern similar to singleton. We can have same promise
 * being resolved again and again.
 * @param {*} companyId 
 * @returns socket descriptor
 */
let sock;
export function connectSocket() {
  return new Promise((resolve, reject) => {
    sock &&
      resolve(sock);
    try {
      sock = io(ENDPOINT, {
        headers: {
          'content-type': 'application/json',
        },
        withCredentials: true
      });
      resolve(sock);
    } catch (err) {
      sock = null;
      reject({
        err,
        msg: `Unable to connect ${ENDPOINT}`
      });
    }
  })
}
