import React, { useEffect, useState } from 'react';
import { IOSSwitch } from '@tt/fe-common';
import { getPricingDetails } from '../../../config/billing';
import styles from './billing.module.scss';
import DoneIcon from '@mui/icons-material/Done';
const IconStyle = {
  position: 'relative',
  top: '0%',
  left: '10%',
  fill: '#5045CC',
  width: 18,
  height: 18
}
export default function Pricing() {
  const [isAnnual, setIsAnnual] = useState(true);
  const [plan, setPlan] = useState('annual');
  const [planDetails, setPlanDetails] = useState(getPricingDetails(plan))
  useEffect(() => {
    if (isAnnual) setPlan('annual')
    else setPlan('monthly')
  }, [isAnnual]);
  useEffect(() => {
    setPlanDetails(getPricingDetails(plan))
  }, [plan]);
  return <div>
    <div className="mt-32 font-semibold text-lg flex items-center justify-center mb-16">
      <span className="mr-2">Monthly</span>
      <IOSSwitch checked={isAnnual} onChange={(e) => setIsAnnual(!isAnnual)} size="small" />
      <span className="ml-2">Annual</span>
    </div>
    <div className={`flex flex-col items-center`}>
      <div className={`${styles.PlanBox} md:max-w-1/4 `}>
        <div className={`flex flex-col items-center`}>
          <span className="text-2xl font-bold">Starter</span>
          <span className="text-2xl font-bold mt-16">₹ {planDetails.costPerAgent}</span>
          <span className="mt-16">per agent/per month billed monthly</span>
          <span className={`${styles.Divider}`}></span>
        </div>
        <div className="flex items-center justify-center">
          <div>
            <div className="flex mb-16 items-center">
              <div className={`${styles.IconContainer}`}>
                <DoneIcon style={{ ...IconStyle }} />
              </div>
              <span className="ml-16">{planDetails.freeAgents} Agents</span>
            </div>
            <div className="flex mb-16 items-center">
              <div className={`${styles.IconContainer}`}>
                <DoneIcon style={{ ...IconStyle }} />
              </div>
              <span className="ml-16">{planDetails.freeSMS} Free SMS</span>
            </div>
            <div className="flex mb-16 items-center">
              <div className={`${styles.IconContainer}`}>
                <DoneIcon style={{ ...IconStyle }} />
              </div>
              <span className="ml-16">{planDetails.freeEMAIL} Free Emails</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}