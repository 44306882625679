import React, { useState, useEffect } from 'react';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { useGetMessagesQuery } from '../../../store/reducer/services/message';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { Drawer } from '@mui/material';
import { Button } from '@tt/fe-common';
import styles from './message.module.scss';
import MessageAction from './addOrEditMessage';
import { Link } from 'react-router-dom';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function Message() {
  const { data: messages } = useGetMessagesQuery();
  const [categoryExpanded, setCategoryExpanded] = React.useState('Employee');
  const [openMessageDrawer, setOpenMessageDrawer] = useState(false);
  const CategorySet = new Set();
  const [currentMessage, setCurrentMessage] = useState({});


  const handleAccordian = (panel) => (event, newExpanded) => {
    if (event.target.tagName !== 'BUTTON')
      setCategoryExpanded(newExpanded ? panel : false);
  };
  const [categories, setCategories] = useState([]);
  useEffect(() => {
    if (messages?.length) {
      messages.forEach(({ category }) => {
        CategorySet.add(category);
      });
      setCategories(Array.from(CategorySet));
    }
  }, [messages]);

  const getEvents = (category) => {
    const categorizedMessage = messages.filter((message) => message.category === category);
    return categorizedMessage.map(message => {
      return <span onClick={() => openDrawer(message)} className={styles.event}>{message.event}</span>
    })
  }

  const openDrawer = (message) => {
    setCurrentMessage(message);
    setOpenMessageDrawer(true);
  }

  return (<div>
    {categories.length > 0 && categories.map((category) => {
      return (
        <Accordion expanded={categoryExpanded === category} onChange={handleAccordian(category)} key={category}>
          <AccordionSummary aria-controls={category} id={category} >
            <div className={`display-flex justify-content-space-between  ${styles.fullWidth}`}>
              <span className={styles.verticalAlign}>{category}</span>
              <Button
                color="info"
                onClick={openDrawer}
              >
                Add {category} Message
              </Button>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            {getEvents(category)}
          </AccordionDetails>
        </Accordion>
      )
    })}
    <Drawer
      anchor="right"
      open={openMessageDrawer}
      onClose={() => setOpenMessageDrawer(false)}
    >
      <MessageAction currentMessage={currentMessage} setOpenMessageDrawer={setOpenMessageDrawer} />
    </Drawer>
  </div>)
}