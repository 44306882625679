import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { setHeader } from 'store/reducer/layout';
import { getOrdersStats } from 'store/reducer/orders';
import { getSubscriptionDetails } from 'store/reducer/subscription';
import { useGetCompanyDetailsQuery, useUpdateCompanyNameMutation } from '../../store/reducer/services/company';
import OnboardingBaseComponent from '../../components/onboarding/index';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useGetUnAssignedAgentsQuery } from 'store/reducer/services/employee';
import styles from './styles.module.scss';
import { SimpleInput, Button } from '@tt/fe-common';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import ErrorContainer from '../../utils/error';
import { useNavigate } from 'react-router-dom';
import Avatar from 'components/commom/Avatar';
import Breached from '../../assets/icons/breached.svg';
import InProgress from '../../assets/icons/inprogress.svg';
import YetToWork from '../../assets/icons/yet-to-work.svg';
import OnHold from '../../assets/icons/on-hold.svg';
import SMS from '../../assets/icons/dashboard-sms.svg';
import Email from '../../assets/icons/dashboard-email.svg';

const Dashboard = () => {
  const stats = useSelector(state => state.orders.stats);
  const { notifications } = useSelector((state) => state?.subscription);
  const dispatch = useDispatch();
  const [hasCompanyName, setHasCompanyName] = useState(true);
  const formRef = useRef();
  const [addCompanyDetails] = useUpdateCompanyNameMutation();
  const { data: usAssignedAgents, isLoading } = useGetUnAssignedAgentsQuery()
  const navigate = useNavigate();
  const validationSchema = Yup.object({
    companyName: Yup.string()
      .required('Required')
      .min(5, "Comapany Name must be atleast 5 letter")
      .max(50, "Looks like a bigger company Name")
  });
  const initialValues = {
    companyName: ''
  }

  useEffect(() => {
    dispatch(setHeader('Dashboard'));
  });

  const { data: companyDetails } = useGetCompanyDetailsQuery();

  useEffect(() => {
    if (companyDetails?.companyName)
      setHasCompanyName(true);
    else if (companyDetails) {
      if (!companyDetails.companyName)
        setHasCompanyName(false);
    }
  }, [companyDetails]);

  useEffect(() => {
    dispatch(getOrdersStats());
    dispatch(getSubscriptionDetails());
  }, []);
  useEffect(() => {

  }, []);

  const handleSubmit = async (values) => {
    try {
      const payload = { ...companyDetails }
      payload.companyName = values.companyName;
      await addCompanyDetails(payload).unwrap();
      setHasCompanyName(true);
    } catch (e) {
      console.log(e);
    }
  }


  const showOnboarding = () => {
    if (!companyDetails?.isOnboardingCompleted && hasCompanyName)
      return <OnboardingBaseComponent companyDetails={companyDetails}></OnboardingBaseComponent>
  };
  const getHeader = (key) => {
    if (typeof key === 'number') {
      switch (key) {
        case 0:
          return 'Yet to be worked'
        case 1:
          return 'In Progress Tasks'
        case 2:
          return 'Orders on hold'
      }
    }
    return 'Breached Orders'
  }

  const getIcon = (key) => {
    if (typeof key === 'number') {
      switch (key) {
        case 0:
          return YetToWork
        case 1:
          return InProgress;
        case 2:
          return OnHold
      }
    }
    return Breached;
  }

  const renderStats = () => {
    const keys = Object.keys(stats);
    return (<div className="grid grid-cols-1 gap-4 md:grid-cols-4 md:gap-4 mt-16">
      {keys.map((key) => {
        return <div key={key} className={`${styles.card} flex justify-between`}>
          <div className="flex flex-col">
            <span className={styles.header}>{getHeader(+key)}</span>
            <span className={styles.stat}>{stats[key]}</span>
          </div>
          <img src={getIcon(+key)} />
        </div>
      })}
    </div>);
  }

  const renderSubscription = () => {
    return (
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-4 mt-24">
        <div className={`${styles.card} flex justify-between cursor-pointer`} onClick={() => navigate('/settings/manage-notifications')}>
          <div className="flex flex-col">
            <span className={styles.header}>Emails Left</span>
            <span className={styles.stat}>{notifications.email.availableCount}</span>
          </div>
          <img src={Email} />
        </div>
        <div className={`${styles.card} flex justify-between cursor-pointer`} onClick={() => navigate('/settings/manage-notifications')}>
          <div className="flex flex-col">
            <span className={styles.header}>SMS Left</span>
            <span className={styles.stat}>{notifications.sms.availableCount}</span>
          </div>
          <img src={SMS} />
        </div>
      </div>
    )
  }

  const renderUnassignedAgents = () => {
    return <div className="mt-24 px-8">
      {usAssignedAgents?.length > 0 ? (
        <>
          <div className="flex justify-between font-semibold">
            <span>Agents Without task</span>
            {usAssignedAgents?.length > 3 && <span>See All</span>}
          </div>
          <div className="grid grid-cols-1 gap-4 md:grid-cols-4 md:gap-4 mt-16">
            {usAssignedAgents?.map((agent, i) => {
              return (<div key={i} className={`${styles.employeeCard} flex flex-col`}>
                <Avatar name={agent.name} size={46} radius={14} />
                <span className="mt-20 font-semibold text-sm">{agent.name}</span>
                <span className={`${styles.role} text-sm`}>{agent.role}</span>
              </div>)
            })}
          </div>
        </>
      ) : (<span>No agents</span>)
      }
    </div >
  }

  return (
    <section>
      <div>
        {stats && renderStats()}
        {notifications && renderSubscription()}
        {!isLoading && renderUnassignedAgents()}
      </div>
      {companyDetails && showOnboarding()}
      {!hasCompanyName && <Dialog open={!hasCompanyName}>
        <DialogTitle id="company-dialog-title" className={styles.modalHeader}>
          <span className="text-[14px]">Enter you company name before you proceed with Knowtify</span>
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={validationSchema}
            innerRef={formRef}
            onSubmit={handleSubmit}
          >
            {({ errors, touched, values, handleChange, handleBlur, handleSubmit }) => (
              <Form>
                <SimpleInput
                  label="Enter Company Name"
                  className="mt-32"
                  size="full-width"
                  name="companyName"
                  value={values.companyName}
                  onBlur={handleBlur('companyName')}
                  handleChange={handleChange('companyName')}
                />
                {errors.companyName && touched.companyName ? (
                  <ErrorContainer>{errors.companyName}</ErrorContainer>
                ) : null}
              </Form>
            )}

          </Formik>
        </DialogContent>
        <DialogActions className="mt-16 mr-8">
          <Button
            color="info"
            type="submit"
            onClick={() => {
              formRef.current.handleSubmit();
            }}
          >Save</Button>
        </DialogActions>
      </Dialog>}
    </section>
  );
};

export default Dashboard;
Dashboard.fab = true;
