const images = [
  'https://payload.cargocollective.com/1/4/150344/2194646/f21.jpg',
  'https://www.hometown.in/media/product/97/8053/39286/1.jpg',
  'http://cdn.shopify.com/s/files/1/0191/2234/products/DSC_2923_1024x1024.jpg?v=1555459621',
  'https://4.imimg.com/data4/LG/SI/ANDROID-46181446/product-500x500.jpeg'
];

const profies = [
  'https://www.parttimely.com/wp-content/uploads/2018/10/profile-pics-of-stylish-boy.jpg',
  'https://i.pinimg.com/originals/5b/0f/aa/5b0faa0ffe11afbd3a37396a8393482a.jpg',
  'https://1.bp.blogspot.com/-c_TvIuPf1MQ/X-hd5s-qomI/AAAAAAAAhGk/8HRNmHhFyCQ9yY-vsn-PL0Diq5kAYzOoQCLcBGAsYHQ/s1321/World%2BStylish%2BGirl%2BDP%2Bfor%2BFacebook%2BProfile%2B2020%2B%25281%2529.jpg',
  'https://www.whatsappimages.in/wp-content/uploads/2021/02/Beautiful-Girls-Whatsapp-DP-Profile-Images-photo-hd.gif'

]

export function getRandomImage() {
  const index = Math.ceil(Math.random() * (3 - 0) + 0);
  return images[index];
}


export function getRandomAvatar() {
  const index = Math.ceil(Math.random() * (3 - 0) + 0);
  return profies[index];
}