import React from 'react';
import { Button } from '@mui/material';
import { styled } from '@mui/system';
import { dottedBorder } from '../../utils/styling';

export function AddImage({ children }) {
  const ImageButton = styled(Button)(({ theme }) => {console.log(theme); return`
  background-image: ${dottedBorder()};
  width: 106px;
  height: 106px;
  color: ${theme.palette.primary.contrastText};
`});

  return (
    <ImageButton component="label">
      <span>{children}</span>
      <input type="file" hidden />
    </ImageButton>
  );
}
