import GoogleLogin from 'react-google-login';
import { useGoogleSiginMutation, useGoogleSignupMutation } from '../../store/reducer/services/signin';
import { useNavigate } from 'react-router-dom';
import { gapi } from 'gapi-script';
import { useEffect } from 'react';

const GoogleOauthComponent = ({ isSignUp }) => {

    const [googleSignin, { isLoading }] = useGoogleSiginMutation();
    const [googleSignup] = useGoogleSignupMutation();
    const history = useNavigate();
    useEffect(() => {
        const initClient = () => {
            gapi.client.init({
                clientId: process.env.REACT_APP_GOOGLE_OAUTH,
                scope: ''
            });
        };
        gapi.load('client:auth2', initClient);
    });

    const handleGoogleAuth = async (res) => {
        try {
            const { tokenId } = res;
            const payload = {
                tokenId: tokenId,
            }
            if (isSignUp) {
                const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
                payload.timeZone = timeZone;
                payload.role = "ACCOUNT_ADMIN";
                await googleSignup(payload).unwrap();
                history('/');
            } else {
                await googleSignin(payload).unwrap();
                history('/');
            }
        } catch (e) {
            console.log(e);
        }
    }

    const handGoogleAuthFailure = (res) => {
        console.log('Google Auth Failed: ', res);
    }

    return (
        <>
            <GoogleLogin
                clientId={`${process.env.REACT_APP_GOOGLE_OAUTH}`}
                buttonText={`${isSignUp ? "Sign up" : "Login"} with Google`}
                onSuccess={handleGoogleAuth}
                onFailure={handGoogleAuthFailure}
                cookiePolicy={'single_host_origin'}
                className="mr-16"
            />
        </>
    )
}

export default GoogleOauthComponent;