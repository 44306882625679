import React, { forwardRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useSignoutMutation } from 'store/reducer/services/signin';
import Avatar from 'components/commom/Avatar';
import { setProfileDropDownState } from 'store/reducer/layout';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import withClickOutside from '../withClickOutside';


const useStyles = makeStyles((theme) => {
  return createStyles({
    link: {
      textAlign: 'center',
      borderTop: 'solid 1px lightgrey'
    },
    flex: {
      display: "flex"
    },
    dropdown: {
      position: 'absolute',
      zIndex: 10,
      top: 47,
      marginRight: 10,
      width: 240,
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: theme.palette.base.b000,
      borderRadcreateStylesius: theme.shape.borderRadius + 'px',
      right: 0,
      boxShadow: '0px 4px 7px rgb(0 0 0 / 0.1)',
      borderRadius: 4,
      border: 'solid 1px lightgray',
      fontSize: 12,
      fontWeight: 500,
      color: theme.palette.info.main,
      padding: '5px 5px',
      '& div': {
        padding: 10,
        cursor: 'pointer',
      },
    },
  })
}
);

const Profile = forwardRef(({ userDetails, setUserInfo }, ref) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const layoutState = useSelector(state => state.layout);
  const [removeUser] = useSignoutMutation();
  const handleSignOut = async () => {
    await removeUser();
    setUserInfo(null);
    navigate('/signin');
  }
  return (
    <div ref={ref} className={classes.flex} id="profile-picture">
      <span
        onClick={() => {
          dispatch(setProfileDropDownState(!layoutState.profileDropDownState));
        }}
        className="ml-8 mr-2 shadow-lg rounded-full"
      >
        <Avatar name={userDetails?.currentUser?.email?.[0] || ""} size={28} />
        {layoutState.profileDropDownState && (
          <div className={classes.dropdown}>
            {layoutState.profileDropDownState &&
              <>
                <div className="flex flex-col">
                  <span className="mb-4 text-gray-400">User Name</span>
                  <span className="mb-16">{userDetails?.currentUser?.email}</span>
                  <span className="mb-4 text-gray-400">Role</span>
                  <span className="mb-16">{userDetails?.currentUser?.role}</span>
                </div>
                <div
                  className={classes.link}
                  key={"logout"}
                  onClick={() => handleSignOut()}
                >
                  Logout
                </div>
              </>
            }
          </div>
        )}

      </span>
    </div>
  );
});

export default withClickOutside(Profile);