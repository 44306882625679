import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../axiosUtility';

export const processAPI = createApi({
  reducerPath: 'process',
  baseQuery: axiosBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_HOST}/`,
  }),
  tagTypes: ['Process'],
  endpoints: (build) => ({
    getAllProcess: build.query({
      query: () => ({
        url: 'api/process/company/getAllProcesses',
        method: 'GET',
      }),
      // providesTags: (result, error, arg) =>
      //   result
      //     ? [...result.map(({ id }) => ({ type: 'Process', id })), 'Process']
      //     : ['Process'],
      providesTags: ['Process'],
    }),
    addProcess: build.mutation({
      query: (body) => ({
        url: `api/process/create`,
        method: 'POST',
        data: body,
      }),
      invalidatesTags: ['Process'],
    }),
    updateProcess: build.mutation({
      query: ({ id, ...body }) => ({
        url: `api/process/${id}`,
        method: 'PUT',
        data: body['payload'],
      }),
      invalidatesTags: ['Process'],
    }),
    deleteProcess: build.mutation({
      query: ({ id }) => ({
        url: `api/process/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Process'],
    }),
    getProcess: build.query({
      query: ({ id }) => ({
        url: `api/process/${id}`,
        method: 'GET',
      }),
      providesTags: ['Process'],
    }),
  }),
});

export const {
  useGetAllProcessQuery,
  useAddProcessMutation,
  useUpdateProcessMutation,
  useDeleteProcessMutation,
  useLazyGetProcessQuery,
} = processAPI;
