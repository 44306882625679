import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  products: []
};

export const counterSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addProduct: (state, action) => {
      state.products = [...action.payload]
    },
    removeProduct: (state, action) => {
      state.products = state.products.filter(({ id }) => {
        return id !== action.payload;
      })
    },
    updateProduct: (state, action) => {
      const productToChange = action.payload;
      state.products = state.products.map((product) => {
        if (product.id === productToChange.id) {
          return productToChange;
        } else {
          return product;
        }
      });
    },
    resetCart: (state, action) => {
      state.products = action.payload;
    }
  }
});

export const { addProduct, removeProduct, updateProduct, resetCart } = counterSlice.actions;
export default counterSlice.reducer;