import React, { useEffect, useState } from 'react';
import { getSocket } from '../../socket/connect';
import { toast } from 'react-toastify';
import moment from 'moment';
import styles from './notification.module.scss';
import ProductIcon from 'assets/icons/products.svg';
import CustomerIcon from 'assets/icons/dark/user-plus.svg';
import axios from 'axios';

function NotificationBaseComponent() {
  const socket = getSocket();
  let notificationStack = [];
  const snooze = async (info, closeToast, snoozeTime) => {
    const snoozeObject = {
      snoozeTime: snoozeTime || 5,
      processId: info.pendingProcessDetails.processId,
    };
    const url = `${process.env.REACT_APP_BACKEND_HOST}/api/order/snooze/${info.id}`
    const res = await axios.put(url, snoozeObject, { withCredentials: true });
    notificationStack = notificationStack.filter((id) => id !== info.pendingProcessDetails.processId);
    closeToast();
  };
  const Toaster = ({ closeToast, toastProps, info }) => {
    const [customSnooze, setCustomSnooze] = useState(false);
    const currentTime = moment.utc();
    const estimateEndTime = moment.utc(info.pendingProcessDetails.endTime);
    const difference = currentTime.diff(estimateEndTime, 'seconds');
    const duration = moment.duration(difference, 'seconds');
    const [customSnoozeTime, setCustomSnoozeTime] = useState(null);
    const { days, hours, minutes } = duration._data
    if (!customSnooze) {
      return (
        <div className="flex flex-col">
          <span className={styles.info}>
            Time breached for
            <span className={styles.info}>{`  ${days ? `${days} Days ` : ''}${hours} Hours ${minutes} Mins`}</span>

          </span>
          <span className="flex mt-8"><img src={ProductIcon} />{info.pendingProcessDetails.processName} -{' '}
            {info.pendingProcessDetails.productName}</span>
          <span className="flex mt-8">
            <img src={CustomerIcon} />{info.pendingProcessDetails.customerName}
          </span>
          <div className="mt-8 flex flex-row-reverse text-sky-500">
            <span onClick={() => snooze(info, closeToast)}>
              Snooze for 5 mins
            </span>
            <span onClick={() => setCustomSnooze(true)} className="mr-8">Remind me in </span>
          </div>
        </div>
      );
    } else {
      return (
        <div className="flex flex-col">
          <div>
            <span className={styles.info}>
              Process overdue by{' '}
            </span>
            <span className={styles.info}>{`  ${days ? `${days} Days ` : ''}${hours} Hours ${minutes} Mins`}</span>
          </div>
          <div>
            <span className="flex mt-8"><img src={ProductIcon} />{info.pendingProcessDetails.processName} -{' '}
              {info.pendingProcessDetails.productName}</span>
          </div>
          <div className="mt-8">
            <span>Snooze in </span>
            <input placeholder="mins" onChange={(e) => setCustomSnoozeTime(+e.target.value)}></input>
          </div>
          <div className="flex flex-row-reverse text-sky-500 mt-8">
            <span onClick={() => setCustomSnooze(false)} >Cancel</span>
            <span onClick={() => snooze(info, closeToast, customSnoozeTime)} className="mr-8">Save</span>
          </div>
        </div>
      );
    }
  };
  const showNotification = (info) => {
    toast(<Toaster info={info} />, {
      appearance: 'success',
      autoDismiss: false,
      autoClose: false,
      closeOnClick: false,
      className: styles.toastContainer
    });
  }
  useEffect(() => {
    socket.on('pendingOrderNotification', function (data) {
      const info = JSON.parse(data)
      if (!notificationStack.includes(info.pendingProcessDetails.processId)) {
        showNotification(info);
        notificationStack.push(info.pendingProcessDetails.processId);
      }

    });
  }, []);

  return <></>;
}

export default NotificationBaseComponent;
