import React from 'react';
import { styled } from '@mui/system';

import { Button as MuiButton } from '../../components/Button';

const Container = styled('div')(
  ({ theme, width }) => `
  bottom: 0;
  width: ${width};
  display: flex;
  margin-left: -1rem;
`
);

//  padding: 1rem 1.5rem;


const Button = styled(MuiButton)`
  margin-left: 1rem;
`;

const MarginNegate = styled('div')`
  display:flex;
  justify-content: center;
`;
//margin-left: -1.5rem;
//margin - right: -1.5rem;

export const ButtonGroup = function ButtonGroup({
  primaryCta,
  secondaryCta,
  primaryFn,
  secondaryFn,
  width,
  classes
}) {
  return (
    <MarginNegate className={classes}>
      <Container width={width}>
        <Button
          variant="outlined"
          color="info"
          size="exact"
          onClick={secondaryFn}
        >
          {secondaryCta}
        </Button>
        <Button
          variant="contained"
          color="info"
          size="exact"
          onClick={primaryFn}
        >
          {primaryCta}
        </Button>
      </Container>
    </MarginNegate>
  );
};
