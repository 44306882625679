import { Provider } from 'react-redux';
import { store } from './store';
import Layout from './pages/layout';
import { ThemeProvider } from '@mui/material/styles';
import { lightTheme } from '@tt/fe-common';
import { BrowserRouter } from 'react-router-dom';
import GlobalLoader from './pages/layout/globalLoader';

import './globals.scss';

function App() {
  return (
    <>
      <GlobalLoader />
      <ThemeProvider theme={lightTheme}>
        <Provider store={store}>
          <BrowserRouter>
            <Layout />
          </BrowserRouter>
        </Provider>
      </ThemeProvider>
    </>
  );
}

export default App;
