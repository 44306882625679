const messageTags = [
  {
    category: 'Agent',
    event: 'Creation',
    tags: [{
      id: `name`,
      display: 'name'
    }, {
      id: `email`,
      display: 'email'
    }, {
      id: `tempPassword`,
      display: 'password'
    }]
  },
  {
    category: 'Process',
    event: 'Completed',
    tags: [{
      id: `customerName`,
      display: 'Customer Name'
    }, {
      id: `productName`,
      display: 'Product Name'
    }, {
      id: `processName`,
      display: 'Process Name'
    }]
  },
  {
    category: 'Product',
    event: 'Completed',
    tags: [{
      id: `customerName`,
      display: 'Customer Name'
    }, {
      id: `productName`,
      display: 'Product Name'
    }]
  }
]

export const getMessageTags = (category, event) => {
  const { tags } = messageTags.find((message) => message.category === category && message.event === event);
  return tags;
}