import React, { useEffect } from 'react';
import { Button, FormGroup } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const DIM = 32;

const useStyles = makeStyles((theme) =>
  createStyles({
    input: {
      border: 0,
      width: 36,
      height: props => props.DIM,
      textAlign: 'center'
    },
    btn: {
      width: props => props.DIM,
      height: props => props.DIM,
      boxSizing: 'border-box',
      minWidth: 'unset',
      //backgroundColor: theme.palette.base.b100,
      boxShadow: 'none',
      //color: theme.palette.info.main,
      fontWeight: 'bold',
      //fontFamily: theme.typography.fontFamily
    },
    root: {
      '& .MuiFormGroup-root': {
        flexDirection: 'row'
      }
    }
  }));

export const Incrementor = function Incrementor({ value = 0, updateFunc = null }) {
  const [stateValue, setValue] = React.useState(value);
  useEffect(() => {
    updateFunc(stateValue);
  }, [stateValue])
  const classes = useStyles({ DIM });
  return (
    <div className={classes.root}>
      <FormGroup>
        <Button variant="contained"
          className={classes.btn}
          onClick={() => setValue(curr => curr > 1 ? curr - 1 : 1)}
        >-</Button>
        <input className={classes.input} value={stateValue} readOnly />
        <Button variant="contained"
          className={classes.btn}
          onClick={() => setValue(curr => curr ? curr + 1 : 1)}
        >+</Button>
      </FormGroup>
    </div>
  );
}
