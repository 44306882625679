import React from 'react';
import {
  InputAdornment,
  IconButton,
  FilledInput,
  TextField,
  OutlinedInput,
  InputLabel,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { styled } from '@mui/system';
import PropTypes from 'prop-types';

const FixedUnit = styled('p')(
  ({ theme }) => `
  font-size: ${theme.typography.caption.fontSize};
  padding: 0 8px;
  border-left: solid 1px ${theme.palette.cancel.main};
`
);

export const VARIANTS = Object.freeze({
  WITH_ICON: 'with-icon',
  FIXED_UNIT: 'fixed-unit',
});

export const SIZE = Object.freeze({
  FULL_WIDTH: 'full-width',
  FIXED_VALUE: 'fixed-value',
});

export function SimpleInput({
  value,
  variant,
  IconComponent = '',
  fixedUnit,
  className = '',
  label = '',
  id = '',
  handleChange,
  onFocus,
  onBlur,
  size = SIZE.FULL_WIDTH,
  type,
  disabled = false,
  iconHandler,
  ...remainingProps
}) {
  const rootStyle = {};
  let AdornmentComponent;

  switch (variant) {
    case VARIANTS.WITH_ICON:
      AdornmentComponent = IconComponent;
      break;
    case VARIANTS.FIXED_UNIT:
      AdornmentComponent = () => <FixedUnit>{fixedUnit || ''}</FixedUnit>;
      break;
  }

  switch (size) {
    case SIZE.FULL_WIDTH:
      rootStyle.width = '100%';
      break;
    case SIZE.MIN_WIDTH:
      rootStyle.width = 'fit-content';
      break;
  }

  const classes = makeStyles(() =>
    createStyles({
      root: {
        ...rootStyle,
      },
    })
  )();

  /*const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };*/

  return (
    <div className={className}>
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <OutlinedInput
        id={id}
        value={value}
        type={type}
        onChange={(e) => handleChange(e.target.value)}
        onBlur={onBlur}
        onFocus={onFocus}
        disabled={disabled}
        className={classes.root}
        endAdornment={
          IconComponent && (
            <InputAdornment position="end">
              <IconButton edge="end" onClick={iconHandler}>
                <IconComponent />
              </IconButton>
            </InputAdornment>
          )
        }
        aria-describedby="outlined-weight-helper-text"
        inputProps={{
          'aria-label': 'weight',
        }}
        {...remainingProps}
      />
    </div>
  );
}

SimpleInput.propTypes = {
  /**
   * TODO: add dynamic values instead of manual typing
   * https://github.com/storybookjs/storybook/issues/14092
   */
  variant: PropTypes.oneOf(['with-icon', 'fixed-unit']),
  fixedUnit: PropTypes.string,
};

SimpleInput.defaultProps = {
  size: SIZE.FULL_WIDTH,
  IconComponent: null,
};
