import { SimpleInput, Button } from '@tt/fe-common';
import { Formik, Form } from 'formik';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useRef, useState, useMemo, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ErrorContainer from '../../utils/error';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import styles from './signup.module.scss';
import cx from 'classnames';
import { useSignupMutation } from 'store/reducer/services/signin';
import GoogleOauthComponent from './googleOauthComponent';
import { setToaster, setToasterMessage } from 'store/reducer/layout';
import { useDispatch } from 'react-redux';
import {
    useLocation
} from "react-router-dom";

function useQuery() {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
}

const Signup = ({ currentUser }) => {
    const uppercaseRegExp = /(?=.*?[A-Z])/;
    const lowercaseRegExp = /(?=.*?[a-z])/;
    const digitsRegExp = /(?=.*?[0-9])/;
    const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
    const minLengthRegExp = /.{8,}/;
    const dispatch = useDispatch();

    const history = useNavigate();
    let query = useQuery();

    useEffect(() => {
        if (currentUser) {
            history('/');
        }
    }, [currentUser]);

    const [addUser, { isLoading }] = useSignupMutation();
    const [passwordHasUppercase, setePasswordHasUppercase] = useState(false);
    const [passwordHasLowercase, setePasswordHasLowercase] = useState(false);
    const [passwordHasDigits, setPasswordHasDigits] = useState(false);
    const [passwordHasSpecialCharacter, setPasswordHasSpecialCharacter] = useState(false);
    const [passwordLengthValidation, setPasswordLengthValidation] = useState(false);
    const [viewPassword, setViewPassword] = useState(false);
    const [viewConfirmPassword, setViewConfirmPassword] = useState(false);

    const formRef = useRef();

    const initialValues = {
        name: '',
        companyName: '',
        email: query.get('email') || '',
        password: '',
        confirmPassword: ''
    }

    const setPassword = (value) => {
        formRef.current.setFieldValue(
            'password', value,
        );
        const passwordLength = value.length;
        setePasswordHasUppercase(uppercaseRegExp.test(value));
        setePasswordHasLowercase(lowercaseRegExp.test(value));
        setPasswordHasDigits(digitsRegExp.test(value));
        setPasswordHasSpecialCharacter(specialCharRegExp.test(value));
        setPasswordLengthValidation(minLengthRegExp.test(value));
    }

    const validationSchema = Yup.object({
        name: Yup.string()
            .required('Required')
            .min(4, "Name must be at least 4 characters"),
        companyName: Yup.string()
            .required('Required'),
        email: Yup.string()
            .email('Invalid email format')
            .required('Required'),
        password: Yup.string()
            .trim()
            .required('Required')
            .min(8)
            .matches(uppercaseRegExp, " ")
            .matches(lowercaseRegExp, " ")
            .matches(digitsRegExp, " ")
            .matches(specialCharRegExp, " "),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
    })

    const handleSubmit = async (values) => {
        try {
            const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
            const payload = { role: 'ACCOUNT_ADMIN', timeZone, ...values };
            await addUser(payload).unwrap();
            formRef.current.resetForm();
            history('/')
        } catch (e) {
            dispatch(setToaster('error'));
            dispatch(setToasterMessage(e.data.errors[0].message));
        }
    }

    return (
        <section className="display-flex min-h-screen">
            <div className="hidden md:block bg-emerald-900 w-6/12 px-16">
                <img src="/icons/svgs/logo.svg" className="mt-16 " />
                <div className="display-flex justify-content-center items-center flex-col mt-16">
                    <p className="text-2xl text-slate-50">Status Notified Easier</p>
                    <p className="text-slate-50 mt-8 mb-32">Its free and easy</p>
                </div>
                <img src="/icons/svgs/signinillustration.svg" className="m-auto " />
            </div>
            <div className="w-full md:w-6/12 md:px-36 m-auto">
                <p className="font-bold text-xl text-center mt-16">Sign up</p>
                <div className="py-16 px-32">
                    <div className="flex justify-center">
                        <GoogleOauthComponent isSignUp={true} />
                    </div>
                    <div className="display-flex justify-content-center mt-8" style={{ color: '#7A828A' }}>
                        <span className="text-center">or</span>
                    </div>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        innerRef={formRef}
                        onSubmit={handleSubmit}
                    >
                        {({ errors, touched, values, handleChange, handleBlur }) => (
                            <Form>
                                <SimpleInput
                                    label="Name"
                                    className="mt-16"
                                    size="full-width"
                                    name="name"
                                    value={values.name}
                                    onBlur={handleBlur('name')}
                                    handleChange={handleChange('name')}
                                />
                                {errors.name && touched.name ? (
                                    <ErrorContainer>{errors.name}</ErrorContainer>
                                ) : null}

                                <SimpleInput
                                    label="Company Name"
                                    className="mt-32"
                                    size="full-width"
                                    name="companyName"
                                    value={values.companyName}
                                    onBlur={handleBlur('companyName')}
                                    handleChange={handleChange('companyName')}
                                />
                                {errors.companyName && touched.companyName ? (
                                    <ErrorContainer>{errors.companyName}</ErrorContainer>
                                ) : null}

                                <SimpleInput
                                    label="Email"
                                    className="mt-32"
                                    size="full-width"
                                    name="email"
                                    value={values.email}
                                    onBlur={handleBlur('email')}
                                    handleChange={handleChange('email')}
                                />
                                {errors.email && touched.email ? (
                                    <ErrorContainer>{errors.email}</ErrorContainer>
                                ) : null}

                                <SimpleInput
                                    className="mt-32"
                                    label="Password"
                                    size="full-width"
                                    name="password"
                                    type={viewPassword ? "text" : "password"}
                                    value={values.password}
                                    variant='with-icon'
                                    IconComponent={viewPassword ? VisibilityOffIcon : VisibilityIcon}
                                    onBlur={handleBlur('password')}
                                    handleChange={setPassword}
                                    iconHandler={() => setViewPassword(!viewPassword)}
                                />
                                <div className="text-xs mt-16">
                                    <div>
                                        <span className={`${styles.dot} ${!passwordLengthValidation ? 'transition ease-in-out delay-150 bg-slate-300' : 'transition ease-in-out delay-150 bg-green-500'}`}></span>
                                        <span>Must be 8 Character at least</span>
                                    </div>
                                    <div>
                                        <span className={`${styles.dot} ${!(passwordHasUppercase && passwordHasLowercase) ? 'transition ease-in-out delay-150 bg-slate-300' : 'transition ease-in-out delay-150 bg-green-500'}`}></span>
                                        <span>Should be a combination of upper and lower case </span>
                                    </div>
                                    <div>
                                        <span className={`${styles.dot} ${!passwordHasDigits ? 'transition ease-in-out delay-150 bg-slate-300' : 'transition ease-in-out delay-150 bg-green-500'}`}></span>
                                        <span>Must contain a number </span>
                                    </div>
                                    <div>
                                        <span className={`${styles.dot} ${!passwordHasSpecialCharacter ? 'transition ease-in-out delay-150 bg-slate-300' : 'transition ease-in-out delay-150 bg-green-500'}`}></span>
                                        <span>Must contain a special character </span>
                                    </div>
                                </div>
                                {errors.password && touched.password ? (
                                    <ErrorContainer>{errors.password}</ErrorContainer>
                                ) : null}

                                <SimpleInput
                                    className="mt-32"
                                    label="confirm Password"
                                    size="full-width"
                                    name="confirmPassword"
                                    type={viewConfirmPassword ? "text" : "password"}
                                    value={values.confirmPassword}
                                    variant='with-icon'
                                    IconComponent={viewConfirmPassword ? VisibilityOffIcon: VisibilityIcon}
                                    onBlur={handleBlur('confirmPassword')}
                                    handleChange={handleChange('confirmPassword')}
                                    iconHandler={() => setViewConfirmPassword(!viewConfirmPassword)}
                                />
                                {errors.confirmPassword && touched.confirmPassword ? (
                                    <ErrorContainer>{errors.confirmPassword}</ErrorContainer>
                                ) : null}

                                <Button color='info' className='mt-32' size="full"
                                    onClick={() => formRef.current.handleSubmit()
                                    }>
                                    Create Account
                                </Button>
                            </Form>
                        )}
                    </Formik>
                    <div className="display-flex justify-content-center mt-16 text-xs" style={{ color: '#7A828A' }}>
                        <span className="text-center ">Already have an account?</span>
                        &nbsp;<Link to='/signin' className="text-sky-500">Login</Link>
                    </div>
                </div>

            </div>
        </section>
    );
}

export default Signup;