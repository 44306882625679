import React from 'react';
import cx from 'classnames';
import { Formik, Form, Field } from 'formik';
import { Checkbox, Typography, FormControlLabel, Button } from '@mui/material';

import { useSnoozeOrderMutation } from 'store/reducer/services/order';

import styles from './styles.module.scss';
import cogImg from 'assets/icons/color/cog.png';
import userImg from 'assets/icons/user.png';

const CUST_SNOOZE_HEIGHT = '--custom-snooze-max-height';
const WRAPPER_MAX_HEIGHT = '--wrapper-max-height';

const initialValues = {
  snoozeTime: 5,
  defaultSnoozeTime: false
};

export default function NotificationCard({ datum }) {
  const [hideCustomSnooze, toggleCustomSnooze] = React.useState(false);
  const [overlaySnooze, toggleOverlaySnooze] = React.useState(false);
  const [snoozeOrder] = useSnoozeOrderMutation();
  const customSnoozeRef = React.useRef(null);
  const wrapperRef = React.useRef(null);
  let custSnoozeHeightValue;

  const customSnoozeHandler = () => {
    /* CSS Custom property is set here once, so that freq `scrollHeight` calculation is
     * is avoided. */
    if (
      !custSnoozeHeightValue &&
      (customSnoozeRef.current instanceof HTMLElement)
    ) {
      custSnoozeHeightValue = customSnoozeRef.current.scrollHeight;
      customSnoozeRef.current.style.setProperty(CUST_SNOOZE_HEIGHT, custSnoozeHeightValue + 'px');
    }
    toggleCustomSnooze(true);
  };

  const snoozeFormHandler = e => {
    (wrapperRef.current instanceof HTMLElement) &&
      timedSnoozeHandler(e);
  }

  const timedSnoozeHandler = (payload) => {
    console.log('ahead', payload);
    const height = window.parseFloat(
      window.getComputedStyle(wrapperRef.current).height
    );
    if (!window.isNaN(height)) {
      wrapperRef.current.style.setProperty(WRAPPER_MAX_HEIGHT, height + 'px');
    }
    window.setTimeout(function () {
      toggleOverlaySnooze(true);
    }, 0);
    snoozeOrder({
      ...payload,
      processId: datum.processId
    });
  }

  return (
    <div ref={wrapperRef} className={cx(styles.wrapper, {
      [styles.dismiss]: overlaySnooze
    })}>
      <div className={cx(styles.overlay, 'stretch', {
        [styles['overlay-active']]: overlaySnooze
      })}>
        <p className={styles['overlay__label']}>Reminding in</p>
        <p className={styles['overlay__info']}>5 mins</p>
      </div>
      <div className={styles.card}>
        {/* Main card content */}
        <Typography variant="h5">Process deadline in 2 minutes!</Typography>
        <div className={cx(styles.item, 'mb-12')}>
          <img src={cogImg} />
          <span>{datum.productName}</span>
        </div>
        <div className={cx(styles.item, 'mb-12')}>
          <img src={userImg} />
          <span>{datum.processName}</span>
        </div>
        <div className={styles['snooze-btn']}>
          <Button color="info" variant="text" onClick={() => timedSnoozeHandler(initialValues)}>Snooze for 5 mins</Button>
          <Button color="info" variant="text" onClick={customSnoozeHandler}>Remind me in…</Button>
        </div>

        {/* Custom snooze widget with form */}
        <div className={cx(styles['custom-snooze__wrapper'], {
          [styles['active']]: hideCustomSnooze
        })} ref={customSnoozeRef}>
          <div className={cx(styles['custom-snooze'], 'mt-8 mb-4')}>
            <Formik initialValues={initialValues} onSubmit={snoozeFormHandler}>{({ values }) => (
              <Form>
                <Typography variant="h6" sx={{
                  fontSize: 12,
                  fontWeight: 600
                }}>Remind me in</Typography>
                <div className={cx('display-flex', styles['custom-snooze__main'])}>
                  <span>Snooze notification</span>
                  <Field name="snoozeTime">{({ field }) => (
                    <div className={styles['custom-snooze__field']}>
                      <input type="text" {...field} />
                      <span>Mins</span>
                    </div>
                  )}
                  </Field>
                </div>
                <Field name="defaultSnoozeTime">{({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox {...field} />
                    }
                    label={<label className={styles['custom-snooze__label']}>Set as default “Snooze time”</label>}
                  />
                )}
                </Field>
                <div className={styles['custom-snooze__footer']}>
                  <Button color="info" type="reset" variant="text">Cancel</Button>
                  <Button color="info" type="submit" variant="text">Save</Button>
                </div>
              </Form>
            )}
            </Formik>
          </div>
        </div>
      </div >
    </div>
  );
}
