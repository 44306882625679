import React, { useEffect } from 'react';
import cx from 'classnames';
import { useDispatch } from 'react-redux';

import { setHeader } from 'store/reducer/layout';
import { hexToRgbaHoc } from '../../utils/color';
import SettingCard from './card';
import styles from './settings.module.scss';

import agentsIcon from 'assets/icons/agents.svg';
import businessHoursIcon from 'assets/icons/business-hours.svg';
import messageIcon from 'assets/icons/messages.svg';
import manageNotificationsIcon from 'assets/icons/manage-notifications.svg';
import plansAndBillingIcon from 'assets/icons/plans-and-billing.svg';

export default function Settings() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setHeader('Settings'));
  });
  return (
    <section className={cx('display-flex', styles.container)}>
      {list.map(listItem => (
        <SettingCard {...listItem} />
      ))}
    </section>
  );
}

Settings.fab = true;

const hexToRgba = hexToRgbaHoc(0.1);
const list = [
  {
    link: '/settings/agents',
    bg: hexToRgba('#00C3FF'),
    icon: agentsIcon,
    label: 'Agents'
  },
  {
    link: '/settings/workingHours',
    bg: hexToRgba('#DD86F2'),
    icon: businessHoursIcon,
    label: 'Business Hours'
  },
  {
    link: '/settings/messages',
    bg: hexToRgba('#87E694'),
    icon: messageIcon,
    label: 'Messages'
  },

  {
    link: '/settings/manage-notifications',
    bg: hexToRgba('#00C3FF'),
    icon: manageNotificationsIcon,
    label: 'Manage Notifications'
  },
  {
    link: '/settings/plans-and-billing',
    bg: hexToRgba('#00C3FF'),
    icon: plansAndBillingIcon,
    label: 'Plans And Billing'
  },

];
