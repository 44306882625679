import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { ButtonGroup } from '@tt/fe-common';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';


export default function DeleteDialog(props) {
  const { onClose, open, name, message, action, item, title } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const handleClose = () => {
    onClose(null);
  };

  return (
    <Dialog onClose={handleClose} open={open} fullScreen={fullScreen}>
      {title && <DialogTitle id="delete-dialog-title" className="flex items-center justify-between">
        <span>{title}</span>
        <HighlightOffRoundedIcon className="cursor-pointer text-gray-400" onClick={handleClose} />
      </DialogTitle>}
      <DialogContent>
        {/* <img src="/icons/svgs/deleteIllustration.jpeg"></img> */}
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions className="mt-24 mr-8">
        <ButtonGroup
          primaryCta="Yes"
          secondaryCta="No"
          primaryFn={action}
          secondaryFn={handleClose}
        />
      </DialogActions>
    </Dialog>
  )

}