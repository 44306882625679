/**
 * Converts hexa-decimal color to RGBA
 * @param {String} hex 
 * @param {Number} opacity (optional)
 * @returns {String}
 */
export function hexToRgba(hex, opacity) {
  const fHex = hex.replace(/^#/, '');
  return `rgba(
    ${parseInt(fHex.slice(0, 2), 16)},
    ${parseInt(fHex.slice(2, 4), 16)},
    ${parseInt(fHex.slice(4, 6), 16)},
    ${opacity || 1})
  `.replace(/\s/g, '');
}

export function hexToRgbaHoc(opacity) {
  return function (hex) {
    return hexToRgba(hex, opacity)
  };
}
