import axios from 'axios';
export const axiosBaseQuery =
  ({ baseUrl } = { baseUrl: '' }) =>
    async ({ url, method, data }) => {
      try {
        const result = await axios({
          url: baseUrl + url, method, data,
          withCredentials: true,
        })
        return result;
      } catch (axiosError) {
        let err = axiosError
        return {
          error: { status: err.response?.status, data: err.response?.data },
        }
      }
    }