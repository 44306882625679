import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import reducer from './reducer';
import { authenticationAPI } from './reducer/services/signin';
import { processAPI } from './reducer/services/process';
import { productAPI } from './reducer/services/product';
import { customerAPI } from './reducer/services/customer';
import { orderAPI } from './reducer/services/order';
import { EmployeeAPI } from './reducer/services/employee';
import { MessageAPI } from './reducer/services/message';
import { companyAPI } from './reducer/services/company';

export const store = configureStore({
  reducer: reducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ serializableCheck: false }).concat([authenticationAPI.middleware,
    processAPI.middleware,
    productAPI.middleware,
    customerAPI.middleware,
    orderAPI.middleware,
    EmployeeAPI.middleware,
    MessageAPI.middleware,
    companyAPI.middleware
    ])
});

setupListeners(store.dispatch)
