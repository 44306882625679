import React, { useEffect, useState } from 'react';
import { Grid, Modal, CssBaseline, Stepper, Step, StepButton, Box, SwipeableDrawer } from '@mui/material';
import { onboardingStyles } from './onboardingStyles';
import CompanyDetailsComponent from './companyDetailsComponent';
import LogoUploadComponent from './logoUploadComponent';
import AddressComponent from './addressComponent';
import CancelIcon from '@mui/icons-material/Cancel';
import { useMediaQuery } from '@mui/material';
import { findTzByKey, findTzByName } from 'timezone-select-js';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 850,
  height: 600,
  backgroundColor: '#FFFFFF',
  boxShadow: 24,
  borderRadius: 10,
  padding: 15,
  overflow: 'auto',
};

function getSteps() {
  return ['', '', ''];
}
function OnboardingBaseComponent({ companyDetails }) {
  const onboardingClasses = onboardingStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const showSwipeableDrawer = useMediaQuery('(max-width:576px)');
  const [onboardingDetails, setOnboardingDetails] = useState({
    domain: '',
    companyEmail: '',
    phone: '',
    companyName: companyDetails.companyName || '',
    timeZone: findTzByName(companyDetails.timeZone) || findTzByName(findTzByKey(companyDetails.timeZone).link),
    address: {
      doorNo: '',
      street: '',
      city: '',
      pinCode: '',
      state: ''
    },
    image: undefined
  });
  const [openModal, setOpenModal] = useState(true);
  const steps = getSteps();

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const stepperContent = () => {
    if (activeStep === 0) {
      return (
        <CompanyDetailsComponent
          onboardingDetails={onboardingDetails}
          setOnboardingDetails={setOnboardingDetails}
          setActiveStep={setActiveStep}
          companyDetails={companyDetails}
        />
      );
    } else if (activeStep === 1) {
      return (
        <>
          <AddressComponent
            onboardingDetails={onboardingDetails}
            setOnboardingDetails={setOnboardingDetails}
            setOpenModal={setOpenModal}
            setActiveStep={setActiveStep}
          />
        </>

      );
    } else {
      return (
        <LogoUploadComponent onboardingDetails={onboardingDetails}
          setOnboardingDetails={setOnboardingDetails} setOpenModal={setOpenModal} company={companyDetails} />
      )
    }
  };
  // `disableBackdropClick` is removed by codemod.
  // You can find more details about this breaking change in [the migration guide](https://mui.com/guides/migration-v4/#modal)
  return (
    <div>
      <Box sx={{ display: { xs: 'none', md: 'block' } }}>
        <Modal open={openModal && !showSwipeableDrawer} onClose={() => setOpenModal(!openModal)}>
          <Box style={style}>
            <CssBaseline>
              <Grid container className={onboardingClasses.container}>
                <Grid item xs={12} md={6} style={{ margin: 'auto' }}>
                  <img src="/icons/svgs/onboarding.svg"></img>
                </Grid>
                <Grid item xs={12} md={6}>
                  <div className={onboardingClasses.formContainer}>
                    <div className={onboardingClasses.headingContent}>
                      <span className={onboardingClasses.header}>
                        Tell us about yourself
                      </span>
                      <CancelIcon className={onboardingClasses.cancelBtn} onClick={() => setOpenModal(!openModal)} />
                    </div>
                    <span className={onboardingClasses.subText}>
                      So we can provide you best service
                    </span>
                    <Stepper nonLinear activeStep={activeStep} className={onboardingClasses.stepper}>
                      {steps.map((label, index) => (
                        <Step key={label} variant="kfStepper">
                          <StepButton
                            onClick={handleStep(index)}
                            completed={completed[index]}
                          >
                            {label}
                          </StepButton>
                        </Step>
                      ))}
                    </Stepper>
                    <div>
                      {stepperContent()}
                      <div className={onboardingClasses.skipContainer}>
                        <span className={onboardingClasses.skip} onClick={() => {
                          setOpenModal(false);
                        }}>
                          Skip &gt;&gt;
                        </span>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </CssBaseline>
          </Box>
        </Modal>
      </Box>
      <Box sx={{ display: { xs: 'block', md: 'none' } }}>
        <SwipeableDrawer open={openModal && showSwipeableDrawer}
          anchor="bottom"
          onClose={() => setOpenModal(!openModal)}>
          <span className={onboardingClasses.mobileHeading}>Tell us about yourself</span>
          <Stepper nonLinear activeStep={activeStep} className={onboardingClasses.mobileStepper}>
            {steps.map((label, index) => (
              <Step key={label} variant="kfStepper">
                <StepButton
                  onClick={handleStep(index)}
                  completed={completed[index]}
                >
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>
          <div>
            {stepperContent()}
          </div>
        </SwipeableDrawer>
      </Box>
    </div>
  );
}

export default OnboardingBaseComponent;