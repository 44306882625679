import React, { useEffect, useState, useRef, useImperativeHandle } from 'react';
import { useGetAllProcessQuery } from 'store/reducer/services/process';
import { Search } from '@mui/icons-material';
import { Button, SimpleInput, ButtonGroup } from '@tt/fe-common';
import Chip from '@mui/material/Chip';
import cx from 'classnames';
import Checkbox from '@mui/material/Checkbox';
import styles from './processList.module.scss';
import NewProcess from './newProcess';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { setBottomSheetComponent } from 'store/reducer/layout';
import { useDispatch, useSelector } from 'react-redux';
import { addProcess as addProcessToStore } from 'store/reducer/createProduct';
import NoProcess from '../../../assets/icons/no-process-small.svg';


export default function ProcessList({ toggleProcessList }) {
  const { data, isLoading } = useGetAllProcessQuery();
  const { processInvolved } = useSelector(state => state.newProduct);
  const [processList, setProcessList] = useState([]);
  const [createNewProcess, toggleCreateNewProcess] = useState(false);
  const newProcessRef = useRef();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!processList?.length)
      setProcessList(data);
  }, [data, processList]);

  const create = async () => {
    if (createNewProcess) {
      const result = await newProcessRef?.current?.createProcess();
      if (result)
        toggleCreateNewProcess(false)
      toggleCreateNewProcess(false);
    }
    else {
      dispatch(setBottomSheetComponent())
      toggleProcessList(false);
    }
  };

  const displayMetaInfo = (metaInformation) => {
    if (metaInformation?.length > 0) {
      return (
        <div className={styles.meta}>
          {metaInformation.map((meta, index) => {
            return (
              <span style={{ marginRight: '10px' }} key={index}>
                {Object.keys(meta)[0]?.replace('_', ' ')}:{' '}
                {meta[Object.keys(meta)[0]]}
              </span>
            );
          })}
        </div>
      );
    }
  };
  const filterProcess = (searchText) => {
    if (searchText) {
      const searchedProcesses = processList.filter((process) => {
        return process.processName
          .toLowerCase()
          .includes(searchText.toLowerCase());
      });
      setProcessList(searchedProcesses);
    } else {
      setProcessList(data);
    }
  }

  const addProcess = (process, event) => {
    if (event.target.checked) {
      dispatch(addProcessToStore({ process, event: true }));
    } else {
      dispatch(addProcessToStore({ process, event: false }))
    }
  }
  const isProcessAddded = (processName) => {
    return processInvolved?.some((process) => {
      console.log(process.processName.trim() === processName.trim(), process.processName, processName);
      return process.processName.trim() === processName.trim();
    });
  }
  const getButtonLabel = () => {
    if (createNewProcess || !processInvolved?.length) return `Add Process`;
    else return `Add ${processInvolved?.length} Processes`;
  }
  const emptyStateProcess = () => {
    return (
      <div className={styles.noProcess}>
        <div><img src={NoProcess} /></div>
        <h1>No Process Found</h1>
        <p>Looks like you haven't creared any process yet...</p>
      </div>
    )
  }

  return (
    <div className={styles.modalContainer}>
      <div className={cx(styles['header-wrapper'])}>
        {
          !createNewProcess && (
            <>
              <div className={styles.header}>
                <span className={styles.head}>Add Process</span>
                <Button variant="text" size="min" color="info"
                  onClick={() => toggleCreateNewProcess(!createNewProcess)}>
                  New process
                </Button>
              </div>
            </>
          )
        }
        {createNewProcess &&
          <div className="flex justify-between">
            <Button variant="text" size="exact" color="info"
              onClick={() => toggleCreateNewProcess(!createNewProcess)}
            ><ArrowBackIosIcon fontSize="small" /> Back
            </Button>
            <span className={styles.newHeading}>New Process</span>
          </div>
        }
      </div>
      <div className={styles.body}>
        {
          !createNewProcess && (
            <div className={styles.container}>
              <SimpleInput variant="with-icon"
                handleChange={filterProcess}
                size="full-width"
                placeholder="Search Process"
                IconComponent={Search}
                className={styles.input}
              />
              {!isLoading &&
                processList?.length ?
                processList.map(process => {
                  return (
                    <div key={process.id}>
                      <div className={styles.process}>
                        <div>
                          <Checkbox
                            onChange={(event) => addProcess(process, event)}
                            name={process.processName}
                            checked={isProcessAddded(process.processName)}
                            color="info"
                          ></Checkbox>
                          <span className="font-medium text-sm">{process.processName}</span>
                        </div>
                        <Chip color="info" variant="outlined" label={`${process.timeSpan} ${process.unit}`} />
                      </div>
                      {displayMetaInfo(process.metaInformation)}
                    </div>
                  )
                }) : !isLoading && emptyStateProcess()
              }
            </div>
          )
        }
        {
          createNewProcess && (
            <NewProcess ref={newProcessRef}
              setProcessList={setProcessList}
              processList={processList} />
          )
        }
      </div>
      <ButtonGroup
        classes={styles.footer}
        primaryCta={getButtonLabel()}
        secondaryCta="Cancel"
        primaryFn={create}
        secondaryFn={() => {
          toggleProcessList(false);
          dispatch(setBottomSheetComponent())
        }} />
    </div>
  )
}
