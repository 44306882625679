import React, { useRef, useState } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { makeStyles, createStyles } from '@mui/styles';
const imgStyles = makeStyles((theme) => 
createStyles({
  img: {
    display: 'block',
    width: 'auto',
    height: '100%',
    maxWidth: '100%',
    maxHeight: '100%',
  }
})
)

export default function ImageCropper ({ imageToCrop, setCroppedImageURL }) {
    const classes = imgStyles();
    const cropperRef = useRef(null);
    const onCrop = () => {
        const imageElement = cropperRef?.current;
        const cropper = imageElement?.cropper;
        setCroppedImageURL(cropper.getCroppedCanvas('image/jpeg', 1.0).toDataURL())
    };

  return (
    <>
        <Cropper
            src={imageToCrop}
            initialAspectRatio={1 / 1}
            className = {classes.img}
            guides={false}
            crop={onCrop}
            ref={cropperRef}
            />
    </>
  );
};
