import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { Select as KSelect } from '@tt/fe-common';
const shortHandDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
const timeSlots = ["12:00 AM", "12:30 AM", "01:00 AM", "01:30 AM",
  "02:00 AM", "02:30 AM", "03:00 AM", "03:30 AM", "04:00 AM", "04:30 AM",
  "05:00 AM", "05:30 AM", "06:00 AM", "06:30 AM", "07:00 AM", "07:30 AM",
  "08:00 AM", "08:30 AM", "09:00 AM", "09:30 AM", "10:00 AM", "10:30 AM",
  "11:00 AM", "11:30 AM", "12:00 PM", "12:30 PM", "01:00 PM", "01:30 PM",
  "02:00 PM", "02:30 PM", "03:00 PM", "03:30 PM", "04:00 PM", "04:30 PM",
  "05:00 PM", "05:30 PM", "06:00 PM", "06:30 PM", "07:00 PM", "07:30 PM",
  "08:00 PM", "08:30 PM", "09:00 PM", "09:30 PM", "10:00 PM", "10:30 PM",
  "11:00 PM", "11:30 PM", "11:59 PM"]
const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
export default function WorkingHours({ businessHours, workingHours, setWorkingHours }) {
  const [selectedDays, setSelectedDays] = useState(0);
  useEffect(() => {
    let workingDays = 0;
    if (workingHours?.length) {
      workingHours?.forEach(({ isHoliday }) => {
        if (!isHoliday)
          workingDays++;
      });
      setSelectedDays(workingDays);
    }
  }, [workingHours]);
  const isSelected = (index) => {
    if (workingHours && workingHours?.[index])
      return workingHours?.[index].isHoliday ? "" : styles.selected;
    else
      return "";
  }

  const addOrRemoveWorkingDays = (index) => {
    setWorkingHours(existingItems => {
      return [
        ...existingItems.slice(0, index),
        {
          ...existingItems[index],
          isHoliday: !existingItems[index].isHoliday,
        },
        ...existingItems.slice(index + 1),
      ]
    });
  }
  const renderDays = () => {
    return shortHandDays.map((day, index) => {
      return <div key={day} className={`${styles.dayChip} 
      flex items-center flex-wrap mr-8 mt-16 
      cursor-pointer ${isSelected(index)}`}
        onClick={() => addOrRemoveWorkingDays(index)}>
        <span className="font-medium text-sm text-center">{day}</span>
      </div>
    })
  }

  const timeSelectValues = timeSlots.map((slot) => {
    return {
      value: slot,
      text: slot
    }
  });

  const setTime = (time, index, boundary) => {
    let [hours, rest] = time.split(":");
    let [mins, noon] = rest.split(" ");
    if (noon === 'PM') {
      hours = 12 + +hours % 12;
    } else {
      if (+hours === 12) {
        hours = 0;
      }
    }
    setWorkingHours(existingItems => {
      return [
        ...existingItems.slice(0, index),
        {
          ...existingItems[index],
          [boundary]: `${hours}:${mins}`,
        },
        ...existingItems.slice(index + 1),
      ]
    });
  }

  const renderBusinessHourSelection = () => {
    return days.map((day, index) => {
      let { startTime, endTime } = workingHours[index];
      let [starthours, startMins] = startTime.split(":");
      let [endhours, endMins] = endTime.split(":");
      starthours = +starthours;
      endhours = +endhours;
      if (starthours > 12) {
        starthours = starthours % 12;
        starthours = starthours < 10 ? `0${starthours}` : `${starthours}`;
        startTime = `${starthours}:${startMins} PM`;
      } else {
        starthours = starthours % 12;
        starthours = starthours < 10 ? `0${starthours}` : `${starthours}`;
        startTime = `${starthours}:${startMins} AM`;
      }
      if (starthours === '00') {
        startTime = `12:${startMins} AM`;
      }
      if (endhours > 12) {
        endhours = endhours % 12;
        endhours = endhours < 10 ? `0${endhours}` : `${endhours}`
        endTime = `${endhours}:${endMins} PM`;
      } else {
        endhours = endhours % 12;
        endhours = endhours < 10 ? `0${endhours}` : `${endhours}`
        endTime = `${endhours}:${endMins} AM`;
      }
      if (endhours === '00') {
        startTime = `12:${startMins} AM`;
      }
      return (<div className="font-semibold text-sm mt-24 flex items-center" key={day}>
        <span className="w-32">{day}</span>
        <KSelect value={startTime}
          data={timeSelectValues}
          className="w-32"
          variant="time"
          handleChange={(e) => setTime(e.target.value, index, 'startTime')}
        ></KSelect>
        <span className="mx-32">to</span>
        <KSelect value={endTime}
          data={timeSelectValues}
          className="w-32"
          variant="time"
          handleChange={(e) => setTime(e.target.value, index, 'endTime')}
        ></KSelect>
      </div>)
    });
  }
  return <>
    <p className="font-semibold text-sm">Select the working days</p>
    <div className="flex items-center flex-wrap">{renderDays()} <span className="mt-20 ml-8 font-medium text-sm">({selectedDays} days selected)</span></div>
    <p className="font-semibold text-sm mt-24 mb-16">Enter the working hours</p>
    {workingHours?.length && <div>{renderBusinessHourSelection()}</div>}

  </>
}