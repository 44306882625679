import { createTheme } from '@mui/material/styles';

import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';

export const THEMING = {
  DARK: 'dark',
  LIGHT: 'light'
};

export const colorPalette = {
  dark: {
    base: {
      b900: '#080807',
      b800: '#141413',
      b700: '#212120',
      b600: '#2D2D2C',
      b500: '#393939',
      b400: 'rgba(255, 255, 255, 0.8)',
      b300: 'rgba(255, 255, 255, 0.85)',
      b200: 'rgba(255, 255, 255, 0.9)',
      b100: 'rgba(255, 255, 255, 0.925)',
      b050: 'rgba(255, 255, 255, 0.95)',
      b000: '#FFFFFF'
    },
    ascent: {
      base: '#080807',
      lightBase: '#6CB9FF',
      warning: '#FFC700',
      error: '#FF6C6C',
      info: '#0177FB',
      success: '#5C913B',
      lightSuccess: '#5C913B',
      lighterSuccess: '#D7F39E',
    },
    grey: {
      e000: '#FFFFFF',
      e600: '#353648',
      e700: '#1E1D2B',
      e800: '#181725',
      e900: '#0C0C16'
    }
  },
  light: {
    base: {
      b900: '#080807',
      b800: '#141413',
      b700: '#212120',
      b600: '#2D2D2C',
      b500: '#393939',
      b400: 'rgba(255, 255, 255, 0.8)',
      b300: 'rgba(255, 255, 255, 0.85)',
      b200: 'rgba(255, 255, 255, 0.9)',
      b100: '#F2F2F2',
      b050: 'rgba(255, 255, 255, 0.95)',
      b000: '#FFFFFF'
    },
    ascent: {
      base: '#FFFFFF',
      lightBase: '#6CB9FF',
      warning: '#FFC700',
      error: '#FF6C6C',
      info: '#5045CC',
      infoBackground: 'rgba(80, 69, 204, 0.3)',
      success: '#5C913B',
      lightSuccess: '#CEF490',
      lighterSuccess: '#D7F39E',
    },
    support: {

    },
    grey: {
      e000: '#FFFFFF',
      e100: '#EBEBEB',
      e200: '#F5F6F7',
      e300: '#FAFAFA',
      e600: '#353648',
      e700: '#1E1D2B',
      e800: '#181725',
      e900: '#0C0C16',
      e1600: '#000000'
    }
  }
}

const getDesignTokens =
  (mode) => {
    let bodyBackground;
    switch (mode) {
      case THEMING.DARK:
        bodyBackground = colorPalette[mode].base.b900;
        break;
      case THEMING.LIGHT:
        bodyBackground = '#fff'
        break;
    }
    const theme = createTheme({
      spacing: (factor) => `${0.25 * factor}rem`,
      typography: {
        htmlFontSize: 16,
        fontFamily: [
          'Poppins',
          '-apple-system',
          'BlinkMacSystemFont',
          '"Segoe UI"',
          'Arial',
          'sans-serif',
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(','),
        label: {
          fontSize: 12,
          fontWeight: 500
        },
        link: {
          fontSize: 16
        },
        h1: {
          fontSize: '1.5rem',
          fontWeight: 700
        },
        h2: {
          fontSize: 24
        },
        h4: {
          fontSize: 20
        },
        h5: {
          fontSize: 16,
          fontWeight: 500
        },
        subtext: {
          fontSize: 12,
          lineHeight: 1.6667
        }
      },
      palette: {
        mode,
        primary: {
          main: colorPalette[mode].ascent.base
        },
        light: {
          main: colorPalette[mode].grey.e200,
          contrastText: colorPalette[mode].ascent.info
        },
        error: {
          main: colorPalette[mode].ascent.error
        },
        warning: {
          main: colorPalette[mode].ascent.warning
        },
        info: {
          main: colorPalette[mode].ascent.info,
        },
        success: {
          main: colorPalette[mode].ascent.success,
          light: colorPalette[mode].ascent.lightSuccess
        },
        cancel: {
          main: colorPalette[mode].grey.e600,
          contrastText: colorPalette[mode].grey.e000
        },
        base: colorPalette[mode].base
      },
      overrides: {},
      components: {
        MuiStep: {
          variants: [
            {
              props: { variant: 'kfStepper' },
              style: {
                svg: {
                  '&.Mui-active': {
                    color: colorPalette[mode].grey.e1600,
                    "& text": {
                      fill: colorPalette[mode].grey.e200
                    }
                  },
                  color: colorPalette[mode].base.b100
                }
              }
            }
          ],
        },
        MuiCard: {
          variants: [
            {
              props: { variant: 'kfCard' },
              style: {
                borderRadius: '12px',
                boxShadow: '2px 4px 12px -2px rgb(0 0 0 / 16%)',
                border: 'solid 1px #e8e8e8'
              }

            }
          ]
        },
        MuiTab: {
          styleOverrides: {
            root: {
              '&.Mui-selected': {
                color: colorPalette[mode].ascent.info,
              }
            }
          }
        },
        MuiList: {
          styleOverrides: {
            root: {
              maxHeight: 250,
              margin: 10,
              paddingTop: 0,
              paddingBottom: 0,
              margin: 0
            }
          }
        },
        MuiTableRow: {
          variants: [
            {
              props: { variant: 'kfTable' },
              style: {
                "& td": {
                  fontSize: 12,
                  borderBottom: 'none',
                  padding: 6,
                },
                "&:nth-of-type(even)": {
                  backgroundColor: colorPalette[mode].grey.e300,
                }
              }
            }
          ]
        },
        MuiMenuItem: {
          variants: [
            {
              props: { variant: "time" },
              style: {
                '&.Mui-selected': {
                  background: colorPalette[mode].ascent.infoBackground,
                  color: colorPalette[mode].ascent.info,
                  borderRadius: 4,
                  '& .MuiTypography-root': {
                    color: colorPalette[mode].ascent.info,
                  }
                }
              }
            },
            {
              props: { variant: "kfAssign" },
              style: {
                borderBottom: "solid 0.4px lightgrey",
                borderRadius: 2,
                padding: "10px 15px",
              }
            }
          ]
        },
        MuiOutlinedInput: {
          styleOverrides: {
            root: {
              "&.Mui-focused": {
                border: `solid 1px #cbcbcb`,
              }
            },
            input: {
              padding: "6px 8px",
              fontSize: 14,
            }
          }
        },
        MuiListItemText: {
          styleOverrides: {
            root: {
              marginTop: 8
            }
          }
        },
        MuiCircularProgress: {
          variants: [
            {
              props: { variant: 'progress' },
              style: {
                color: colorPalette[mode].ascent.success
              }
            },
            {
              props: { variant: 'danger' },
              style: {
                color: colorPalette[mode].ascent.error
              }
            },
            {
              props: { variant: 'warning' },
              style: {
                color: colorPalette[mode].ascent.warning
              }
            },
          ]
        },
        MuiFormControl: {
          variants: [
            {
              props: { type: 'kfDropdown' },
              style: {
                "& label": {
                  top: "-1rem",
                  position: "relative",
                  left: "-0.7rem"
                },
                "& div": {
                  "& fieldset": {
                    border: 'none'
                  },
                  "& .MuiChip-root": {
                    background: colorPalette[mode].ascent.lighterSuccess,
                    padding: "5px 0",
                    marginBottom: 5
                  }
                }
              }
            }
          ]
        },
        MuiTableHead: {
          variants: [
            {
              props: { variant: 'kfTable' },
              style: {
                backgroundColor: colorPalette[mode].grey.e100,
                "& th": {
                  fontSize: 12,
                  borderBottom: 'none',
                  padding: '4px 0px 4px 8px',
                  fontWeight: '600'
                }
              }
            }
          ]
        },
        MuiChip: {
          styleOverrides: {
            fontSize: 10,
            fontWeight: 700,
            height: 24,
            padding: 5,
            root: {
              height: 24,
              padding: 5,
              borderRadius: 4,
              "& span": {
                paddingLeft: 0,
                paddingRight: 0,
                fontSize: 10,
                fontWeight: 700,
              },
            },
          }
        },
        MuiTableContainer: {
          styleOverrides: {
            root: {
              boxShadow: '0px 4px 34px rgba(0, 0, 0, 0.05)',
              borderRadius: 8
            }
          }
        },
        MuiInputLabel: {
          styleOverrides: {
            formControl: {
              color: colorPalette[mode].base.b000
            }
          }
        },
        MuiButton: {
          styleOverrides: {
            root: {
              textTransform: 'none',
              minWidth: 10,
            }
          }
        },
        MuiSnackbar: {
          styleOverrides: {
            root: {
              '& .MuiAlert-filledSuccess': {
                background: "#FFF",
                color: 'black',
                borderLeft: `solid 4px ${colorPalette[mode].ascent.success}`,
                '& .MuiAlert-icon': {
                  color: colorPalette[mode].ascent.success
                }
              },
              '& .MuiAlert-filledError': {
                background: "#FFF",
                color: 'black',
                borderLeft: `solid 4px ${colorPalette[mode].ascent.error}`,
                '& .MuiAlert-icon': {
                  color: colorPalette[mode].ascent.error
                }
              },
            }
          }
        },
        MuiInputBase: {
          styleOverrides: {
            root: {
              /**
               * BUG: insufficient specificity
               */
              '&::after': {
                borderBottom: "none !important"
              },
              '&::before': {
                borderBottom: "none !important"
              },
              MuiFilledInput: {
                root: {
                  '&::before': {
                    borderBottom: 'none !important'
                  }
                }
              }
            }
          }
        },
        MuiDrawer: {
          styleOverrides: {
            paperAnchorBottom: {
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10
            }
          }
        }
      }
    });
    theme.components.MuiTypography = {
      styleOverrides: {
        root: {
          color: theme.palette.primary.contrastText
        },
        subtext: {
          color: theme.palette.grey[600]
        }
      }
    };
    theme.components.MuiInputLabel = {
      styleOverrides: {
        root: {
          color: theme.palette.primary.contrastText,
          fontSize: theme.typography.label.fontSize,
          fontWeight: theme.typography.label.fontWeight,
          marginBottom: 4,
        }
      }
    };
    theme.components.MuiFilledInput = {
      styleOverrides: {
        root: {
          borderRadius: theme.shape.borderRadius,
          '&:hover:not(.Mui-disabled):before': {
            border: 0
          }
        },
        input: {
          height: 36,
          paddingTop: 0,
          paddingBottom: 0,
          lineHeight: 36
        },
      }
    };
    theme.components.MuiSpeedDial = {
      styleOverrides: {
        fab: {
          backgroundColor: theme.palette.info.main,
          color: theme.palette.info.contrastText
        }
      }
    };
    theme.components.MuiSelect = {
      styleOverrides: {
        select: {
          height: 36
        }
      }
    };
    theme.components.MuiPaper = {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.grey.e000,
        }
      }
    }
    theme.palette.background.default = bodyBackground;
    return theme;
  };

export default getDesignTokens;

export const darkTheme = getDesignTokens(THEMING.DARK);
export const lightTheme = getDesignTokens(THEMING.LIGHT);
export const themeList = {
  [THEMING.DARK]: darkTheme,
  [THEMING.LIGHT]: lightTheme
};