import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setHeader } from 'store/reducer/layout';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import WorkingHours from './WorkingHours';
import Holidays from './Holiday';
import { ButtonGroup } from '@tt/fe-common';
import { useNavigate } from 'react-router-dom';
import styles from './styles.module.scss';
import { useGetBusinessHoursQuery, useUpdateBusinessHoursMutation, authenticationAPI } from '../../../store/reducer/services/signin';
import moment from 'moment-timezone';

const INPUT_TIME_FORMAT = "H:mm";
const OUTPUT_TIME_FORMAT = "HH:mm";

function convertToTimeZone(time, timeZone) {
  return moment.utc(time, INPUT_TIME_FORMAT).tz(timeZone).format(INPUT_TIME_FORMAT);
}

function convertToUTC(time, timeZone) {
  return moment.tz(time, OUTPUT_TIME_FORMAT, timeZone).utc().format(OUTPUT_TIME_FORMAT);
}

export default function BusinessCalendar() {
  const [currentTab, setCurrentTab] = useState('1');
  const { data: businessHours, isSuccess } = useGetBusinessHoursQuery();
  const [holidays, setHolidays] = useState([]);
  const [updateBusinessHours] = useUpdateBusinessHoursMutation();
  const businessHourSlot = businessHours?.businessHourSlot;
  const holidayList = businessHours?.holidayList;
  const timeZone = businessHours?.timeZone || moment.tz.guess();
  const [workingHours, setWorkingHours] = useState(null);
  const { refetch } = authenticationAPI.endpoints.getBusinessHours.useQuerySubscription();

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setHeader('Business Calendar'));
    refetch();
  }, []);

  useEffect(() => {
    if (holidays[0] && !Object.keys(holidays[0])) {
      setHolidays([]);
    }
    else if (holidayList) {
      setHolidays([...holidayList]);
    }
  }, [holidayList]);

  function serializeWorkingHours() {
    let serializedBusinessHours = [];
    businessHourSlot?.forEach((slot) => {
      const startTime = convertToTimeZone(slot?.startTime, timeZone);
      const endTime = convertToTimeZone(slot?.endTime, timeZone);
      serializedBusinessHours.push({
        ...slot,
        startTime,
        endTime
      })
    })
    return serializedBusinessHours
  }

  function deserializeWorkingHours() {
    let deserializedWorkingHours = [];
    workingHours?.forEach((slot) => {
      const startTime = convertToUTC(slot?.startTime, timeZone);
      const endTime = convertToUTC(slot?.endTime, timeZone);
      deserializedWorkingHours.push({
        ...slot,
        startTime,
        endTime
      })
    })
    return deserializedWorkingHours;
  }

  useEffect(() => {
    setWorkingHours(serializeWorkingHours());
  }, [businessHourSlot]);
  const navigate = useNavigate();

  const saveBusinessHours = async () => {
    const payload = {
      companyId: businessHours.companyId,
      businessHourSlot: deserializeWorkingHours(),
      holidayList: [...holidays],
      timeZone
    }
    await updateBusinessHours(payload).unwrap();
  }

  return (
    <div>
      {isSuccess && (<div className="w-full lg:w-9/12">
        <TabContext value={currentTab}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Business Hours" value="1" />
              <Tab label="Holidays" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1"><WorkingHours businessHours={businessHours}
            workingHours={workingHours} setWorkingHours={setWorkingHours} /></TabPanel>
          <TabPanel value="2"><Holidays holidayList={holidayList}
            holidays={holidays} setHolidays={setHolidays} /></TabPanel>
        </TabContext>
      </div>)}
      <div className={styles.ButtonGroupContainer}>
        <ButtonGroup
          primaryCta="Save"
          secondaryCta="Cancel"
          primaryFn={saveBusinessHours}
          secondaryFn={() => {
            navigate(-1);
          }} />
      </div>
    </div>
  )
}