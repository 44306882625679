import { Formik, Form, FieldArray } from 'formik';
import { SimpleInput, Button, ButtonGroup } from '@tt/fe-common';
import { useRef, useState, useEffect } from 'react';
import styles from '../new.module.scss';
import { Typography, useMediaQuery } from '@mui/material';
import { updateProduct } from 'store/reducer/productCart';
import { useDispatch, useSelector } from 'react-redux';
import { findTotalTime } from '../../../utils/timeUtility';

export default function TimeModify({
  product,
  toggleOpenChangeTime,
}) {
  const formRef = useRef();
  const dispatch = useDispatch();
  const forMobile = useMediaQuery('(max-width:576px)');
  const [totHours, setToHours] = useState(product.processList.map(item => parseInt(item.timeSpan)).reduce((prev, curr) => prev + curr, 0));

  const initialValues = {
    product: product,
  };

  useEffect(() => {
    if (formRef.current?.values) {
      setToHours(formRef.current.values.product.processList.map(item => parseInt(item.timeSpan)).reduce((prev, curr) => prev + curr, 0));
    }
  }, [formRef.current?.values]);

  const saveProduct = async (values) => {
    values.product.totalTime = findTotalTime(values.product.processList);
    setToHours(values.product.processList.map(item => parseInt(item.timeSpan)).reduce((prev, curr) => prev + curr, 0));
    dispatch(updateProduct(values.product));
    toggleOpenChangeTime(false);
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        innerRef={formRef}
        onSubmit={saveProduct}
      >
        {({ values, handleChange, handleBlur }) => (
          <Form>
            <FieldArray
              name="product"
              render={(arrayHelpers) => (
                <div>
                  {!forMobile ?
                    <div>
                      <Typography variant="h5"><strong>Time to complete(Only this order)</strong></Typography>
                      {values.product.processList.map((process, index) => (
                        <div className="grid grid-cols-2 gap-4 mt-16">
                          <p className="flex items-center">{process.processName}</p>
                          <SimpleInput
                            // className="mt-32"
                            size="full-width"
                            value={process.timeSpan}
                            onBlur={handleBlur}
                            handleChange={handleChange(
                              `product.processList.${index}.timeSpan`
                            )}
                            variant="fixed-unit"
                            fixedUnit="Hours"
                          />
                        </div>
                      ))}
                      <div className={styles.btnContainer}>
                        <Button
                          onClick={() => {
                            formRef.current.handleSubmit();
                          }}
                        >
                          save
                        </Button>
                        <Button
                          className={styles.btnCancel}
                          onClick={() => {
                            toggleOpenChangeTime(false);
                          }}
                        >
                          cancel
                        </Button>
                      </div>
                    </div> :
                    <div className="p-20">
                      <div className={styles.subHeading}>
                        <Typography variant="h5" align="center"><strong>Time to complete (hrs)</strong></Typography>
                        <Typography variant="caption">(Only this order)</Typography>
                      </div>
                      <div className={styles.bodyContainer}>
                        {values.product.processList.map((process, index) => (
                          <div className="mt-32">
                            <p className={styles.processName}>{process.processName}</p>
                            <div className="flex justify-content-space-between">
                              <SimpleInput
                                className={styles.input}
                                size="full-width"
                                variant="fixed-unit"
                                fixedUnit="Days"
                              />
                              <SimpleInput
                                className={styles.input}
                                size="full-width"
                                value={process.timeSpan}
                                onBlur={handleBlur}
                                handleChange={handleChange(
                                  `product.processList.${index}.timeSpan`
                                )}
                                variant="fixed-unit"
                                fixedUnit="Hours"
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="flex justify-content-space-between">
                        <span>Total time</span>
                        <div>
                          <span>0 Days</span>
                          <span className="ml-16">{totHours} Hours</span>
                        </div>
                      </div>
                      <div>
                        <ButtonGroup
                          primaryCta="Save"
                          secondaryCta="Cancel"
                          primaryFn={() => formRef.current.handleSubmit()}
                          secondaryFn={() => toggleOpenChangeTime(false)} />
                      </div>
                    </div>}
                </div>
              )}
            />
          </Form>
        )}
      </Formik>
    </>
  );
}
