import React, { useEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { CssBaseline, Drawer, SwipeableDrawer } from '@mui/material';
import cx from 'classnames';
import axios from 'axios';
import { increment, decrement } from 'store/reducer/counter';

import Header from './header';
import Menu from './menu';
import LeftMenu from './left-menu';
import KfFab from './fab';
import KfToaster from '../../components/KfToaster';
import ProtectedRoutes from '../auth/protectedRoutes';
import Signup from '../auth/signup';
import Dashboard from '../dashboard';
import ForgotPassword from '../auth/forgotPassword';
import Signin from '../signin';
import ResetPassword from '../auth/resetPassword';
import Process from 'pages/Process';
import AddProcess from '../Process/add';
import NewCustomer from '../customer/new';
import NewProduct from 'pages/product/new';
import NewOrder from 'pages/order/new';
import Settings from 'pages/settings';
import AgentList from 'pages/agent/list';
import Filter from 'pages/filter';
import AgentNew from 'pages/agent/new';
import Orders from 'pages/orders';
import Agent from 'pages/settings/agents';
import Message from 'pages/settings/messages';
//import WorkingHours from 'pages/settings/workingHours';
import BusinessCalendar from 'pages/settings/businessCalendar';
import Notifications from 'pages/notifications';
import Payment from 'pages/settings/payment';
import PlansAndBilling from 'pages/settings/plans-and-billing';
import ManageNotifications from 'pages/settings/notifications';
import { connectToWebSocket } from 'socket/connect';
import NewProcess from '../product/process/newProcess';
import AddCustomer from '../order/customer/new';
import Products from 'pages/product';

import { useCurrentUserQuery } from 'store/reducer/services/signin';
import { toggleMenu, setBottomSheetComponent, setBreadcrumbs, setToaster, setToasterMessage, setProfileDropDownState } from 'store/reducer/layout';
import styles from './layout.module.scss';
import './output.css';
import Notification from './Notification';
import { canShowMessage, getMessage } from '../../utils/toastrMessage';
import NotificationBaseComponent from '../../components/notifications/notificationBaseComponent';
import Track from 'pages/orders/track';
import { useNavigate } from 'react-router-dom';
import { getSubscriptionDetails } from 'store/reducer/subscription';
import PublicTrack from 'pages/orders/status';
import Customer from 'pages/customer';


const routeMap = [
  {
    path: '/product/new',
    Component: NewProduct,
    crumbs: [
      {
        path: '/home',
        text: 'Home'
      },
      {
        path: '/orders',
        text: 'Products'
      }
    ],
    current: 'New'
  },
  {
    path: '/product/edit',
    Component: NewProduct
  },
  {
    path: 'filter',
    Component: Filter
  },
  {
    path: '/order/new',
    Component: NewOrder,
    crumbs: [
      {
        path: '/home',
        text: 'Home'
      },
      {
        path: '/orders',
        text: 'Orders'
      }
    ],
    current: 'New'
  },
  {
    path: '/orders',
    Component: Orders
  },
  {
    path: '/agents',
    Component: AgentList
  },
  {
    path: '/agents/new',
    Component: AgentNew
  },
  {
    path: '/products',
    Component: Products
  },
  {
    path: '/process/add',
    Component: AddProcess
  },
  {
    path: '/customer/new',
    Component: NewCustomer
  },
  {
    path: '/settings/workingHours',
    Component: BusinessCalendar
  },
  {
    path: '/process',
    Component: Process
  },
  {
    path: '/settings/agents',
    Component: Agent
  },
  {
    path: '/settings/messages',
    Component: Message
  },
  {
    path: '/settings/manage-notifications',
    Component: ManageNotifications
  },
  {
    path: '/notifications',
    Component: Notifications
  },
  {
    path: '/settings',
    Component: Settings
  },
  {
    path: '/settings/notifications',
    Component: Payment
  },
  {
    path: '/settings/plans-and-billing',
    Component: PlansAndBilling
  },
  {
    path: '/',
    Component: Dashboard
  },
  {
    path: '/orders/track/:orderId',
    Component: Track,
    crumbs: [
      {
        path: '/home',
        text: 'Home'
      },
      {
        path: '/orders',
        text: 'Orders'
      }
    ],
  },
  {
    path: '/customers',
    Component: Customer
  }
];

function BreadCrumbHoc(props) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setBreadcrumbs({ ...props }));
  }, []);
  const Component = props.component;
  return (
    <Component {...props} />
  );
}

function Layout() {
  const drawerMenu = useSelector(state => state.layout.menu);
  const actionFromFab = useSelector(state => state.layout.isFab);
  const drawerBottomSheet = useSelector(state => state.layout.bottomSheet);
  const [currPathIndex, setCurrPathIndex] = useState(-1);
  const location = useLocation();
  const dispatch = useDispatch();
  const [userInfo, setUserInfo] = useState(null);
  const { data: userDetails } = useCurrentUserQuery();
  const [socketConnected, setSocketConnected] = useState(false);
  const hideFabPages = ['/signup', '/signin', '/forgot-password', '/reset-password', '/status'];
  const isUnAuthorizedPage = hideFabPages.includes(location.pathname) || location.pathname.includes("/status/");
  const toaster = useSelector(state => state.layout.toaster);
  const toasterMessage = useSelector(state => state.layout.toasterMessage);
  const [createNewProcess, setCreateNewProcess] = useState(false);
  const [createNewCustomer, setCreateNewCustomer] = useState(false);
  const history = useNavigate();
  const { isSubscriptionOver } = useSelector((state) => state?.subscription);

  axios.interceptors.request.use(
    (req) => {
      dispatch(increment());
      return req;
    },
    (err) => {
      return Promise.reject(err);
    }
  );
  axios.interceptors.response.use(
    (res) => {
      dispatch(decrement());
      if (canShowMessage(res.config.method, res.config.url)) {
        dispatch(setToasterMessage(getMessage(res.config.method, res.config.url, 'success')));
        dispatch(setToaster('success'));
      }
      return res;
    },
    (err) => {
      dispatch(decrement());
      const { method, url } = err.response.config;
      if (canShowMessage(method, url)) {
        dispatch(setToaster('error'));
        dispatch(setToasterMessage(getMessage(method, url, 'error')));
      }
      return Promise.reject(err);
    }
  );

  let BottomSheetComp;
  const { profileDropDownState } = useSelector(state => state.layout);

  useEffect(() => {
    setCurrPathIndex(
      routeMap.findIndex(route => route.path === location.pathname)
    );
  }, [location.pathname]);

  /** The purpose of this function is to set a Functional Component in
   * `bottomSheetComp` for the `SwipeableDrawer` to render when value is 
   * non-nullish. All the conditions/checks necessary are added here for
   * maintainable `jsx` */
  (function selectComponentForBottomSheet() {
    if (
      /* Checks authentication */
      !isUnAuthorizedPage &&
      /* Safe check if able to get the route path in time. Can be removed in
       * future if this condition is never falsey */
      location.pathname &&
      /* The current slug should be the one among the defined paths above */
      ~currPathIndex &&
      /* the component that is getting rendered within `Layout` should have
       * the static property `BottomDrawerChild` defined for the
       * `SwipeableDrawer` to be enabled */
      routeMap[currPathIndex].Component.BottomDrawerChild
    ) {
      /* When the exported component has more than 1 components to be rendered in
       * `SwipeableDrawer`, it is passed in as Array. Which element to render is
       * decided by `state.layout.bottomSheet`, which is its corresponding index.
       * Sometimes, the index can be set wrongly which can lead to app break. Those
       * checks are added below: */
      if (typeof drawerBottomSheet === 'number') {
        BottomSheetComp = routeMap[currPathIndex].Component.BottomDrawerChild[drawerBottomSheet]
          ? routeMap[currPathIndex].Component.BottomDrawerChild[drawerBottomSheet]
          : null;
      } else {
        BottomSheetComp = routeMap[currPathIndex].Component.BottomDrawerChild[0];
      }
    }
    if (actionFromFab) {
      BottomSheetComp = KfFab.BottomDrawerChild[drawerBottomSheet];
    }
  })();

  useEffect(() => {
    setUserInfo(userDetails);
    dispatch(getSubscriptionDetails())
  }, [userDetails])

  useEffect(() => {
    if (userInfo && !isSubscriptionOver && typeof isSubscriptionOver !== 'object') {
      connectToWebSocket(userInfo?.currentUser.companyId, setSocketConnected);
    }
  }, [userInfo, isSubscriptionOver]);

  useEffect(() => {
    if (isSubscriptionOver) {
      history("/settings/plans-and-billing");
    }
  }, [isSubscriptionOver]);

  return (
    <CssBaseline>
      <div className="app-container h-100">
        {!isUnAuthorizedPage && <LeftMenu />}
        <main className="app-main">
          {!isUnAuthorizedPage && <Header setCreateNewProcess={setCreateNewProcess} setCreateNewCustomer={setCreateNewCustomer} userDetails={userDetails} setUserInfo={setUserInfo} />}
          <div className={cx({ "app-content p-24": !isUnAuthorizedPage })}>
            {(socketConnected && userInfo) && <Notification userDetails={userInfo} />}
            {(socketConnected && userInfo) && <NotificationBaseComponent />}
            <Routes>
              <Route path="/signup" element={<Signup currentUser={userInfo} />} />
              <Route path="/signin" element={<Signin currentUser={userInfo} />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/status/:orderId" element={<PublicTrack />} />
              {
                routeMap.map((route, id) => {
                  const { Component, path } = route;
                  return (
                    <Route key={id} element={<ProtectedRoutes />}>
                      <Route path={path} element={
                        <BreadCrumbHoc crumbs={route.crumbs} current={route.current} component={Component} />
                      } />
                    </Route>
                  )
                })
              }

            </Routes>
            {
              !isUnAuthorizedPage &&
              /* At each component we have to update the static value as 
               * `ComponentName.fab = true` for the KfFab to be displayed.
               * This makes them hidden by default. */
              routeMap[currPathIndex] &&
              routeMap[currPathIndex].Component.fab &&
              <KfFab />
            }
          </div>
        </main>
        <Drawer open={drawerMenu} onClick={() => dispatch(toggleMenu())}>
          <Menu setUserInfo={setUserInfo} />
        </Drawer>
        {
          BottomSheetComp &&
          <SwipeableDrawer open={!!BottomSheetComp}
            anchor="bottom"
            onOpen={() => { }}
            onClose={() => dispatch(setBottomSheetComponent())}
          >
            <div className={cx(styles['bottom-sheet'], 'pt-16 px-24')}>
              {/* <img src={backIcon} onClick={() => dispatch(setBottomSheetComponent())} /> */}
              <BottomSheetComp />
            </div>
          </SwipeableDrawer>
        }
        <Drawer
          anchor="right"
          open={createNewCustomer}
          onClose={() => {
            setCreateNewCustomer(false)
          }}
        >
          <div className={styles.modalContainer}>
            <AddCustomer fromLayout={true} setCreateNewCustomer={setCreateNewCustomer} />
          </div>
        </Drawer>

        <Drawer
          anchor="right"
          open={createNewProcess}
          onClose={() => {
            setCreateNewProcess(!createNewProcess)
          }}
        >
          <div className={styles.modalContainer}>
            <NewProcess setCreateNewProcess={setCreateNewProcess} fromLayout={true} />
          </div>
        </Drawer>

      </div >
      {toaster === 'success' && <KfToaster openToaster={toaster === 'success'} variant={'success'} message={toasterMessage} />}
      {toaster === 'error' && <KfToaster openToaster={toaster === 'error'} variant={'error'} message={toasterMessage} />}
    </CssBaseline >
  );
}

export default Layout;
