import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getSocket } from '../../socket/connect';
import { ToastContainer, toast } from 'react-toastify';
import { getSubscriptionDetails } from '../../store/reducer/subscription';
import 'react-toastify/dist/ReactToastify.css';
export default function Notification({ userDetails }) {
  const { id } = userDetails.currentUser
  const socket = getSocket();
  const dispatch = useDispatch();
  useEffect(() => {
    socket.on('taskAssigned', (data) => {
      const { productName, processName, employeeId } = JSON.parse(data)
      if (id === employeeId) {
        const text = `You have assigned ${processName} for ${productName}`
        toast.info(text);
      }
    });
    socket.on('paymentMade', (data) => {
      dispatch(getSubscriptionDetails());
    });
  }, []);
  return (
    <>
      <ToastContainer position="top-right"
        autoClose={15000}
        hideProgressBar
        newestOnTop
        closeOnClick />
    </>
  )
}
