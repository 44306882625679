import React, { forwardRef } from 'react';
import withClickOutside from '../withClickOutside';
import Profile from './profile';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import cx from 'classnames'

const createDropDown = makeStyles((theme) => {
  return createStyles({
    label: {
      cursor: 'pointer',
      display: 'flex',
    },
    link: {
      '&:hover': {
        backgroundColor: theme.palette.info.main,
      },
    },
    dropdown: {
      position: 'absolute',
      zIndex: 10,
      top: 45,
      marginRight: 40,
      width: 140,
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: theme.palette.info.main,
      borderRadcreateStylesius: theme.shape.borderRadius + 'px',
      borderRadius: 10,
      right: 0,
      padding: '5px 5px',
      '& div': {
        padding: 10,
        cursor: 'pointer',
      },
      '& *': {
        color: theme.palette.info.contrastText
      },
    },
    container: {
      backgroundColor: theme.palette.info.main,
      borderRadcreateStylesius: theme.shape.borderRadius + 'px',
      '& *': {
        color: theme.palette.info.contrastText
      }
    },
    avatar: {
      marginLeft: 10,
      boxShadow: "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)"
    }
  })

}
);
const CreateItems = forwardRef(({ dropDownState, setDropDownState, userDetails,
  setUserInfo, setCreateNewProcess, setCreateNewCustomer }, ref) => {
  const createDropDownClasses = createDropDown();
  const options = ['Product', 'Order', 'Customer', 'Process'];
  const navigate = useNavigate();
  const { isSubscriptionOver } = useSelector((state) => state?.subscription); 
  const navigatePage = (page) => {
    setDropDownState(false);
    if (page === 'Process')
      setCreateNewProcess(true);
    else if (page === 'Customer')
      setCreateNewCustomer(true);
    else
      navigate(`/${page.toLowerCase()}/new`);
  };
  return (
    <div ref={ref}>
      <div className={createDropDownClasses.label} >
        <span className = {cx(isSubscriptionOver ? 'pointer-events-none' : '')}
          onClick={() => {
            setDropDownState(!dropDownState);
          }}
        >
          Create
          {dropDownState ? (
            <ArrowDropUpIcon />
          ) : (
            <ArrowDropDownIcon />
          )}
        </span>
        <Profile userDetails={userDetails} setUserInfo={setUserInfo} />
      </div>
      {dropDownState && (
        <div className={createDropDownClasses.dropdown}>
          {dropDownState &&
            options.map((option) => {
              return (
                <div
                  className={createDropDownClasses.link}
                  key={option}
                  onClick={() => navigatePage(option)}
                >
                  {option}
                </div>
              );
            })}
        </div>
      )}
    </div>
  )
});

export default withClickOutside(CreateItems);