import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const DOMAIN = `${process.env.REACT_APP_BACKEND_HOST}`;

const initialState = {
  docs: [],
  notifications: [],
  totalPages: 0,
  totalDocs: 0,
  isDeviceAMobile: true,
  isLoading: false,
  currentPage: 1,
  employees: [],
  stats: null
}
export const fetchOrders = createAsyncThunk(
  'orders/fetch',
  async (payload) => {
    delete payload['isMobile']
    try {
      const res = await axios({
        url: `${DOMAIN}/api/order/company`, method: 'POST', data: payload,
        withCredentials: true,
      });
      return res.data;
    } catch (e) {
      console.log('error', e);
    }
  });

export const assignTask = createAsyncThunk(
  'orders/assingTask',
  async ({ orderId, employeeId, payload }) => {
    const res = await axios({
      url: `${DOMAIN}/api/order/${orderId}/assign/${employeeId}`, method: 'PATCH', data: payload,
      withCredentials: true,
    });
    return res.data;
  }
);

export const updateProcessStatus = createAsyncThunk(
  'orders/updateProcessStatus',
  async ({ id, payload }) => {
    try {
      const res = await axios({
        url: `${DOMAIN}/api/order/process/${id}`, method: 'PUT', data: payload,
        withCredentials: true,
      });
      return res.data;
    } catch (e) {
      console.log('error', e);
    }
  }
);

export const getNotifications = createAsyncThunk(
  'order/notifications',
  async (params) => {
    try {
      const res = await axios({
        url: DOMAIN + '/api/order/notifications',
        method: 'GET',
        withCredentials: true,
        params
      });
      return res.data;
    } catch (e) {
      console.log('error', e);
    }
  }
);

export const getOrdersStats = createAsyncThunk(
  'order/stats',
  async (params) => {
    try {
      const res = await axios({
        url: DOMAIN + '/api/order/stats',
        method: 'GET',
        withCredentials: true,
        params
      });
      return res.data;
    } catch (e) {
      console.error(e);
    }
  }
)

export const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    setDeviceType: (state, action) => {
      state.isDeviceAMobile = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload
    },
    setEmployees: (state, action) => {
      state.employees = action.payload
    }
  },
  extraReducers: builder => {
    builder
      /** fetchOrders */
      .addCase(fetchOrders.fulfilled, (state, action) => {
        if (action.payload) {
          const { docs, totalPages, totalDocs, page } = action.payload
          if (page === 1) {
            state.docs = docs;
          }
          else {
            if (state.isDeviceAMobile)
              state.docs = [...state.docs, ...docs];
            else
              state.docs = [...docs];
          }
          state.totalPages = totalPages;
          state.totalDocs = totalDocs;
          state.isLoading = false;
        }
      })
      .addCase(fetchOrders.rejected, (state) => {
        state.isLoading = false;
      })

      /** assignTask */
      .addCase(assignTask.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(assignTask.fulfilled, (state, action) => {
        const updatedOrder = action.payload;
        state.docs = state.docs.map((order) => {
          if (updatedOrder.id === order.id) {
            return updatedOrder;
          }
          else {
            return order;
          }
        });
        state.isLoading = true;
      })

      /** updateProcessStatus */
      .addCase(updateProcessStatus.fulfilled, (state, action) => {
        const updatedOrder = action.payload;
        if (updatedOrder.percentageCompleted >= 100) {
          state.docs = state.docs.filter((order) => order.id !== updatedOrder.id);
        } else {
          state.docs = state.docs.map((order) => {
            if (updatedOrder.id === order.id) {
              return updatedOrder;
            }
            else {
              return order;
            }
          });
        }
        state.isLoading = true;
      })
      .addCase(updateProcessStatus.rejected, (state) => {
        state.isLoading = false;
      })

      /** getNotifications */
      .addCase(getNotifications.pending, state => {
        state.isLoading = true;
      })
      .addCase(getNotifications.fulfilled, (state, action) => {
        state.isLoading = false;
        state.notifications = action.payload.docs;
      })
      .addCase(getNotifications.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getOrdersStats.pending, state => {
        state.isLoading = true;
      })
      .addCase(getOrdersStats.fulfilled, (state, action) => {
        state.isLoading = false;
        state.stats = action.payload;
      })
      .addCase(getOrdersStats.rejected, (state) => {
        state.isLoading = false;
      })
  }
});

export const {
  setDeviceType,
  setCurrentPage,
  setEmployees,
} = ordersSlice.actions;

export default ordersSlice.reducer;
