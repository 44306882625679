import styles from './orders.module.scss';
import { setBottomSheetComponent } from 'store/reducer/layout';
import { useDispatch } from 'react-redux';
import { updateProcessStatus } from 'store/reducer/orders';
import { ProcessStatus } from './index';

export default function ProcessUpdate({ selectedOrder }) {
    const dispatch = useDispatch();

    const handleMark = async (status) => {
        const id = selectedOrder?.id;
        if (id) {
            const process = selectedOrder.processList.find((selectedOrder) => !selectedOrder.isCompleted)
            const processId = process.id;
            const processStatus = ProcessStatus[status];

            const payload = {
                processId,
                processStatus
            }
            dispatch(setBottomSheetComponent());
            dispatch(updateProcessStatus({ id, payload }));
        }
    }

    return (
        <div className={styles.processUpdate}>
            <span className={styles.first} onClick={() => handleMark("IN_PROGRESS")}>Start to Work</span>
            <span className={styles.first} onClick={() => handleMark("ON_HOLD")}>Put on Hold</span>
            <span onClick={() => handleMark("COMPLETED")}>Mark as Completed</span>
        </div>
    )
}