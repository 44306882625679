import { EmployeeAPI, useGetEmployeesMutation } from 'store/reducer/services/employee';
import { useAssignTaskMutation } from '../../store/reducer/services/order';
import React, { useEffect, useState } from 'react';
import { getRandomAvatar } from 'utils/randomImages';
import { ButtonGroup, SimpleInput } from '@tt/fe-common';
import styles from './orders.module.scss';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Search } from '@mui/icons-material';
import Radio from '@mui/material/Radio';
import { useDispatch } from 'react-redux';
import { setBottomSheetComponent } from 'store/reducer/layout';
import { assignTask } from 'store/reducer/orders';
import backIcon from 'assets/icons/light/back.svg';
import { useSelector } from 'react-redux';
import { setEmployees } from 'store/reducer/orders';

export default function AsignProcessMobile({ productName, orderId, processName }) {
  const [getEmployees] = useGetEmployeesMutation();
  const [isLoading, setIsLoading] = useState(false);
  const { employees } = useSelector(state => state.orders);
  //const [assignTask] = useAssignTaskMutation();
  const [options, setOptions] = useState([]);
  const [selectedValue, setSelectedValue] = React.useState('');
  const dispatch = useDispatch();

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleSubmit = async () => {
    let empName = ''
    let employeeId = selectedValue;

    options.map(({ name, id }) => {
      if (id === selectedValue) {
        empName = name;
      }
    })

    const payload = {
      employeeId,
      avatar: '',
      name: empName,
    }
    dispatch(assignTask({ orderId, employeeId, payload }));
    dispatch(setBottomSheetComponent());
    dispatch(setEmployees([]));
    dispatch(EmployeeAPI.util.invalidateTags(['Employee']));
  }

  useEffect(async () => {
    if (!employees.length && !isLoading) {
      setIsLoading(true);
      const searchPayload = {
        query: {
          field: "skills",
          operator: 'EQUAL',
          criteria: {
            productName: productName,
            processName: processName
          }
        }
      }
      const { docs } = await getEmployees({ ...searchPayload }).unwrap();
      dispatch(setEmployees([...docs]));
      const options = docs.map(({ name, id, email }) => {
        return {
          name, id, email
        }
      });
      setOptions(options);
    }
  }, [employees]);

  useEffect(() => {
    return () => {
      dispatch(setEmployees([]));
      setIsLoading(false);
    };
  }, []);

  const filterAgents = (searchText) => {
    if (searchText) {
      const searchedAgents = options.filter((option) => {
        return option.name
          .toLowerCase()
          .includes(searchText.toLowerCase());
      });
      setOptions(searchedAgents);
    } else {
      setOptions(options);
    }
  }

  return (
    <div>
      <div className={styles.apmHeadingContainer}>
        <div className={styles.imgContainer}>
          <img src={backIcon} onClick={() => dispatch(setBottomSheetComponent())} />
        </div>
        <div className={styles.apmHeadingWrapper}>
          <span className={styles.apmHeading}>Choose agent</span>
        </div>
      </div>

      <div className={styles.apmBodyContainer}>
        <div>
          <SimpleInput variant="with-icon"
            handleChange={filterAgents}
            placeholder="Search agent..."
            size="full-width"
            IconComponent={Search}
            className={styles.apmInput}
          />
        </div>
        {!!options.length && options.map((option) => (
          <div className={styles.apmItems}>
            <img src={getRandomAvatar()} className={styles.image} />
            <div className={styles.apmItem}>
              <span className={styles.apmItemName}>{option.name}</span>
              <span className={styles.apmItemEmail}>{option.email}</span>
            </div>
            <div>
              <Radio
                checked={selectedValue === `${option.id}`}
                onChange={handleChange}
                value={option.id}
                name="radio-buttons"
                inputProps={{ 'aria-label': `${option.id}` }}
                color="info"
                checkedIcon={<CheckCircleIcon />}
              />
            </div>
          </div>
        ))}
        <div>
          <ButtonGroup
            primaryCta="Save"
            secondaryCta="Cancel"
            primaryFn={handleSubmit}
            secondaryFn={() => dispatch(setBottomSheetComponent())}
          />
        </div>
      </div>
    </div>
  )
}