import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import axios from 'axios';

axios.interceptors.withCredentials = true;
axios.interceptors.request.use(
  (req) => {
    return req;
  },
  (err) => {
    return Promise.reject(err);
  }
);

const axiosBaseQuery =
  ({ baseUrl } = { baseUrl: '' }) =>
    async ({ url, method, data }) => {
      try {
        const result = await axios({
          url: baseUrl + url, method, data,
          withCredentials: true
        })
        return { data: result.data }
      } catch (axiosError) {
        let err = axiosError
        return {
          error: { status: err.response?.status, data: err.response?.data },
        }
      }
    }

export const authenticationAPI = createApi({
  reducerPath: 'user',
  baseQuery: axiosBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_HOST}/`,
  }),
  tagTypes: ['user'],
  endpoints: (build) => ({
    currentUser: build.query({
      query: () => ({ url: 'api/users/currentUser', method: 'GET' }),
      providesTags: ['user']
    }),
    googleSigin: build.mutation({
      query: (body) => ({
        url: `api/users/signin/Oauth`,
        method: 'POST',
        data: body,
      }),
      invalidatesTags: ['user'],
    }),
    googleSignup: build.mutation({
      query: (body) => ({
        url: `api/users/signup/Oauth`,
        method: 'POST',
        data: body,
      }),
      invalidatesTags: ['user'],
    }),
    signin: build.mutation({
      query: (body) => ({
        url: `api/users/signin`,
        method: 'POST',
        data: body,
      }),
      invalidatesTags: ['user'],
    }),
    signout: build.mutation({
      query: (body) => ({
        url: `api/users/signout`,
        method: 'POST',
        data: body,
      }),
      invalidatesTags: ['user'],
    }),
    signup: build.mutation({
      query: (body) => ({
        url: `api/users/signup`,
        method: 'POST',
        data: body,
      }),
      invalidatesTags: ['user'],
    }),
    getBusinessHours: build.query({
      query: () => ({ url: 'api/company/businessCalendar', method: 'GET' }),
      providesTags: ['user']
    }),
    updateBusinessHours: build.mutation({
      query: (body) => ({
        url: `api/company/businessCalendar`,
        method: 'POST',
        data: body,
      }),
      invalidatesTags: ['user'],
    })
  }),
});

export const {
  useCurrentUserQuery,
  useGoogleSiginMutation,
  useGoogleSignupMutation,
  useSigninMutation,
  useSignoutMutation,
  useSignupMutation,
  useGetBusinessHoursQuery,
  useUpdateBusinessHoursMutation
} = authenticationAPI