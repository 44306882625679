import React from 'react';
import PropTypes from 'prop-types';

import { describeArc } from '../../utils/arc';

const IGNORE = 0.000001;

export function Ring({width = 16, height = 16, percent = 75, color = 'black'}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d={describeArc(8, 8, 7, 0, (percent - IGNORE) * 3.6)}
        strokeLinecap="round"
        stroke={color}
        strokeWidth={2}
        fill="none"
      />
    </svg>
  );
}

Ring.PropTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  percent: PropTypes.number,
  color: PropTypes.string
};

/**
 * Resources:
 * http://tutorials.jenkov.com/svg/path-element.html
 * https://www.svgviewer.dev/
 */
