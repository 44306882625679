import { Card as MuiCard, Chip as MuiChip } from '@mui/material';
import { styled } from '@mui/system';
import cx from 'classnames';
import { CircularProgressBar } from '@tt/fe-common';

import styles from './styles.module.scss';

const Card = styled(MuiCard)`
  padding: 1rem;
  margin-bottom: 16px;
`;

const Chip = styled(MuiChip)(
  ({ theme }) => {
    return `
      border-radius: ${theme.shape.borderRadius}px;
    `;
  }
);


export default function KfCard({ data, head, config, floater, src, chipLabel, progress,actions }) {
  let Floater;
  if (src) {
    src && (Floater = () => <div className={styles.avatar} style={{
      backgroundImage: `url(${src})`
    }}></div>);
  } else if (chipLabel) {
    chipLabel && (Floater = () => <Chip label={chipLabel} color="success" />);
  } else if (progress) {
    (Floater = () => <CircularProgressBar
      value={+progress} />)
  }

  return (
    <Card>
      <div className={cx(styles.head, 'display-flex')}>
        <h4 className="pr-16">{head}</h4>
        {Floater && <Floater />}
        {actions && <div>{actions(data)}</div>}
      </div>
      {
        Object.keys(config).map(key => (
          <div key={key} className={cx(styles.item, 'mb-12')}>
            <img role="presentation" alt="" src={config[key]} />
            <span>{data[key]}</span>
          </div>
        ))
      }
    </Card>
  );
}