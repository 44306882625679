import React, { useEffect, useState, useRef } from 'react';
import cx from 'classnames';
import styles from './style.module.scss';
import closeIcon from 'assets/icons/light/close.svg';

export default function PageFilter() {
  return (
    <div className={styles.page}>
      <Filter />
    </div>
  );
}

function Filter() {
  /** final output value to be exported */
  const [filterValues, setFilterValues] = useState([[]]);
  /** initially dropdown should be shown for LHS */
  const [currentElement, setCurrentElement] = useState(0);
  /** Dropdown should be populated with values for LSH */
  const [dropdownList, setDropdownList] = useState(meta.fields);
  const [dropdownActive, setDropdownActive] = useState(false);
  const containerRef = useRef(null);

  /**
   *  enum of 0 / 1 / 2 which represents
   *  LHS, Op or RHS respectively. Based on the value the
   *  corresponding dropdown is populated
   */
  function changeCurrentDropdown(elementValue) {
    setFilterValues(prev => {
      const last = prev[prev.length - 1].slice(0);
      last.push(elementValue);
      const next = prev.slice(0, prev.length - 1)
      next.push(last);
      (currentElement === 2) && next.push([]);
      return next;
    });
    setCurrentElement(prev => (prev + 1) % 3);
  }

  function removeFilter(filterIndex) {
    setFilterValues(prev => {
      const filters = [...prev];
      filters.splice(filterIndex, 1);
      return filters;
    });
  }

  /**
   * The dropdown container is same for LHS, Operator and RHS.
   * So, this function takes care or populating and repopulating
   * the values inside them based on which is the active element
   */
  useEffect(() => {
    const filter = filterValues[filterValues.length - 1][0];
    switch (currentElement) {
      /** LHS */
      case 0:
        setDropdownList(meta.fields);
        break;
      /** Op */
      case 1:
        let ops;
        if (~filter) {
          console.log('stab', meta.fields[
            meta.fields.findIndex(field => field.id === filter)
          ].operators);
          ops = meta.fields[
            meta.fields.findIndex(field => field.id === filter)
          ].operators;
        }
        console.log('case 1', filter, ops);
        setDropdownList(ops || []);
        break;
      /** RHS */
      case 2:
        let rhs;
        if (~filter) {
          rhs = data.findIndex(datum => datum.field === filter);
        }
        setDropdownList(data[rhs].values.map(value => ({
          label: value
        })) || []);
        break;
    }
  }, [currentElement]);

  /**
   * To close the dropdown when mouse is clicked outside
   * the component.
   * @param {*} e - MouseClickEvent
   */
  function outsideClickHandler(e) {
    if (!e.target.closest('.' + styles.container)) {
      document.removeEventListener('click', outsideClickHandler);
      setDropdownActive(false);
    }
  }

  /** This attaches mouse click event handler to detect clicks
   * outside the component, to close the dropdown.
   */
  useEffect(() => {
    if (dropdownActive) {
      document.addEventListener('click', outsideClickHandler);
    }
  }, [dropdownActive]);

  /**
   * This function is to to conditionally display between the 
   * placeholder and the value tag elements. This also helps in
   * code split up.
   */
  function EmptyValue() {
    return (
      filterValues &&
      filterValues[0] &&
      filterValues[0][0]
    ) ? (
      filterValues.map((filter, i) => (
        <div key={i} className={styles.main}>
          {
            filter[0] && <div className={cx(styles.tag, styles['tag-lhs'])}>
              {meta.fields[
                meta.fields.findIndex(field => field.id === filter[0])
              ].label}
            </div>
          }
          {
            filter[0] && filter[1] && <div className={cx(styles.tag, styles['tag-op'])}>
              {meta.fields[
                meta.fields.findIndex(field => field.id === filter[0])
              ].operators[
                meta.fields.findIndex(op => op.id === filter[1])
              ].label}
            </div>
          }
          {
            filter[0] && filter[2] && <div className={cx(styles.tag, styles['tag-rhs'])}>
              <span>{filter[2]}</span>
              <img aria-label="dismiss filter" src={closeIcon}
                onClick={() => removeFilter(i)} className={styles.close} />
            </div>
          }
        </div>
      ))
    ) : (
      <p className={styles.empty}>Search by order, customer…</p>
    )
  }

  return (
    <>
      <div>{JSON.stringify(filterValues)}</div>
      <div className={styles.container} ref={containerRef}>
        <div className={styles.inner}>
          <EmptyValue />
          <div className={cx(styles['dropdown-container'], {
            [styles.active]: dropdownActive
          })} onClick={() => {
            setDropdownActive(prev => {
              /** Close the dropdown only when click is received
               * after RHS */
              if (prev) {
                return (currentElement !== 2);
              }
              return true;
            });
          }}>
            <ul className={styles['dropdown-list']}>
              {
                dropdownList.map(({ label, id }, i) => (
                  <li key={id || i}
                    onClick={(e) => changeCurrentDropdown(id || label)}>{label}</li>
                ))
              }
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

/**
 * MOCK DATA begins here
 */

const operatorGroup = {
  string: [
    { label: 'equals', id: 1 },
    { label: 'not equals', id: 2 }
  ],
  relational: [
    { label: 'greater than', id: 3 },
    { label: 'greater than or equals', id: 4 },
    { label: 'less than', id: 5 },
    { label: 'less than or equals', id: 6 }
  ],
  logical: []
};

const meta = {
  fields: [
    {
      label: 'Author',
      id: 1,
      operators: operatorGroup.string
    },
    {
      label: 'Type',
      id: 2,
      operators: operatorGroup.string
    },
    {
      label: 'Assignee',
      id: 3,
      operators: operatorGroup.string
    },
    {
      label: 'Item',
      id: 4,
      operators: [
        ...operatorGroup.string,
        ...operatorGroup.relational
      ]
    }
  ],
};

const data = [
  {
    field: 1,
    values: [
      '@maximum',
      '@minimum',
      '@chica',
      '@dinosaur'
    ]
  },
  {
    field: 2,
    values: [
      'text',
      'date',
      'timestamp',
      'blob',
      'integer',
      'float',
      'double'
    ]
  },
  {
    field: 3,
    values: [
      'Spiderman',
      'Ironman',
      'Hulk',
      'Wolverine',
      'Batman',
      'Wonder Woman'
    ]
  },
  {
    field: 4,
    values: [
      2342,
      3564,
      8334,
      9923,
      9011,
      2210
    ]
  }
]
