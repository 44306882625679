import React from 'react';
import PropTypes from 'prop-types';
import { Button as MuiButton, InputLabel as MuiInputLabel } from '@mui/material';
import {KeyboardArrowDown} from '@mui/icons-material';
import cx from 'classnames';
import { styled } from '@mui/system';
import styles from './styles.module.css';

export const InputHybrid = function (props) {
  const { children, color = 'light', variant = 'contained', label, className, ...remainingProps } = props;

  /* TODO: confirm font color since this does not comply
   * with theming palette */
  const StyledButton = styled(MuiButton)(({ theme }) => `
    box-sizing: border-box;
    width: 100%;
    justify-content: space-between;
    box-shadow: none;
    color: #3c3c43;
  `);

  const InputLabel = styled(MuiInputLabel)`
    margin-bottom: 4px;
  `;


  return (
    <div className={cx(styles['full-width'], className)}>
      {label && <InputLabel>{label}</InputLabel>}
      <StyledButton
        variant={variant}
        color={color}
        {...remainingProps}
      >
        {children}
      </StyledButton>
    </div>
  );
}

InputHybrid.muiName = MuiButton.muiName;

InputHybrid.propTypes = {
  variant: PropTypes.oneOf(['contained'])
};

InputHybrid.defaultProps = {
  variant: 'contained',
  endIcon: <KeyboardArrowDown />
};
