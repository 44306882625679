import React from 'react';
import { FormGroup, FormControlLabel, Checkbox } from '@mui/material';

export const CheckBox = function CheckBox({ value = false, label = '' }) {
  const [ipValue, setValue] = React.useState(value);
  return (
    <FormGroup>
      <FormControlLabel control={
        <Checkbox
          color="info"
          onClick={() => setValue(curr => !curr)}
          checked={ipValue} />
      } label={label} />
    </FormGroup>
  )
}
