import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const onboardingStyles = makeStyles((theme) => ({
    container: {
      width: 800,
      background: theme.palette.primary.main,
      backdropFilter: 'blur(152px)',
    },
    formControl: {
      margin: '10px 0',
    },
    cancelBtn: {
      marginRight: -50,
    },
    stepper: {
      width: 180,
    },
    formContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: 350,
    },
    header: {
      fontSize: 20,
      fontWeight: 700,
    },
    subText: {
      fontSize: 14,
      margin: '10px 0',
    },
    error: {
      fontWeight: 400,
      marginTop: 10,
      marginLeft: 10,
    },
    skipContainer: {
      marginTop: 20,
      marginLeft: 25,
    },
    skip: {
      cursor: 'pointer',
      color: theme.palette.info.main,
    },
    headingContent: {
      display: 'flex',
      justifyContent: 'space-between',
      cursor: 'pointer',
    },
    mobileHeading: {
      fontSize: 'x-large',
      fontWeight: 500,
      textAlign: 'center',
      marginTop: 10,
    },
    mobileStepper: {
      width: 180,
      marginTop: 10,
      margin: 'auto',
    },
  })
);
