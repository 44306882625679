import React, { useRef, useEffect, useState } from 'react';
import makeAnimated from 'react-select/animated';
import cx from 'classnames';
import { useCreateEmployeeMutation, useUpdateEmployeeMutation, EmployeeAPI } from '../../../store/reducer/services/employee';
import { useDispatch } from 'react-redux';
import styles from './agent.module.scss';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import ErrorContainer from 'utils/error';
import { SimpleInput, ButtonGroup, MultiSelect } from '@tt/fe-common';
import { useGetProductsQuery } from '../../../store/reducer/services/product';
import { setToaster, setBottomSheetComponent } from 'store/reducer/layout';
import backIcon from 'assets/icons/light/back.svg';
import CloseIcon from '@mui/icons-material/Close';


export default function AddAgent({ setOpenNewAgentDrawer, isEdit, id, name, email, skillset, setIsEdit }) {
  const formRef = useRef();
  const { data: productsList } = useGetProductsQuery();
  const [createEmployee, { isLoading }] = useCreateEmployeeMutation();
  const [updateEmployee] = useUpdateEmployeeMutation();
  const [processOptions, setProcessOptions] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const dispatch = useDispatch();
  const AgentSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Name is Required'),
    email: Yup.string()
      .email('Must be a valid email')
      .max(255)
      .required('Email is required'),
  });
  useEffect(() => {
    if (isEdit) {
      setSelectedSkills([...skillset]);
    }
  }, [isEdit])
  const animatedComponents = makeAnimated();

  var initialValues = {
    name: isEdit ? name : '',
    email: isEdit ? email : '',
  };

  useEffect(() => {
    if (productsList) {
      productsList.forEach(({ productName, processList }) => {
        processList.forEach(({ processName, id }) => {
          const option = {
            productName,
            processName,
            processId: id
          }
          setProcessOptions((prev) => [...prev, option]);
        });
      });
    }
  }, [productsList]);

  const handleCancel = () => {
    setIsEdit(false);
    formRef.current.resetForm();
    setOpenNewAgentDrawer(false);
  }
  const saveAgent = async (values) => {
    try {
      const payload = { role: 'EMPLOYEE', ...values, skills: selectedSkills };
      await createEmployee(payload).unwrap();

      formRef.current.resetForm();
      setIsEdit(false);
      setOpenNewAgentDrawer(false);
    } catch (e) {
      console.log('ERRORS', e);
    }
  };

  const updateAgent = async (values) => {
    try {
      const payload = { role: 'EMPLOYEE', ...values, skills: selectedSkills };
      const employee = await updateEmployee({ id, payload }).unwrap();
      formRef.current.resetForm();
      dispatch(EmployeeAPI.util.updateQueryData('getEmployeesList', undefined, (draftEmployees) => {
        return draftEmployees.map(emp => {
          if (emp.id === id) {
            return employee;
          } else {
            return emp;
          }
        });
      }));
      dispatch(setToaster('success'));
      setOpenNewAgentDrawer(false);
    } catch (e) {
      console.log('ERRORS', e);
      dispatch(setToaster('error'));
    }
  };

  const skillSelected = (e) => {
    setSelectedSkills([...e]);
  }

  const selectedValueDecorator = (v, option) => {
    return <span>{option.processName}</span>
  }

  const optionValueDecorator = (v, option) => {
    return <span>{option.processName}</span>
  }

  const removeSkill = (e) => {
    setSelectedSkills([...e]);
  }

  return (
    <div className={`${styles.agentContainer} `}>
      <div className={styles.agentHeadingContainer}>
        <div className={`${styles.imgContainer}`}>
          <img className="cursor-pointer" src={backIcon} onClick={() => {
            dispatch(setBottomSheetComponent());
            handleCancel();
          }} />
        </div>
        <div className={styles.agentHeadingWrapper}>
          <span className={styles.agentHeading}>Agent Info</span>
        </div>
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={AgentSchema}
        innerRef={formRef}
        enableReinitialize
        onSubmit={isEdit ? updateAgent : saveAgent}
      >
        {({ errors, touched, values, handleChange, handleBlur, setValues }) => (
          <Form className={cx(styles.form)}>
            <SimpleInput
              className="mt-32"
              label="Agent name"
              size="full-width"
              name="name"
              value={values.name}
              onBlur={handleBlur}
              handleChange={handleChange('name')}
            />
            {errors.name && touched.name ? (
              <ErrorContainer>{errors.name}</ErrorContainer>
            ) : null}
            <SimpleInput
              className="mt-32"
              label="Email"
              size="full-width"
              name="email"
              disabled={isEdit}
              value={values.email}
              onBlur={handleBlur}
              handleChange={handleChange('email')}
            />
            {errors.email && touched.email ? (
              <ErrorContainer>{errors.email}</ErrorContainer>
            ) : null}
            <div className={cx(styles.skills, "mt-32")}>
              <h6></h6>
              <span className={styles.description}>Choose which process, in which product this agent works on.</span>
              <div className={styles.skillsContainer}>
                <MultiSelect options={processOptions}
                  customCloseIcon={<CloseIcon style={{
                    fontSize: 14,
                    marginLeft: 5,
                    color: "black"
                  }} />}
                  className={`${styles.customMultiSelect}`}
                  onSelect={(e) => skillSelected(e)}
                  emptyRecordMsg="No Values found"
                  selectedValues={selectedSkills}
                  optionValueDecorator={optionValueDecorator}
                  selectedValueDecorator={selectedValueDecorator}
                  onRemove={removeSkill}
                  groupBy="productName" displayValue="processId" />
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <div className={styles.ButtonGroupPosition}>
        <ButtonGroup
          primaryCta="Save"
          secondaryCta="Cancel"
          primaryFn={() => formRef.current.handleSubmit()}
          secondaryFn={handleCancel}
        />
      </div>
    </div>
  );
}
