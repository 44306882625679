import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import cx from 'classnames';
import { List, ListItemButton, ListItemIcon, ListItemText, Collapse } from '@mui/material';
import { ExpandLess, ExpandMore, StarBorder } from '@mui/icons-material';
import { makeStyles, createStyles } from '@mui/styles';
import { useSignoutMutation } from '../../store/reducer/services/signin';

import styles from './menu.module.scss';
import dashboardIcon from 'assets/icons/light/dashboard.svg';
import messageIcon from 'assets/icons/light/message.svg';
import checkActiveIcon from 'assets/icons/light/check-active.svg';
import settingsIcon from 'assets/icons/light/settings.svg';
import shoppingCartIcon from 'assets/icons/light/shopping-cart.svg';
import userPlusIcon from 'assets/icons/light/user-plus.svg';
import userIcon from 'assets/icons/light/user.svg';

const useStyles = makeStyles(() =>
  createStyles({
    list: {
      '& .MuiListItemIcon-root': {
        minWidth: 'unset',
        marginRight: 16
      },
      '& a': {
        display: 'flex',
        textDecoration: 'none'
      }
    }
  }))

export default function Menu({ setUserInfo }) {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const [removeUser, { isLoading }] = useSignoutMutation();
  const history = useNavigate();

  const handleClick = () => {
    setOpen(!open);
  };

  const handleSignOut = async () => {
    await removeUser();
    setUserInfo(null);
    history('/signin');
  }

  return (
    <div className={cx(styles.left)}>

      <div>
        <h4 className={cx(styles.heading, "ml-16 mb-8")}>Menu</h4>
      </div>

      <div className={styles.listContainer}>
        <List className={classes.list}>

          <ListItemButton>
            <Link to="/">
              <ListItemIcon>
                <img src={dashboardIcon} />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </Link>
          </ListItemButton>

          <ListItemButton>
            <Link to="/orders">
              <ListItemIcon>
                <img src={messageIcon} />
              </ListItemIcon>
              <ListItemText primary="Orders" />
            </Link>
          </ListItemButton>

          <ListItemButton>
            <Link to="/products">
              <ListItemIcon>
                <img src={shoppingCartIcon} />
              </ListItemIcon>
              <ListItemText primary="Products" />
            </Link>
          </ListItemButton>

          <ListItemButton>
            <Link to="/process">
              <ListItemIcon>
                <img src={checkActiveIcon} />
              </ListItemIcon>
              <ListItemText primary="Process" />
            </Link>
          </ListItemButton>

          <ListItemButton>
            <Link to="/products">
              <ListItemIcon>
                <img src={userIcon} />
              </ListItemIcon>
              <ListItemText primary="Contacts" />
            </Link>
          </ListItemButton>

          <ListItemButton>
            <Link to="/">
              <ListItemIcon>
                <img src={userPlusIcon} />
              </ListItemIcon>
              <ListItemText primary="Vendors" />
            </Link>
          </ListItemButton>

          <ListItemButton>
            <Link to="/settings">
              <ListItemIcon>
                <img src={settingsIcon} />
              </ListItemIcon>
              <ListItemText primary="Settings" />
            </Link>
          </ListItemButton>

          {/*
        * This is collapsible menu item for future extension.
        *
        <ListItemButton onClick={handleClick}>
          <ListItemIcon>
            <img src={settingsIcon} />
          </ListItemIcon>
          <ListItemText primary="Additional Settings" />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton sx={{ pl: 4 }}>
              <Link to="/">
                <ListItemIcon>
                  <StarBorder />
                </ListItemIcon>
                <ListItemText primary="Starred" />
              </Link>
            </ListItemButton>
          </List>
        </Collapse> */}
        </List>
      </div>

      <div className={styles.logoutContainer} onClick={() => handleSignOut()}>
        <img src="/icons/svgs/logout.svg"></img>
        <span>Logout</span>
      </div>

    </div>
  );
}
