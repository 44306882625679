import React from 'react';
import { Chip as MuiChip } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';

const useStyles = makeStyles((theme) =>
  createStyles({
    chip: {
      //borderRadius: theme.shape.borderRadius
    }
  }));

export const Chip = function Chip({ label = '' }) {
  const classes = useStyles();
  return (
    <MuiChip label={label} color="success" className={classes.chip} />
  );
}
