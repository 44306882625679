import { AddImage } from './AddImage';
import { Button, ButtonGroup } from '@tt/fe-common';
import styles from './onboarding.module.scss';
import { useMediaQuery } from '@mui/material';
import { useState } from 'react';
import { useUpdateCompanyDetailsMutation } from '../../store/reducer/services/company';

export default function LogoUploadComponent({ onboardingDetails, setOnboardingDetails, setOpenModal, company }) {
    const isMobileView = useMediaQuery('(max-width:576px)');
    const [mainCroppedImage, setMainCroppedImage] = useState(undefined);
    const [addCompanyDetails] = useUpdateCompanyDetailsMutation();
    const submitOnBoarding = async () => {
        try {
            if (mainCroppedImage) {
                onboardingDetails.image = mainCroppedImage;
            }
            const timeZone = onboardingDetails.timeZone.value;
            delete onboardingDetails.timeZone;
            const payload = { ...company }
            payload.companyDetails = { ...onboardingDetails };
            payload.timeZone = timeZone;
            payload.email = onboardingDetails.companyEmail;
            await addCompanyDetails(payload).unwrap();
            setOpenModal(false);
        } catch (e) {
            console.log(e);
        }
    }
    return (
        <div className={!isMobileView ? styles.mainContainer : styles.mobileContainer}>
            <div className={isMobileView && styles.logoContainer}>
                <AddImage mainCroppedImage={mainCroppedImage} setMainCroppedImage={setMainCroppedImage}>Add your logo</AddImage>
            </div>
            {
                !isMobileView ?
                    <Button color='info' className='mt-32' onClick={submitOnBoarding}>
                        Get Started
                    </Button> :
                    <div className={styles.btnGrpLogo}>
                        <ButtonGroup
                            primaryCta="Get Started"
                            secondaryCta="Skip"
                            primaryFn={() => console.log('Started')}
                            secondaryFn={() => console.log('Skip')} />
                    </div>
            }
        </div>
    )
}