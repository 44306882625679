import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setHeader } from 'store/reducer/layout';
import { SimpleInput } from '@tt/fe-common';
import { Search } from '@mui/icons-material';
import { useGetProductsQuery, useDeleteProductMutation, productAPI } from 'store/reducer/services/product';
import RemoveIcon from 'assets/icons/remove.svg';
import EditIcons from 'assets/icons/edit.svg';
import styles from './new.module.scss';
import { useNavigate } from 'react-router-dom';
import { setProductToBeEdited } from 'store/reducer/createProduct';
import DeleteDialog from "../../components/modal/DeleteDialog";
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import Chip from '@mui/material/Chip';
import { Dialog, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import Button from '@mui/material/Button';
import NoProduct from '../../assets/icons/no-products.svg';


export default function Products() {
  const dispatch = useDispatch();
  const deleteMessage = "Deleting this product will delete all the existing orders and the delete's skills assigned to your agent. Do you still wanted to delete ?"
  const { data, isFetching } = useGetProductsQuery();
  const [productsList, setProductsList] = useState([]);
  const navigate = useNavigate();
  const [deleteProduct] = useDeleteProductMutation();
  const { refetch } = productAPI.endpoints.getProducts.useQuerySubscription();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [productToBeDeleted, setProductToBeDeleted] = useState(null);
  const [productSelected, setProductSelected] = useState(null);
  const [openProductModal, setProductModal] = useState(false);

  const handleClose = (value) => {
    setOpenDeleteDialog(false);
  };

  dispatch(setHeader("Products"));
  const openDialog = (product) => {
    setProductToBeDeleted(product);
    setOpenDeleteDialog(true);
  }

  const removeProduct = async () => {
    const id = productToBeDeleted.id;
    await deleteProduct({ id }).unwrap();
    setProductToBeDeleted(null);
    setOpenDeleteDialog(false);
  }

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    setProductsList(data);
  }, [data]);

  const filterProducts = (searchText) => {
    if (searchText) {
      const searchedProducts = data.filter((product) => {
        return product.productName
          .toLowerCase()
          .includes(searchText.toLowerCase());
      });
      setProductsList(searchedProducts);
    } else {
      setProductsList(data);
    }
  }

  const history = useNavigate();

  const editProduct = (product) => {
    dispatch(setProductToBeEdited(product));
    navigate(`/product/edit`);
  }
  const renderProcessList = (product) => {
    const { processList } = product;
    const items = [];
    for (let i = 0; i < 3; i++) {
      if (processList[i]) {
        items.push(<span>{` ${processList[i]?.processName}${i < 2 ? "," : ""} `}</span>)
      }
    }
    if (processList.length > 3) {
      items.push(<span className={`cursor-pointer ${styles.link}`}
        onClick={() => { setProductSelected(product); setProductModal(true) }}
      >... + {processList.length - 3} more</span>)
    }
    return items;
  }

  const searchProducts = () => {
    <SimpleInput size="full-width"
          handleChange={filterProducts}
          variant="with-icon"
          placeholder="Search Product..."
          IconComponent={Search}
        />
  }

  const renderProducts = () => {
    if (productsList?.length) {
      return productsList.map((product) => {
        const { productName, id } = product
        return (
          <div className="flex my-16">
            <img src={RemoveIcon} alt="remove" className="mr-16 cursor-pointer" onClick={() => openDialog(product)} />
            <div className={`${styles.productsContainer} py-16 w-full px-16 flex justify-between`}>
              <div>
                <div>
                  <span className="font-semibold text-base">{productName}</span>
                </div>
                <div className="mt-8 text-sm">
                  <span className="font-normal">Process List: </span>
                  <span className="font-medium">{renderProcessList(product)}</span>
                </div>
              </div>
              <div className={`${styles.editContainer} cursor-pointer flex 
              justify-around items-center font-medium text-sm`} onClick={() => editProduct(product)}>
                <img src={EditIcons} />
                <span>Edit</span>
              </div>
            </div>
          </div>
        )
      })
    }
    else{
      return(
        <div className={styles.noRecords}>
            <img src={NoProduct} />
            <h1>No Products Found</h1>
            <p>Looks like you haven't created any Product yet...</p>
            <Button
                variant="outlined"
                color="info"
                onClick={() => {
                  history('/product/new');
                }}
              >Create Product</Button>
          </div>
      )
      }
  }
  return (
    <>
      <div className="xs:w-full sm:w-9/12 md:w-2/4 lg:w-1/3 xl:w-1/3 2xl:w-1/3">
        {searchProducts()}
      </div>
      <div className={styles.listContainer}>{renderProducts()}</div>
      <DeleteDialog
        open={openDeleteDialog}
        onClose={handleClose}
        action={removeProduct}
        message={deleteMessage}
        title={`Are you sure you wanted to delete ${productToBeDeleted?.productName}`}
      />
      {productSelected && <Dialog onClose={() => setProductModal(false)} open={openProductModal} >
        <DialogTitle id="skills-dialog-title" className={`flex items-center justify-between ${styles.modalHeader}`}>
          <span className="text-base ">{productSelected.productName}</span>
          <HighlightOffRoundedIcon className="cursor-pointer text-gray-400" onClick={() => setProductModal(false)} />
        </DialogTitle>
        <DialogContent className={styles.modalWidth}>
          <DialogContentText>
            {productSelected.processList.map((process) => <div className="flex items-center justify-between mb-16 pl-8">
              <span>{process.processName}</span>
              <Chip label={`${process.timeSpan} ${process.unit}`} color="info" variant="outlined" />
            </div>)}
          </DialogContentText>
        </DialogContent>
      </Dialog>}
    </>
  )
}

Products.fab = true;
