import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import cx from 'classnames';
import { useGetProductsQuery } from '../../../store/reducer/services/product';
import { Search } from '@mui/icons-material';
import { SimpleInput, ButtonGroup } from '@tt/fe-common';
import { getRandomImage } from '../../../utils/randomImages';
import styles from '../new.module.scss';
import CheckIcon from '@mui/icons-material/Check';
import { setBottomSheetComponent, } from 'store/reducer/layout';
import { useDispatch, useSelector } from 'react-redux';
import { addProduct } from 'store/reducer/productCart';
import backIcon from 'assets/icons/light/back.svg';
import NoProduct from '../../../assets/icons/no-products-small.svg';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';


export default function ProductList({ addedProducts, setAddedProducts, toggleOpenProducts }) {
  const { data, isLoading } = useGetProductsQuery();
  const [productList, setProductList] = useState([]);
  const dispatch = useDispatch();
  const [selectedProducts, setSelectedProducts] = useState([]);
  const productsInCart = useSelector(state => state.cart.products);

  useEffect(() => {
    if (!productList?.length)
      setProductList(data);
  }, [data, productList]);

  useEffect(() => {
    if (productsInCart.length)
      setSelectedProducts([...productsInCart]);
  }, [productsInCart])

  const filterProduct = (searchText) => {
    if (searchText) {
      const searchedProducts = productList.filter((product) => {
        return product.productName
          .toLowerCase()
          .includes(searchText.toLowerCase());
      });
      setProductList(searchedProducts);
    } else {
      setProductList(data);
    }
  }

  const history = useNavigate();

  const isProductSelected = (productId) => {
    return selectedProducts.some(addedProduct => addedProduct.id === productId);
  }
  const addProductToCart = (product) => {
    if (!isProductSelected(product.id))
      setSelectedProducts((prev) => [...prev, product])
    else {
      const filteredProducts = selectedProducts.filter(({ id }) => id !== product.id);
      setSelectedProducts([...filteredProducts]);
    }
  }

  const renderProducts = () => {
    return productList?.map(product => {
      const { imageURL } = product;

      return (
        <>
          <div key={product.id} className={styles['product-card']} onClick={() => addProductToCart(product)}>
            <img src={`${imageURL ? imageURL : getRandomImage()}`} />
            {isProductSelected(product.id) && <div className={styles.overlay}><CheckIcon /></div>}
            <div className={styles['product-item']}>
              <Typography variant="h5" fontWeight="bold">{product.productName}</Typography>
              <div className={styles['product-desc']}>
                {product.processList.map(({ processName }, index) => {
                  const seperator = index < product.processList.length - 1 ? styles.seperator : '';
                  return (
                    <Typography key={index} variant="subtext" component="span" className={cx(styles['product-bullet'], styles['whitespace-nowrap'])}>{processName}</Typography>
                  );
                })}
              </div>
            </div>
          </div>
        </>
      )
    })
  }
  const getButtonLabel = () => {
    return selectedProducts?.length > 0 ? `Add ${selectedProducts.length} Products` : `Add Product`
  }

  const productlistFooter = () => {
    return(
      <ButtonGroup primaryCta={getButtonLabel()}
        secondaryCta="Cancel"
        primaryFn={() => {
          dispatch(addProduct(selectedProducts))
          dispatch(setBottomSheetComponent())
          toggleOpenProducts(false);
        }}
        secondaryFn={cancel} />
    )
  }

  const emptyStateProduct = () => {
    return (
      <div className={styles.noProduct}>
          <div><img src={NoProduct} /></div>
          <h1>No Products Found</h1>
          <p>Looks like you haven't creared any product yet...</p>
          <Button
                variant="outlined"
                color="info"
                onClick={() => {
                  history('/product/new');
                }}
              >Create Product</Button>
        </div>
    )
  }

  const cancel = () => {
    setAddedProducts([]);
    toggleOpenProducts(false);
    dispatch(setBottomSheetComponent());
  }
  return (
    <div className={styles.drawerContainer}>
      <div className={styles.listHeader}>
        <img src={backIcon} onClick={() => {
          toggleOpenProducts(false);
          dispatch(setBottomSheetComponent());
        }} className="cursor-pointer" />
        <Typography variant="h4"><strong>Add Product</strong></Typography>
      </div>
      <SimpleInput size="full-width"
        placeholder="Search product..."
        handleChange={filterProduct}
        variant="with-icon"
        IconComponent={Search}
      />
      <div className={styles.productList}>
        {data?.length ? renderProducts() :!isLoading && emptyStateProduct()}
      </div>
      {data?.length ? productlistFooter() : <></>}
    </div>
  )
}