import {  Typography } from '@mui/material';

const NewCustomer = () => {
  return (
    <section>
      <Typography variant="h4">Welcome to Customer new page</Typography>
    </section>
  );
};

export default NewCustomer;