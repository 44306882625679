import React from "react";
import { useSelector } from 'react-redux';
import styles from './layout.module.scss';
const GlobalLoader = () => {
  const count = useSelector(state => state.counter.value);

  return (
    <div>
      {
        count > 0 ? <div className={styles.loaderContainer}>
          {/* <div className={styles.spinnerWrapper}>
            <div className={styles.spinner}>
              <div className={styles.skFoldingCube}>
                <div className={`${styles.skCube1} ${styles.skCube}`}></div>
                <div className={`${styles.skCube2} ${styles.skCube}`}></div>
                <div className={`${styles.skCube4} ${styles.skCube}`}></div>
                <div className={`${styles.skCube3} ${styles.skCube}`}></div>
              </div>
            </div>
             </div> */}
          <div className={styles.loader}></div>

        </div> : null
      }
    </div >
  );
}

export default GlobalLoader