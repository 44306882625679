import React, { useEffect, useState } from 'react';
import styles from './orders.module.scss';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Button } from '@tt/fe-common';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import { useGetEmployeesMutation } from 'store/reducer/services/employee';
import { useAssignTaskMutation } from '../../store/reducer/services/order';
import { EmployeeAPI } from 'store/reducer/services/employee';
//import { assignTask } from 'store/reducer/orders';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import Avatar from 'components/commom/Avatar';

export default function AsignProcessToEmployee({ productName, orderId, rows, setRows, processName }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [getEmployees, { isLoading }] = useGetEmployeesMutation();
  const [assignTask] = useAssignTaskMutation();
  const [employees, setEmployees] = useState([]);
  const [options, setOptions] = useState([]);
  const dispatch = useDispatch();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = async ({ id: employeeId, name }) => {
    if (employeeId) {
      const payload = {
        employeeId,
        avatar: '',
        name,
      }
      const order = await assignTask({ orderId, employeeId, payload });
      const updatedRows = rows.map((row) => {
        if (row.id === orderId) {
          const { productName,
            estimatedEndTime,
            quantity,
            percentageCompleted,
            customerName,
            email,
            address,
            id,
            processList,
            assignedTo } = order.data
          return {
            productName,
            estimatedEndTime: moment
              .utc(estimatedEndTime)
              .local()
              .format('ddd - MMM DD, YYYY'),
            customerName,
            email,
            quantity,
            percentageCompleted,
            address,
            id,
            processList,
            assignedTo
          };
        } else {
          return row
        }
      });
      setRows([...updatedRows]);
    }
    dispatch(EmployeeAPI.util.invalidateTags(['Employee']));
    setAnchorEl(null);
  };

  useEffect(async () => {
    if (open) {
      const searchPayload = {
        query: {
          field: "skills",
          operator: 'EQUAL',
          criteria: {
            productName: productName,
            processName: processName
          }
        }
      }
      const { docs, totalPages } = await getEmployees({ ...searchPayload }).unwrap();
      setEmployees([...docs]);
    }
  }, [open]);

  useEffect(() => {
    if (employees.length) {
      const options = employees.map(({ name, id }) => {
        return {
          name, id
        }
      });
      setOptions(options);
    }
  }, [employees])
  return (
    <div className={styles.assignContainer}>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls="long-menu"
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        variant="kfAssign"
        onClose={handleClose}
      >
        {!!options.length ? options.map((option) => (
          <MenuItem key={option.name} selected={option === 'Pyxis'}
            variant="kfAssign"
            onClick={() => handleClose(option)}>
            <div className={styles.employee}>
              <Avatar name={option.name} size={24}></Avatar>
              <span className="font-medium text-xs">{option.name}</span>
            </div>
          </MenuItem>
        )) :
          <div className="flex flex-col p-8 items-center">
            <span className={styles.heading}>Don't have agents with this skill</span>
            <a href="/settings/agents" className={styles.button}>
              <Button size='exact' color="light" variant="contained">Add Agents</Button>
            </a>
          </div>
        }
      </Menu>
    </div>
  )
}
