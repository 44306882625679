import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../axiosUtility';

export const EmployeeAPI = createApi({
  reducerPath: 'employee',
  baseQuery: axiosBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_HOST}/api/employee`,
  }),
  tagTypes: ['Employee'],
  endpoints: (build) => ({
    createEmployee: build.mutation({
      query: (body) => ({
        url: `/create`,
        method: 'POST',
        data: body,
      }),
      invalidatesTags: ['Employee'],
    }),
    getEmployees: build.mutation({
      query: (body) => ({
        url: `/list`,
        method: 'POST',
        data: body,
      }),
      providesTags: ['Employee'],
    }),
    getEmployeesList: build.query({
      query: () => ({ url: '/list', method: 'GET' }),
      providesTags: ['Employee'],
    }),
    deleteEmployee: build.mutation({
      query: (id) => ({
        url: `/${id}`,
        method: 'DELETE',
      }),
    }),
    updateEmployee: build.mutation({
      query: ({ id, ...body }) => ({
        url: `/${id}`,
        method: 'PUT',
        data: body['payload'],
      }),
    }),
    requestPasswordReset: build.mutation({
      query: (body) => ({
        url: `/request-password-reset`,
        method: 'POST',
        data: body,
      }),
    }),
    resetPassword: build.mutation({
      query: (body) => ({
        url: `/reset-password`,
        method: 'POST',
        data: body,
      }),
    }),
    updatePassowrd: build.mutation({
      query: (body) => ({
        url: `/update-password`,
        method: 'PUT',
        data: body,
      }),
    }),
    getUnAssignedAgents: build.query({
      query: () => ({ url: '/unassigned-agents', method: 'GET' }),
      providesTags: ['Employee'],
    }),
  }),
});

export const {
  useCreateEmployeeMutation,
  useGetEmployeesMutation,
  useGetEmployeesListQuery,
  useDeleteEmployeeMutation,
  useUpdateEmployeeMutation,
  useRequestPasswordResetMutation,
  useResetPasswordMutation,
  useGetUnAssignedAgentsQuery,
  useUpdatePassowrdMutation
} = EmployeeAPI;
