import React from 'react';
import cx from 'classnames'
import { Link } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';

import styles from './left-menu.module.scss';

import logoIcon from 'assets/icons/light/logo.svg';
import dashboardIcon from 'assets/icons/light/dashboard.svg';
import dashboardActiveIcon from 'assets/icons/light/dashboard-active.svg';
import cartIcon from 'assets/icons/light/shopping-cart.svg';
import cartActiveIcon from 'assets/icons/light/shopping-cart-active.svg';
import processIcon from 'assets/icons/light/process.svg';
import processActiveIcon from 'assets/icons/light/process-active.svg';
import settingsIcon from 'assets/icons/light/settings.png';
import settingsActiveIcon from 'assets/icons/light/settings-active.png';
import productsIcon from 'assets/icons/light/user.svg';
import productsActiveIcon from 'assets/icons/light/products-active.svg';
import customersIcon from 'assets/icons/light/dashboard.svg';
import customersActiveIcon from 'assets/icons/light/dashboard-active.svg';
import { useSelector } from 'react-redux';

const menuConfig = [
  {
    link: '/',
    src: dashboardIcon,
    srcActive: dashboardActiveIcon,
    title: 'Dashboard'
  },
  {
    link: '/orders',
    src: cartIcon,
    srcActive: cartActiveIcon,
    title: 'Orders'
  },
  {
    link: '/process',
    src: processIcon,
    srcActive: processActiveIcon,
    title: 'Processes'
  },
  {
    link: '/products',
    src: productsIcon,
    srcActive: productsActiveIcon,
    title: 'Products'
  },
  {
    link: '/customers',
    src: customersIcon,
    srcActive: customersActiveIcon,
    title: 'Customers'
  },
  {
    link: '/settings',
    src: settingsIcon,
    srcActive: settingsActiveIcon,
    title: 'Settings'
  },
]

export default function LeftMenu() {
  const { isSubscriptionOver } = useSelector((state) => state?.subscription);
  return (
    <nav className={cx(styles.container, 'py-24', isSubscriptionOver ? 'pointer-events-none py-12' : 'py-12')}>
      <img alt="dashboard" className={cx(styles.logo)} src={logoIcon} />
      {
        menuConfig.map((menu, i) => (
          <Link key={i} to={menu.link} className={'py-12'} >
            <Tooltip title={menu.title} placement="right-start">
              <img alt={menu.title} className={styles.icon} onMouseOver={(e) => {
                e.target.src = menu.srcActive;
              }} onMouseLeave={(e) => {
                e.target.src = menu.src;
              }} src={menu.src} />
            </Tooltip>
          </Link>
        ))
      }
    </nav>
  );
}