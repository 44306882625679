const PRICING = Object.freeze({
  monthly: {
    freeAgents: 4,
    freeSMS: 20,
    freeEMAIL: 200,
    costPerSMS: 0.5,
    costPerEMAIL: 0.25,
    costPerAgent: 250
  },
  annual: {
    freeAgents: 4,
    freeSMS: 100,
    freeEMAIL: 1000,
    costPerSMS: 0.5,
    costPerEMAIL: 0.25,
    costPerAgent: 225
  }
});

export const BILLING_CYCLE = ['monthly', 'annual']

export const getPricingDetails = (plan) => {
  return PRICING[plan];
}