import React, { useState, useRef, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import cx from 'classnames';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useMediaQuery, Tooltip, Drawer } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SimpleInput, Button, ButtonGroup } from '@tt/fe-common';
import { AddImage } from './AddImage'
import { useAddProductMutation, useUpdateProductMutation } from 'store/reducer/services/product';
import { setHeader } from 'store/reducer/layout';
import ProcessList from './process/processList';
import styles from './new.module.scss';
import ErrorContainer from 'utils/error';
import { setBottomSheetComponent } from 'store/reducer/layout';
import { useLocation } from 'react-router-dom';
import { arrangeProcesses, removeProcess as removeProcessFromStore, resetProcessToEdited } from 'store/reducer/createProduct';

import moveImg from 'assets/icons/move.svg';
import DeleteIcon from 'assets/icons/light/delete.svg';
import arrowDownImg from 'assets/icons/arrow-down.svg';

const ProductSchema = Yup.object().shape({
  productName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  // processList: Yup.array()
  //   .min(1, 'At least one process should be involved')
});

const createProductStyles = makeStyles((theme) => ({
  processListCard: {
    display: 'flex',
    backgroundColor: theme.palette.primary.main,
    minWidth: 250,
    margin: '10px 10px',
    justifyContent: 'space-between',
    padding: '20px 10px',
    borderRadius: '4px',
    cursor: 'pointer',
    minWidth: 'calc(100% - 100px)',
    //TODO Bring this color in theme 
    border: `solid 1px #DEDEDE`,
    borderRadius: 10,
  },
  unitSecondary: {
    fontSize: 12,
    padding: 5,
    background: theme.palette.primary.main,
    borderRadius: 4,
    border: `solid 1px ${theme.palette.info.main}`,
  },

  metaInfoContainer: {
    maxHeight: 250,
    overflow: 'auto',
  },

  metaInfo: {
    backgroundColor: theme.palette.primary.main,
    padding: 10,
    width: '96%',
    borderRadius: 7,
    marginTop: 20,
    marginLeft: 7,
  },
  processCardWithIcons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  cursorPointer: {
    marginLeft: 15,
    cursor: 'pointer',
  },
}));

const style = {
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 536,
  height: 640,
  backgroundColor: '#FFFFFF',
  boxShadow: 24,
  borderRadius: 5,
  padding: 24,
  overflow: 'auto',
};


export default function NewProduct() {
  const formRef = useRef();
  const { processInvolved, productToBeEdited } = useSelector(state => state?.newProduct);
  const dispatch = useDispatch();
  const location = useLocation();
  const isProductEdit = location.pathname.includes("/product/edit");
  const [addProduct, { isLoading }] = useAddProductMutation();
  const [updateProduct] = useUpdateProductMutation();
  const [mainCroppedImage, setMainCroppedImage] = useState(undefined);
  const [processError, setProcessError] = useState(false);
  const initialValues = () => {
    return { productName: productToBeEdited ? productToBeEdited.productName : '', processList: processInvolved, }
  }
  const [processList, toggleProcessList] = useState(false);
  const isMobile = useMediaQuery('(max-width:576px)');
  const [draggedIndex, setDraggedIndex] = useState(null);
  const navigate = useNavigate();
  const classes = createProductStyles();
  const onDrop = (event) => {
    try {
      const target = +event.target.dataset.index;
      const source = +draggedIndex;
      let temp = [...processInvolved];
      const draggedProcess = temp[source];
      temp.splice(source, 1);
      temp.splice(target, 0, draggedProcess);
      dispatch(arrangeProcesses([...temp]))
    }
    catch (e) {
      console.log(e)
    }
  };
  const onDragOver = (e) => {
    let event = e;
    event.stopPropagation();
    event.preventDefault();
  }

  const setDraggedElement = (event) => {
    setDraggedIndex(event.target.dataset.index);
  };
  const removeProcess = (id) => {
    dispatch(removeProcessFromStore(id))
  };
  const diplayProcessInvolved = (processInvolved) => {
    if (processInvolved?.length) {
      return processInvolved.map((process, index) => {
        return (
          <div key={index}>
            <div className={classes.processCardWithIcons}>
              <Tooltip title="Rearrange" placement="top" data-index={index}
                draggable={true}
                onDrop={onDrop}
                onDragOver={onDragOver}
                onDragStart={setDraggedElement}
                key={index}>
                <img src={moveImg} className="cursor-pointer"></img>
              </Tooltip>
              <div
                className={classes.processListCard}
              >
                <span>{process.processName}</span>
                <span className={classes.unitSecondary}>
                  {process.timeSpan} {process.unit}
                </span>
              </div>
              <img
                src={DeleteIcon}
                className={classes.cursorPointer}
                onClick={() => removeProcess(process.id)}
              ></img>
            </div>
            {processInvolved?.length - 1 > index && <img
              src={arrowDownImg}
              style={{ display: 'flex', margin: 'auto' }}
            />}
          </div>
        );
      });
    } else {
      return <></>
    }
  }
  const createProduct = async (values) => {
    if (values.processList.length < 1) {
      setProcessError(true);
    } else {
      try {
        if (isProductEdit) {
          const { id, companyId } = productToBeEdited;
          const payload = { ...values, companyId };
          await updateProduct({ id, payload }).unwrap();
          dispatch(resetProcessToEdited());
          navigate(-1);
        } else {
          values.image = mainCroppedImage;
          await addProduct(values).unwrap();
          dispatch(resetProcessToEdited());
          navigate("/products");
        }
        dispatch(arrangeProcesses([]));
        formRef.current.resetForm();

      } catch (e) {
        console.log("ERROR IN ADDING PRODUCT", e);
      }
    }
  }
  useEffect(() => {
    dispatch(setHeader('Product'));
  }, []);

  useEffect(() => {
    formRef.current.setFieldValue('processList', processInvolved, true);
    if (processInvolved.length) {
      setProcessError(false);
    }
  }, [processInvolved]);

  NewProduct.BottomDrawerChild = [
    () => (
      <div className="mb-16">
        <ProcessList
          toggleProcessList={toggleProcessList}
        />
      </div>
    )
  ];

  return (
    <section className={cx(styles.cont, 'flex flex-col ')} >
      <AddImage mainCroppedImage={mainCroppedImage} setMainCroppedImage={setMainCroppedImage}>Add image {location.pathname}</AddImage>
      <Formik className={styles.form}
        initialValues={initialValues()}
        onSubmit={createProduct}
        innerRef={formRef}
        validateOnMount={false}
        validationSchema={ProductSchema}>
        {({ errors, touched, values, handleChange, handleBlur, isSubmitting }) => (
          <Form className={styles.form}>
            <SimpleInput className="mt-16"
              label="Product name"
              size="full-width"
              name="productName"
              value={values.productName}
              onBlur={handleBlur}
              handleChange={handleChange('productName')}
            />
            {errors.productName && touched.productName ? (
              <ErrorContainer>{errors.productName}</ErrorContainer>
            ) : null}
            <div className="mb-16 mt-16">
              <Button
                label="Processes"
                variant="outlined"
                color="info"
                onClick={() => {
                  if (isMobile) {
                    dispatch(setBottomSheetComponent(0))
                  } else {
                    toggleProcessList(!processList)
                  }
                }}
              // toggleProcessList(!processList)
              >Add Process</Button>
            </div>
            {processError ? (
              <ErrorContainer>Atleast one process needs to selected</ErrorContainer>
            ) : null}
          </Form>
        )}

      </Formik>
      <div className={styles.processListContainer}>{diplayProcessInvolved(processInvolved)}</div>
      <ButtonGroup classes={styles['btn-grp']} primaryCta={isProductEdit ? "Save Product" : "Create Product"} secondaryCta="Cancel"
        primaryFn={() => formRef.current.handleSubmit()}
        secondaryFn={() => {
          formRef.current.resetForm();
          dispatch(resetProcessToEdited());
          navigate(-1);
        }} />
      <Drawer
        anchor="right"
        open={processList}
        onClose={() => {
          toggleProcessList(!processList)
        }}
      >
        <ProcessList
          toggleProcessList={toggleProcessList} />
      </Drawer>
    </section>
  )
}
