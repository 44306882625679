import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../axiosUtility';

export const companyAPI = createApi({
  reducerPath: 'company',
  baseQuery: axiosBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_HOST}/api/company`,
  }),
  tagTypes: ['Company'],
  endpoints: (build) => ({
    getCompanyDetails: build.query({
      query: () => ({ url: '/details', method: 'GET' }),
      providesTags: ['Company'],
    }),
    updateCompanyDetails: build.mutation({
      query: (body) => ({
        url: `/details`,
        method: 'PUT',
        data: body,
      }),
      invalidatesTags: ['Company'],

    }),
    updateCompanyName: build.mutation({
      query: (body) => ({
        url: `/companyName`,
        method: 'PUT',
        data: body,
      }),
      invalidatesTags: ['Company'],

    }),
  }),
});

export const {
  useGetCompanyDetailsQuery,
  useUpdateCompanyDetailsMutation,
  useUpdateCompanyNameMutation,
} = companyAPI;
