import React, { useEffect, useState, useRef } from 'react';
import backIcon from 'assets/icons/light/back.svg';
import { Select, MenuItem, ListItemText, InputLabel, FormControl } from '@mui/material';
import { BILLING_CYCLE } from '../../../config/billing';
import { styled } from '@mui/system';
import styles from './billing.module.scss';
import * as Yup from 'yup';
import { SimpleInput, ButtonGroup, Select as KSelect } from '@tt/fe-common';
import { Formik, Form } from 'formik';
import { getPricingDetails } from '../../../config/billing';
import { setBottomSheetComponent } from 'store/reducer/layout';
import { useDispatch } from 'react-redux';
import { useCreateOrderMutation } from '../../../store/reducer/services/payment';
import useRazorpay from "react-razorpay";
import { useMediaQuery } from '@mui/material';
const StyledSelect = styled(Select)`
  background-color: #eeeeee;
  overflow: auto;
  height: auto;
  min-width: 100%;
`;
export default function Purchase({ isAddOn, setOpenPurchaseModal, userDetails }) {
  const formRef = useRef();
  const forMobile = useMediaQuery('(max-width:576px)');
  const dispatch = useDispatch();
  const Razorpay = useRazorpay();
  const [billingCycle, setBillingCycle] = useState('annual');
  const [planDetails, setPlanDetails] = useState(getPricingDetails(billingCycle));
  const [createOrder] = useCreateOrderMutation();
  const initialValues = {
    agents: isAddOn ? 0 : 1,
    sms: 1,
    email: 1
  };
  const PaymentSchema = Yup.object().shape({
    agents: Yup.number()
      .min(0, 'Too Short!')
      .max(500, 'Too Long!'),
    sms: Yup.number()
      .min(0, 'Too Short!')
      .max(50000, 'Too Long!'),
    email: Yup.number()
      .min(0, 'Too Short!')
      .max(500000, 'Too Long!')
  });
  const [agentCost, setAgentCost] = useState(planDetails.costPerAgent * initialValues.agents * (billingCycle === 'annual' ? 12 : 1));
  const [smsCost, setSmsCost] = useState(planDetails.costPerSMS * initialValues.sms);
  const [emailCost, setEmailCost] = useState(planDetails.costPerEMAIL * initialValues.email);
  const [total, setTotal] = useState(agentCost + smsCost + emailCost);

  useEffect(() => {
    setTotal(agentCost + smsCost + emailCost);
  }, [agentCost, smsCost, emailCost]);


  useEffect(() => {
    setPlanDetails(getPricingDetails(billingCycle));
    setAgentCost(planDetails.costPerAgent * formRef.current.values.agents * (billingCycle === 'annual' ? 12 : 1))
  }, [billingCycle]);


  const billingTypes = () => {
    return BILLING_CYCLE.map((type) => {
      return <MenuItem key={type} value={type}>
        <ListItemText primary={type} />
      </MenuItem>
    })
  }
  const makePayment = async () => {
    const order = await createOrder({ amount: total * 100 }).unwrap();
    const { agents, sms, email } = formRef.current.values;
    const options = {
      key: "rzp_test_QdxtjsjAKApTuQ",
      amount: order.amount,
      currency: "INR",
      name: "Knowtify",
      description: "Test Transaction",
      order_id: order.id,
      handler: (res) => {
        console.log(res);
      },
      prefill: {
        name: "Goutham Palani",
        email: "gouthamproject17@gmail.com",
        contact: "+918939600806",
      },
      notes: {
        isAddOn,
        agents,
        sms,
        email,
        companyId: userDetails.currentUser.companyId,
        billingCycle: isAddOn ? null : billingCycle
      },
      theme: {
        color: "#5045CC",
      },
    };
    const rzpay = new Razorpay(options);
    rzpay.open();
  }

  const proceedPayment = async () => {
    await makePayment();
  }

  const billingSelectValues = BILLING_CYCLE.map((op) => {
    return {
      value: op,
      text: op
    }
  })

  return <>
    <div className={`mb-32 md:w-96 ${!forMobile ? 'p-16' : ''}`}>
      <div className="flex items-center justify-between mb-16">
        <img className="block md:hidden" src={backIcon} onClick={() => { dispatch(setBottomSheetComponent()); setOpenPurchaseModal(false) }} />
        <span className="text-xl font-bold">{isAddOn ? 'Buy Add on' : 'Buy Plan'} </span>
        <span className={`hidden md:block ${styles.Close}`} onClick={() => { setOpenPurchaseModal(false) }} >X</span>
      </div>
      {!isAddOn && (<div className={`${styles.ModalPlanDesc} flex flex-col`}>
        <div className="flex flex-col">
          <span className='opacity-90 mb-16'>Current Plan</span>
          <span className="font-bold mr-3 text-2xl">Starter</span>
        </div>
        <div className="mt-16">
          <InputLabel>
            Billing Cycle
          </InputLabel>
          <FormControl fullWidth type="kfDropdown">
            <KSelect value={billingCycle}
              data={billingSelectValues}
              handleChange={(e) => setBillingCycle(e.target.value)}
            ></KSelect>
          </FormControl>
        </div>

      </div>)}
      <div>
        <div className={!forMobile ? (isAddOn ? styles.FormHeightAddOn : styles.FormHeight) : (isAddOn ? styles.FormMobileHeightAddOn : styles.FormMobileHeight)}>
          <Formik initialValues={initialValues}
            innerRef={formRef}
            validationSchema={PaymentSchema}
            onSubmit={proceedPayment}
            className={styles.FormHeight}
            enableReinitialize>
            {({ errors, touched, values, handleBlur, setFieldValue }) =>
              <Form>
                {!isAddOn && <div className="mt-32 flex items-center">
                  {<div className="flex items-center w-9/12 md:w-4/5">
                    <div>
                      <p>Number of agents </p>
                      <SimpleInput
                        name="agents"
                        size="fixed-value"
                        type="number"
                        value={values.agents}
                        onBlur={handleBlur}
                        className={styles.inputField}
                        handleChange={(value) => {
                          setFieldValue('agents', value, true)
                          setAgentCost(planDetails.costPerAgent * value * (billingCycle === 'annual' ? 12 : 1))
                        }}
                      />
                    </div>
                    <span className={`${styles.Multipler} `}>X</span>
                    <div className="flex flex-col ml-16">
                      <p>
                        Cost
                      </p>
                      <span className={styles.Cost}>₹ {planDetails.costPerAgent}</span>
                    </div>
                  </div>}
                  <div className={`flex flex-col ${!forMobile ? 'mr-16' : ''}`}>
                    <p>
                      Total
                    </p>
                    <span className={styles.Cost}>₹ {agentCost}</span>
                  </div>
                </div>}
                <div className="mt-32 flex items-center">
                  <div className="flex items-center w-9/12 md:w-4/5">
                    <div>
                      <p>Number of sms </p>
                      <SimpleInput
                        name="sms"
                        size="fixed-value"
                        type="number"
                        value={values.sms}
                        onBlur={handleBlur}
                        className={styles.inputField}
                        handleChange={(value) => {
                          setFieldValue('sms', value, true)
                          setSmsCost(planDetails.costPerSMS * value)
                        }}
                      />
                    </div>
                    <span className={`${styles.Multipler} `}>X</span>
                    <div className="flex flex-col ml-16">
                      <p>
                        Cost
                      </p>
                      <span className={styles.Cost}>₹ {planDetails.costPerSMS}</span>
                    </div>
                  </div>
                  <div className={`flex flex-col ${!forMobile ? 'mr-16' : ''}`}>
                    <p>
                      Total
                    </p>
                    <span className={styles.Cost}>₹ {smsCost}</span>
                  </div>
                </div>
                <div className="mt-32 flex items-center">
                  <div className="flex items-center w-9/12 md:w-4/5">
                    <div>
                      <p>Number of Email </p>
                      <SimpleInput
                        name="email"
                        size="fixed-value"
                        type="number"
                        value={values.email}
                        onBlur={handleBlur}
                        className={styles.inputField}
                        handleChange={(value) => {
                          setFieldValue('email', value, true)
                          setEmailCost(planDetails.costPerEMAIL * value)
                        }}
                      />
                    </div>
                    <span className={`${styles.Multipler} `}>X</span>
                    <div className="flex flex-col ml-16">
                      <p>
                        Cost
                      </p>
                      <span className={styles.Cost}>₹ {planDetails.costPerEMAIL}</span>
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <p>
                      Total
                    </p>
                    <span className={styles.Cost}>₹ {emailCost}</span>
                  </div>
                </div>
                <div className={styles.TotalContainer}>
                  <span>Total Price</span>
                  <strong>₹ {total}</strong>
                </div>
              </Form>

            }
          </Formik>
        </div>
        <div className={`${forMobile ? '' : styles.PullDown}`}>
          <ButtonGroup
            primaryCta="Proceed Payment"
            secondaryCta="Cancel"
            primaryFn={() => {
              formRef.current.handleSubmit();
            }}
            secondaryFn={() => {
              setOpenPurchaseModal(false);
              dispatch(setBottomSheetComponent());
            }} />
        </div>
      </div>
    </div>
  </>
}