import { default as counter } from './counter';
import { default as layout } from './layout';
import ordersReducer from './orders';
import subscription from './subscription';
import { authenticationAPI } from './services/signin';
import { processAPI } from './services/process';
import { productAPI } from './services/product';
import { customerAPI } from './services/customer';
import { orderAPI } from './services/order';
import { EmployeeAPI } from './services/employee';
import { MessageAPI } from './services/message';
import { companyAPI } from './services/company';
import { paymentAPI } from './services/payment';
import { default as newProduct } from './createProduct';
import { default as cart } from './productCart';

export default {
  counter,
  layout,
  orders: ordersReducer,
  cart,
  subscription,
  newProduct,
  [authenticationAPI.reducerPath]: authenticationAPI.reducer,
  [productAPI.reducerPath]: productAPI.reducer,
  [processAPI.reducerPath]: processAPI.reducer,
  [customerAPI.reducerPath]: customerAPI.reducer,
  [orderAPI.reducerPath]: orderAPI.reducer,
  [EmployeeAPI.reducerPath]: EmployeeAPI.reducer,
  [MessageAPI.reducerPath]: MessageAPI.reducer,
  [companyAPI.reducerPath]: companyAPI.reducer,
  [paymentAPI.reducerPath]: paymentAPI.reducer
};
