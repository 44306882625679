import React, { useState, useEffect, useRef } from 'react';
import styles from './message.module.scss';
import * as Yup from 'yup';
import { getMessageTags } from '../../../utils/messageTags';
import { MentionsInput, Mention } from 'react-mentions';
import { useUpdateMessageMutation } from '../../../store/reducer/services/message';
import { Formik, Form, Field, FieldArray, FormGroup } from 'formik';
import {
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';

import { Button, ButtonGroup, TextArea, IOSSwitch } from '@tt/fe-common';

export default function MessageAction({ currentMessage, setOpenMessageDrawer }) {
  const { category, event, message, id, companyId } = currentMessage;
  const { content, header, footer } = message;
  const formRef = useRef();
  const [updateMessage] = useUpdateMessageMutation();
  const tags = getMessageTags(category, event)
  const initialValues = {
    content,
    header,
    footer
  }
  const MessageSchema = Yup.object().shape({
    content: Yup.string()
      .min(2, 'Too Short!')
      .max(500, 'Too Long!')
      .required('Required'),
  });
  const handleCancel = () => {
    formRef.current.resetForm();
    setOpenMessageDrawer(false);
  }

  const updateMessageContent = async (values) => {
    try {
      const payload = {
        category,
        event,
        companyId,
        message: values
      }
      await updateMessage({ id, payload }).unwrap()
    } catch (e) {
      console.log('ERRORS', e);
    }
  }
  return <div className={styles.messageContainer}>
    <h3>Custom Message</h3>
    <Formik
      initialValues={initialValues}
      validationSchema={MessageSchema}
      innerRef={formRef}
      enableReinitialize
      onSubmit={updateMessageContent}
    >{({ error, touched, values, handleChange, handleBlur }) => (
      <Form className="px-24">
        <div className="display-flex align-items-center justify-content-space-between">
          <Typography variant="h5" className="mt-32 ">
            Header
          </Typography>
          <IOSSwitch sx={{ m: 1 }} defaultChecked />
        </div>
        <TextArea size="full-width"
          handleChange={handleChange('header')}
          width="full"
          rows={3}
          value={values.header}
          onBlur={handleBlur}
          className="mt-16"
        />
        {/* <TextArea size="full-width"
          handleChange={handleChange('content')}
          width="full"
          rows={7}
          value={values.content || ' '}
          onBlur={handleBlur}
          className="mt-16"
          ref={el => this.input = el} x
        /> */}
        <div className="mt-32">
          <Typography variant="h5" className="mt-16">
            Content
          </Typography>
          <MentionsInput value={values.content} onChange={handleChange('content')}
            style={{
              width: '100 %',
              border: 0,
              background: '#F5F6F7',
              borderRadius: 4,
              resize: 'none',
              padding: 12,
              boxSizing: 'border- box'
            }}
            className="mention-input mt-16"
          >
            <Mention
              trigger="@"
              data={tags}
              markup='{__id__}'
              rows={7}

            />
          </MentionsInput>
        </div>
        <div className="display-flex align-items-center justify-content-space-between mt-32">
          <Typography variant="h5" className="mt-32 ">
            Footer
          </Typography>
          <IOSSwitch sx={{ m: 1 }} defaultChecked />
        </div>
        <TextArea size="full-width"
          handleChange={handleChange('footer')}
          width="full"
          rows={3}
          value={values.footer}
          onBlur={handleBlur}
          className="mt-16"
        />

        <div style={{ width: 500 }}>
          <ButtonGroup
            // primaryCta={isEdit ? "Update": "Save"}
            primaryCta="Save"
            secondaryCta="Cancel"
            width="500px"
            primaryFn={() => formRef.current.handleSubmit()}
            secondaryFn={handleCancel}
          />
        </div>
      </Form>
    )}</Formik>
  </div>
}