import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../axiosUtility';

export const orderAPI = createApi({
  reducerPath: 'order',
  baseQuery: axiosBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_HOST}/api/order`,
  }),
  tagTypes: ['Order'],
  endpoints: (build) => ({
    createOrder: build.mutation({
      query: (body) => ({
        url: '/create',
        method: 'POST',
        data: body,
      }),
    }),
    getOrders: build.mutation({
      query: (body) => ({
        url: '/company',
        method: 'POST',
        data: body,
      }),
      providesTags: ['Order'],
    }),
    snoozeOrder: build.mutation({
      query: (data) => ({
        url: '/snooze',
        method: 'PUT',
        data,
      }),
    }),
    assignTask: build.mutation({
      query: ({ orderId, employeeId, payload }) => {
        return {
          url: `/${orderId}/assign/${employeeId}`,
          method: 'PATCH',
          data: payload,
        };
      },
      invalidatesTags: ['Order'],
    }),
    updateProcessStatus: build.mutation({
      query: ({ id, payload }) => ({
        url: `/process/${id}`,
        method: 'PUT',
        data: payload,
      }),
      invalidatesTags: ['Order'],
    }),
    getOrder: build.query({
      query: ({ id }) => ({
        url: `/${id}`,
        method: 'GET',
      }),
      providesTags: ['Order'],
    }),
    getPublicOrder: build.mutation({
      query: ({ payload }) => ({
        url: `/status`,
        method: "POST",
        data: payload
      })
    })
  }),
});

export const {
  useCreateOrderMutation,
  useGetOrdersMutation,
  useAssignTaskMutation,
  useUpdateProcessStatusMutation,
  useSnoozeOrderMutation,
  useLazyGetOrderQuery,
  useGetPublicOrderMutation
} = orderAPI;
