/**
 * This slice controls all the layout based states which are spread across 
 * multiple components,
 * like showing or hiding menu in mobile view
 */

import { createSlice } from '@reduxjs/toolkit';

export const LEFT_ACTION = {
  BACK: 'BACK',
  HAMBURGER: 'HAMBURGER'
};

const initialState = {
  menu: false,
  head: 'Dashboard',
  breadcrumb: {},
  leftAction: LEFT_ACTION.HAMBURGER,
  bottomSheet: -1,
  isFab: false,
  toaster: '',
  toasterMessage: '',
  notificationCount: 0,
  notifications: {},
  profileDropDownState: false,
};

export const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    toggleMenu: state => { state.menu = !state.menu; },
    setBottomSheetComponent: (state, action) => {
      let rootElement = document.getElementById('root');
      if (typeof action.payload === 'number') {
        rootElement.style.filter = "blur(2px)";
        state.bottomSheet = action.payload;
        state.isFab = false;
      } else if (typeof action.payload === 'object') {
        rootElement.style.filter = "blur(2px)";
        const { isFab, index } = action.payload;
        state.bottomSheet = index;
        state.isFab = isFab;
      }
      if (!action.payload && typeof action.payload !== 'number') {
        rootElement.style.filter = "blur(0px)";
        state.bottomSheet = -1;
        state.isFab = false;
      }
    },
    setHeader: (state, action) => {
      state.head = action.payload
    },
    setToaster: (state, action) => {
      state.toaster = action.payload
    },
    setToasterMessage: (state, action) => {
      state.toasterMessage = action.payload
    },
    setLeftAction: (state, action) => {
      LEFT_ACTION[action.payload] &&
        (state.leftAction = action.payload);
    },
    setBreadcrumbs: (state, action) => {
      state.breadcrumb = (
        action.payload.crumbs &&
        action.payload.current
      )
        ? { ...action.payload }
        : {}
    },
    setNotifications: (state, action) => {
      action.payload.id &&
        action.payload.pendingProcessDetails &&
        (state.notifications = {
          ...state.notifications,
          [action.payload.id]: action.payload.pendingProcessDetails
        });
    },
    setNotificationCount: (state, action) => {
      typeof action.payload === 'number' &&
        (state.notificationCount = action.payload);
    },
    setProfileDropDownState: (state, action) => {
      state.profileDropDownState = action.payload;
    }
  }
});

export const {
  toggleMenu,
  setHeader,
  setToaster,
  setLeftAction,
  setToasterMessage,
  setBottomSheetComponent,
  setBreadcrumbs,
  setNotifications,
  setNotificationCount,
  setProfileDropDownState
} = layoutSlice.actions;
export default layoutSlice.reducer;
