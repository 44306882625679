import React from 'react';
import LetteredAvatar from 'react-lettered-avatar';

export default function Avatar({ name, size, radius }) {
  const arrayWithColors = [
    '#2ecc71',
    '#3498db',
    '#8e44ad',
    '#e67e22',
    '#e74c3c',
    '#1abc9c',
    '#2c3e50'
  ];
  return (
    <LetteredAvatar
      name={name}
      color="#fff"
      size={size || 100}
      radius={radius || 20}
      backgroundColors={arrayWithColors}
    />
  )
}