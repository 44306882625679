import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup } from '@tt/fe-common';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import TextField from '@mui/material/TextField';
import styles from './styles.module.scss';
import moment from 'moment';
import DeleteIcon from 'assets/icons/delete.svg';
import ErrorContainer from 'utils/error';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 2,
  p: 8,
};

export default function Holidays({ holidayList, holidays, setHolidays }) {
  const [toggleModal, setToggleModal] = useState(false);
  const handleOpen = () => setToggleModal(true);
  const [holiday, setHoliday] = useState(moment());
  const [holidayName, setHolidayName] = useState("");
  const [holidayError, setHolidayError] = useState(false);

  const handleChange = (newValue) => {
    setHoliday(newValue);
  };
  const addHoliday = () => {
    if (holidayName.length < 4) {
      setHolidayError(true);
    } else {
      const payload = { event: holidayName, date: holiday };
      setHolidays((prev) => [...prev, payload]);
      setToggleModal(false);
    }
  }
  useEffect(() => {
    if (holidayName.length > 3) {
      setHolidayError(false);
    }
  }, [holidayName]);

  const deleteHoliday = (index) => {
    setHolidays(existingItems => {
      existingItems.splice(index, 1);
      return [
        ...existingItems,
      ]
    });
  }

  const displayHolidays = () => {
    return holidays.map((holiday, index) => {
      return <div className={`flex py-16 px-8 ${styles.holidayContainer}`} key={index}>
        <span className="w-52">{moment(holiday.date).format("MMM DD")}</span>
        <span className="w-52">{holiday.event}</span>
        <div className={`${styles.imageContainer}`}> <img src={DeleteIcon} onClick={() => deleteHoliday(index)} /></div>
      </div>
    })
  }
  return <div>
    <div className="flex justify-between items-center mt-8">
      <p className="font-semibold text-sm">Orders' end time calculation will increased based on Holidays added</p>
      <Button
        size='exact' color="info"
        onClick={handleOpen}
      >Add Holidays</Button>
    </div>
    {holidays.length > 0 && (<div>
      <div className={`mt-8 py-16 px-8 ${styles.holidayHeader} flex`}>
        <span className="w-52">Dates</span>
        <span className="w-52">Name</span>

      </div>
      {displayHolidays()}
    </div>)}
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={toggleModal}
      toggleModal={toggleModal}
      onClose={() => setToggleModal(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={toggleModal}>
        <Box sx={style}>
          <span className="font-bold text-medium">
            Add Holidays
          </span>
          <div className="mt-16">
            <p className="mb-4 font-medium text-sm">Date </p>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DesktopDatePicker
                inputFormat="MM/DD/YYYY"
                value={holiday}
                onChange={handleChange}
                renderInput={(params) => <TextField className="p-8" {...params} />}
              />

            </LocalizationProvider>
          </div>
          <div className="mt-16 w-full">
            <p className="mb-4 font-medium text-sm">Name</p>
            <TextField placeholder="Holiday Name" className="p-8 w-full" onChange={(e) => setHolidayName(e.target.value)} />
            {holidayError && <ErrorContainer>You Must specify a valid name for holiday</ErrorContainer>}
          </div>
          <ButtonGroup
            classes="mt-32"
            primaryCta="Add"
            secondaryCta="Cancel"
            primaryFn={addHoliday}
            secondaryFn={() => { setToggleModal(false) }} />
        </Box>
      </Fade>
    </Modal>
  </div>
}