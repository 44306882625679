import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../axiosUtility';

export const paymentAPI = createApi({
  reducerPath: 'payment',
  baseQuery: axiosBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_HOST}/api/subscription`,
  }),
  tagTypes: ['Payment'],
  endpoints: (build) => ({
    createOrder: build.mutation({
      query: (body) => ({
        url: '/razorpay/order/create',
        method: 'POST',
        data: body,
      }),
    }),
  })
});

export const {
  useCreateOrderMutation
} = paymentAPI;