import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../axiosUtility';

export const MessageAPI = createApi({
  reducerPath: 'message',
  baseQuery: axiosBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_HOST}/api/message`,
  }),
  tagTypes: ['Message'],
  endpoints: (build) => ({
    createMessage: build.mutation({
      query: (body) => ({
        url: `/create`,
        method: 'POST',
        data: body,
      }),
      invalidatesTags: ['Message'],
    }),
    getMessages: build.query({
      query: (body) => ({
        url: `/list`,
        method: 'GET',
        data: body,
      }),
      providesTags: ['Message'],
    }),
    updateMessage: build.mutation({
      query: ({ id, ...body }) => ({
        url: `/${id}`,
        method: 'PUT',
        data: body['payload'],
      }),
      invalidatesTags: ['Message'],
    })
  })
});

export const {
  useCreateMessageMutation,
  useUpdateMessageMutation,
  useGetMessagesQuery
} = MessageAPI