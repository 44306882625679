import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IOSSwitch, ButtonGroup } from '@tt/fe-common';
import { getSubscriptionDetails, updateNotifications } from 'store/reducer/subscription';
import { setHeader } from 'store/reducer/layout';
import styles from './notification.module.scss';
import EmailIcon from 'assets/icons/mail.svg'
import SMSIcon from 'assets/icons/sms.svg';
import { useNavigate } from 'react-router-dom';

export default function ManageNotifications() {
  const dispatch = useDispatch();
  const { notifications } = useSelector((state) => state?.subscription);
  const [smsEnabled, setSmsEnabled] = useState(false);
  const [emailEnabled, setEmailEnabled] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getSubscriptionDetails());
    dispatch(setHeader('Notifications'));
  }, []);
  useEffect(() => {
    if (notifications) {
      setSmsEnabled(notifications.sms.enabled);
      setEmailEnabled(notifications.email.enabled);
    }
  }, [notifications]);
  const saveNotifications = () => {
    const payload = {
      sms: smsEnabled,
      email: emailEnabled
    }
    dispatch(updateNotifications(payload));
  }
  return <>
    <span>Choose to enable or disable notifications via the available mediums</span>
    <div className="mt-32">
      <div className={`p-16 mb-16 flex flex-col ${styles.NotificationContainer}`}>
        <div className="flex justify-between font-medium">
          <div className="flex">
            <img src={SMSIcon} className="mr-1.5" />
            <span>SMS</span>
          </div>
          <IOSSwitch checked={smsEnabled} onChange={(e) => setSmsEnabled(!smsEnabled)} size="small" />
        </div>
        <span className="mt-8">Choose to enable or disable SMS Notification</span>
        <span className="font-medium mt-8">Availalbe SMS: <strong>{notifications?.sms.availableCount}</strong></span>
      </div>
      <div className={`${styles.NotificationContainer} p-16 mb-16 flex flex-col`}>
        <div className="flex justify-between font-medium">
          <div className="flex">
            <img src={EmailIcon} className="mr-1.5" />
            <span>Email</span>
          </div>
          <IOSSwitch checked={emailEnabled} onChange={(e) => setEmailEnabled(!emailEnabled)} size="small" />
        </div>
        <span className="mt-8">Choose to enable or disable Email Notification</span>
        <span className="font-medium mt-8">Availalbe Email: <strong>{notifications?.email.availableCount}</strong></span>
      </div>
    </div>
    <div className={styles.ButtonGroupContainer}>
      <ButtonGroup
        primaryCta="Save"
        secondaryCta="Cancel"
        primaryFn={saveNotifications}
        secondaryFn={() => {
          navigate('/settings');
        }} />
    </div>
  </>
}