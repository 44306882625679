import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { setHeader } from 'store/reducer/layout';
import styles from './styles.module.scss';
import ErrorContainer from '../../utils/error';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import GoogleOauthComponent from '../auth/googleOauthComponent';
import { SimpleInput, Button } from '@tt/fe-common';
import { Formik, Form } from 'formik';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useSigninMutation } from '../../store/reducer/services/signin';

const Signin = ({ currentUser }) => {
  const [addUser, { isLoading }] = useSigninMutation();
  const dispatch = useDispatch();
  const history = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [loginErrors, setLoginErrors] = useState([]);
  const [viewPassword, setViewPassword] = useState(false);

  const formRef = useRef();
  useEffect(() => {
    dispatch(setHeader('SignIn'));
  }, []);
  useEffect(() => {
    if (currentUser) {
      window.location.pathname = '/';
    }
  }, [currentUser]);
  const initialValues = {
    email: '',
    password: ''
  }

  const validationSchema = Yup.object({
    email: Yup.string()
      .email('Invalid email format')
      .required('Required'),
    password: Yup.string().required('Required')
  })

  const onApiSubmit = async (values) => {
    try {
      const userInfo = await addUser(values).unwrap();
      formRef.current.resetForm();
      if (userInfo.temporaryPassword)
        history('/reset-password');
      else {
        window.location.pathname = '/'
      }
    } catch (e) {
      console.log('ERRORS adding user', e.data.errors);
      setLoginErrors([...e.data.errors]);
    }
  };
  const iconClick = () => {
    setShowPassword(!showPassword);
  }
  return (
    <section className="display-flex min-h-screen">
      <div className="hidden md:block bg-emerald-900 w-6/12 px-16">
        <img src="/icons/svgs/logo.svg" className="mt-16 " />
        <div className="display-flex justify-content-center items-center flex-col mt-16">
          <p className="text-2xl text-slate-50">Status Notified Easier</p>
          <p className="text-slate-50 mt-8 mb-32">Its free and easy</p>
        </div>
        <img src="/icons/svgs/signinillustration.svg" className="m-auto " />
      </div>
      <div className="w-full md:w-6/12 md:px-36 m-auto"> <p className="font-bold text-2xl text-center mt-32">Log in</p>
        {/* <p className="text-center mt-16" style={{ color: '#7A828A' }}>Login with</p> */}
        <div className="display-flex justify-content-center mt-16">
          <div>
            <GoogleOauthComponent />
          </div>
        </div>
        <div className="display-flex justify-content-center mt-16 " style={{ color: '#7A828A' }}>
          <span className="text-center">or</span>
        </div>
        <div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            className={styles.form}
            innerRef={formRef}
            onSubmit={onApiSubmit}
          >
            {({ errors, touched, values, handleChange, handleBlur }) => (
              <Form className="px-24">
                <SimpleInput
                  label="Email"
                  placeholder="example@example.com"
                  className="mt-32"
                  size="full-width"
                  name="email"
                  value={values.email}
                  onBlur={handleBlur('email')}
                  handleChange={handleChange('email')}
                />
                {errors.email && touched.email ? (
                  <ErrorContainer>{errors.email}</ErrorContainer>
                ) : null}

                <SimpleInput
                  className="mt-32 mb-8"
                  label="Password"
                  placeholder="Enter password"
                  size="full-width"
                  name="password"
                  type={viewPassword ? "text" : "password"}
                  value={values.password}
                  variant='with-icon'
                  IconComponent={viewPassword ? VisibilityOffIcon : VisibilityIcon}
                  onBlur={handleBlur('password')}
                  handleChange={handleChange('password')}
                  iconHandler={() => setViewPassword(!viewPassword)}
                />
                {errors.password && touched.password ? (
                  <ErrorContainer>{errors.password}</ErrorContainer>
                ) : null}
                <Link to="/forgot-password" style={{ color: '#4C6FFF' }} className="text-xs">
                  Forgot Password?
                </Link>
                <Button color='info' className='mt-5' size="full"
                  onClick={() => formRef.current.handleSubmit()
                  }>
                  Sign in
                </Button>
                <ErrorContainer className="mt-5">{loginErrors.length > 0 && loginErrors.map(({ message }) => message)}</ErrorContainer>
              </Form>
            )}
          </Formik>
          <div className="display-flex justify-content-center" style={{ color: '#7A828A' }}>
            <span className="text-center mt-16 text-xs">don't have account ?</span>
          </div>
          <Button size="full" className='mt-5 px-24' onClick={() => {
            history('/signup');
          }}>
            Sign up
          </Button>
        </div></div>
    </section>
  );
}

export default Signin;