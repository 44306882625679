import React, { useState, useRef, Children } from 'react';
import { Button, Typography } from '@mui/material';
import { styled } from '@mui/system';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import styles from './image.module.scss'
import { Button as ModalButton, dottedBorder } from '@tt/fe-common';
import { Box, Modal } from '@mui/material';
import ImageCropper from './cropImage'
import DeleteIcon from '@mui/icons-material/Delete';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 520,
  height: 520,
  backgroundColor: '#FFFFFF',
  boxShadow: 24,
  borderRadius: 4,
  padding: 10,
  overflow: 'auto',
};

export function AddImage({ children, mainCroppedImage, setMainCroppedImage }) {
  const ImageButton = styled(Button)(({ theme }) => {
    console.log(theme);
    return `
  background-image: ${dottedBorder()};
  width: 106px;
  height: 106px;
  color: ${theme.palette.primary.contrastText};
`});
  const [selectedFile, setSelectedFile] = useState(null);
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [showModel, toggleModel] = useState(false);
  const [croppedImage, setCroppedImageURL] = useState(undefined);
  const handleChange = async (event) => {
    setSelectedFile(URL.createObjectURL(event.target.files[0]));
    setIsFilePicked(true);
    toggleModel(true)
  };
  const ModalContent = () => {
    return (
      <Modal open={showModel} onClose={() => toggleModel(!showModel)}>
        <Box style={style}>
          <div className={styles.header}>
            <Typography variant="h4">Add Image</Typography>
            <CancelRoundedIcon className={styles.crossIcon} onClick={() => toggleModel(!showModel)} />
          </div>
          <div className={styles.Image}>
            <ImageCropper
              imageToCrop={selectedFile}
              setCroppedImageURL={setCroppedImageURL} />
          </div>
          <div className={styles.buttonGroup}>
            <ModalButton className={styles.button} onClick={() => toggleModel(!showModel)}>Cancel</ModalButton>
            <ModalButton onClick={() => {
              setMainCroppedImage(croppedImage)
              toggleModel(!showModel)
            }}>Add Image</ModalButton>
          </div>
        </Box>
      </Modal>
    )
  }

  return (
    <div>
      <ImageButton component="label">
        <span>{!mainCroppedImage && (<span>Add Image</span>)}</span>
        <span>{mainCroppedImage && (
          <div>
            <img height='113px' width='106px' alt="Cropped Img" src={mainCroppedImage} />
          </div>
        )}</span>
        <input type="file" hidden onChange={handleChange} />
      </ImageButton>
      {isFilePicked && ModalContent()}
      {mainCroppedImage && <DeleteIcon color="error" sx={{ marginLeft: 4 }} onClick={() => setMainCroppedImage(null)} />}
    </div>
  );
}
