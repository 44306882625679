import React, {useEffect} from 'react';
import { useDispatch } from 'react-redux';

import { setHeader } from 'store/reducer/layout';
import KfCard from '../../components/KfCard';
import agentList from '../../mock/agent';

import cogImg from 'assets/icons/color/cog.png';
import calendarIcon from 'assets/icons/color/calendar.png';
import userImg from 'assets/icons/user.png';

const config = {
  name: userImg,
  timeStamp: calendarIcon,
  process: cogImg
};

export default function AgentList() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setHeader('Agents'));
  });
  return (
    <>
      {
        agentList(10).process.map(datum => {
          const otherProps = {};
          datum.src && (otherProps.src = datum.src);
          return (
            <KfCard data={datum} head={datum.product} config={config} {...otherProps} />
          )
        })
      }
    </>
  );
}
