import React from 'react';
import Multiselect from 'multiselect-react-dropdown';

export const MultiSelect = ({ options, groupBy, displayValue, className, onRemove,
  onSelect, selectedValues, optionValueDecorator, selectedValueDecorator, customCloseIcon }) => {
  return <Multiselect
    displayValue={displayValue}
    groupBy={groupBy}
    customCloseIcon={customCloseIcon}
    optionValueDecorator={optionValueDecorator}
    selectedValueDecorator={selectedValueDecorator}
    selectedValues={selectedValues}
    onKeyPressFn={function noRefCheck() { }}
    onRemove={onRemove || function noRefCheck() { }}
    onSearch={function noRefCheck() { }}
    onSelect={onSelect}
    options={options}
    showCheckbox
    className={className}
    style={{
      chips: {
        background: "rgba(80, 69, 204, 0.1)",
        border: "1px solid #5045CC",
        color: "#5045CC"
      }
    }}
  />
}