const minuteConvertor = (span, unit) => {
  switch (unit) {
    case 'hours':
      return span * 60;
    case 'days':
      return span * 24 * 60;
    case 'weeks':
      return span * 7 * 24 * 60;
    case 'months':
      return span * 30 * 7 * 24 * 60;
    default:
      return span;
  }
};

const findTotalTime = (processList) => {
  const totalTime = processList.reduce(
    /* eslint-disable */
    (accumulator, process) =>
      accumulator + minuteConvertor(Number(process.timeSpan), process.unit),
    0
  );
  return totalTime;
};

export { findTotalTime, minuteConvertor };