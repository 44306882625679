import React, { useRef, useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import { SimpleInput, Button, ButtonGroup } from '@tt/fe-common';
import * as Yup from 'yup';
import ErrorContainer from '../../utils/error';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useMediaQuery } from '@mui/material';
import styles from './onboarding.module.scss';
import { TimezoneSelect, listTz, findTzByName, findTzByKey, tzRawData } from 'timezone-select-js';

export default function CompanyDetailsComponent({
  onboardingDetails,
  setOnboardingDetails,
  setActiveStep,
  companyDetails
}) {

  const formRef = useRef();
  const isMobileView = useMediaQuery('(max-width:576px)');

  const validationSchema = Yup.object({
    companyEmail: Yup.string()
      .email('Invalid email format')
      .required('Required'),
    domain: Yup.string()
      .required('Please help us to know your domain'),
    phone: Yup.string()
      .required('Phone number is required'),
    timeZone: Yup.object().required('Time zone is required')
  });


  const initialValues = {
    domain: onboardingDetails?.domain || '',
    phone: onboardingDetails?.phone || '',
    companyEmail: onboardingDetails?.companyEmail || '',
    timeZone: onboardingDetails?.timeZone
  };


  const companyDetailsSubmit = async (values) => {
    try {
      setActiveStep(1);
      const details = Object.assign(onboardingDetails, values);
      setOnboardingDetails(details);
    }
    catch (e) {
      console.log('submitting error', e);
    }
  }

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        innerRef={formRef}
        onSubmit={companyDetailsSubmit}
      >
        {({ errors, touched, values, handleChange, handleBlur, setFieldValue }) => (

          <Form className="px-24">
            <SimpleInput
              label="Domain"
              className="mt-32"
              size="full-width"
              name="domain"
              value={values.domain}
              onBlur={handleBlur('domain')}
              handleChange={handleChange('domain')}
            />
            {errors.domain && touched.domain ? (
              <ErrorContainer>{errors.domain}</ErrorContainer>
            ) : null}

            <SimpleInput
              label="Company Email ID"
              className="mt-32"
              size="full-width"
              name="companyEmail"
              value={values.companyEmail}
              onBlur={handleBlur('companyEmail')}
              handleChange={handleChange('companyEmail')}
            />
            {errors.companyEmail && touched.companyEmail ? (
              <ErrorContainer>{errors.companyEmail}</ErrorContainer>
            ) : null}

            <SimpleInput
              label="Phone No"
              className="mt-32"
              size="full-width"
              name="phone"
              value={values.phone}
              onBlur={handleBlur('phone')}
              handleChange={handleChange('phone')}
            />
            {errors.phone && touched.phone ? (
              <ErrorContainer>{errors.phone}</ErrorContainer>
            ) : null}
            <div className="mt-32 text-sm mb-16">
              <span>Time Zone</span>
              <TimezoneSelect
                value={values.timeZone}
                label="Time Zone"
                name="timeZone"
                onBlur={handleBlur('timeZone')}
                onChange={(e) => {
                  setFieldValue('timeZone', e);
                }}
              />
              {errors.timeZone && touched.timeZone ? (
                <ErrorContainer>{errors.timeZone}</ErrorContainer>
              ) : null}
            </div>

            {
              !isMobileView ?
                <Button color='info' className='mt-32'
                  onClick={() => formRef.current.handleSubmit()
                  }>
                  Next &nbsp;<ArrowForwardIcon fontSize="small" />
                </Button> :
                <div className={styles.btnGrp}>
                  <ButtonGroup
                    primaryCta="Next"
                    secondaryCta="Skip"
                    primaryFn={() => formRef.current.handleSubmit()}
                    secondaryFn={() => console.log('Skip')} />
                </div>
            }
          </Form>
        )}
      </Formik>
    </div>
  );
}

