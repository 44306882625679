import React, { useState, useEffect } from 'react';
import { SwipeableDrawer, useMediaQuery, Drawer } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, ButtonGroup, InputHybridButton } from '@tt/fe-common';
import styles from './new.module.scss';
import ProductList from './product/productList';
import ProductCart from './product/productCart';
import ListCustomers from './customer/listCustomers';
import { useCreateOrderMutation } from 'store/reducer/services/order';
import { LEFT_ACTION, setHeader, setLeftAction } from 'store/reducer/layout';
import AddCustomer from './customer/new';
import { setBottomSheetComponent } from 'store/reducer/layout';
import Avatar from 'components/commom/Avatar';
import { resetCart } from 'store/reducer/productCart';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 536,
  height: 640,
  backgroundColor: '#FFFFFF',
  boxShadow: 24,
  borderRadius: 5,
  padding: 10,
  overflow: 'auto',
};

export default function Order() {
  const [openProducts, toggleOpenProducts] = useState(false);
  const [openCreateCustomer, toggleOpenCreateCustomer] = useState(false);
  const [openListCustomer, toggleOpenListCustomers] = useState(false);
  const [addedProducts, setAddedProducts] = useState([]);
  const addedProductsInCart = useSelector(state => state.cart.products);
  const [addedCustomers, setAddedCustomers] = useState([]);
  const [createOrder, { isLoading }] = useCreateOrderMutation();
  const [customerError, setCustomerError] = useState(false);
  const showSwipeable = useMediaQuery('(max-width:576px)');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setHeader('New order'));
    dispatch(setLeftAction(LEFT_ACTION.BACK));
  }, []);

  useEffect(() => {
    if (addedCustomers.length) {
      setCustomerError(false);
    }
  }, [addedCustomers])

  const listCustomers = () => {
    if (showSwipeable) {
      return (
        <SwipeableDrawer
          open={openListCustomer}
          anchor="bottom"
          onClose={() => toggleOpenListCustomers(!openListCustomer)}
        >
          <ListCustomers
            toggleOpenListCustomers={toggleOpenListCustomers}
            addedCustomers={addedCustomers}
            setAddedCustomers={setAddedCustomers}
          />
        </SwipeableDrawer>
      );
    }

    return (
      <Drawer
        open={openListCustomer}
        anchor="right"
        onClose={() => toggleOpenListCustomers(!openListCustomer)}
      >
        <ListCustomers
          toggleOpenListCustomers={toggleOpenListCustomers}
          addedCustomers={addedCustomers}
          setAddedCustomers={setAddedCustomers}
        />
      </Drawer>
    );

  };

  const navigate = useNavigate();

  const placeOrder = async () => {
    if (!addedCustomers.length) {
      setCustomerError(true);
    } else {
      addedProductsInCart.forEach(async (product, index) => {
        const payload = {
          customerId: addedCustomers[0].id,
          quantity: product.quantity || 1,
          ...product,
        };
        await createOrder(payload).unwrap();
        if (index === addedProductsInCart.length - 1) {
          dispatch(resetCart([]));
          setAddedCustomers([]);
        }
      });
    }
  };

  Order.BottomDrawerChild = [
    () => <AddCustomer toggleOpenCreateCustomer={toggleOpenCreateCustomer} setAddedCustomers={setAddedCustomers} />,
    () => (
      <ProductList
        addedProducts={addedProducts}
        setAddedProducts={setAddedProducts}
        toggleOpenProducts={toggleOpenProducts}
      />
    )
  ];

  return (
    <>
      <section className={styles.mainPage}>
        {
          !!addedProductsInCart &&
          !!addedProductsInCart.length && (
            <ProductCart
            />
          )}
        <Button
          label="Product"
          onClick={() => {
            if (showSwipeable) {
              dispatch(setBottomSheetComponent(1));
            } else {
              toggleOpenProducts(true);
            }
          }}
        >
          <AddIcon />
          Add Product
        </Button>
        <InputHybridButton
          variant="contained" label="Customer" className="pt-24"
          onClick={() => toggleOpenListCustomers(!openListCustomer)}
        >{addedCustomers.length ?
          <div className="flex items-center">
            <Avatar name={addedCustomers[0]?.customerName} size={28} radius={14} className="mr-4" />
            <span>{addedCustomers[0]?.customerName}</span>
          </div>
          :
          <span>Choose customer...</span>
          }</InputHybridButton>
        <div class={styles['new-customer']}>
          <Button
            variant="none"
            color="info"
            className={`${styles.left} mt-8 ${styles.buttonSpace}`}
            onClick={() => {
              if (showSwipeable)
                dispatch(setBottomSheetComponent(0))
              else
                toggleOpenCreateCustomer(true);
            }}
          >
            or create new customer
          </Button>
        </div>
        {customerError && <div className="flex justify-center mb-8 text-red-500 font-medium text-xs">You need to add a customer before placing an order</div>}

        {listCustomers()}
        {/* {opentNewCustomer && createNewCustomer()} */}
        <div className="boxer">
          <ButtonGroup
            primaryCta="create order"
            secondaryCta="Cancel"
            primaryFn={placeOrder}
            secondaryFn={() =>{dispatch(resetCart([]));
              navigate(-1);} }
          />
        </div>
        <Drawer
          anchor="right"
          open={openProducts}
          onClose={() => {
            toggleOpenProducts(false)
          }}
        >
          <ProductList
            addedProducts={addedProducts}
            setAddedProducts={setAddedProducts}
            toggleOpenProducts={toggleOpenProducts}
          />
        </Drawer>
        <Drawer
          anchor="right"
          open={openCreateCustomer}
          onClose={() => {
            toggleOpenCreateCustomer(false)
          }}
        >
          <div className={styles.drawerContainer}>
            <AddCustomer toggleOpenCreateCustomer={toggleOpenCreateCustomer}
              setAddedCustomers={setAddedCustomers}
            />
          </div>
        </Drawer>
      </section>
    </>
  );
}

