import React, { useState, useEffect } from 'react';
import { getRandomImage } from '../../../utils/randomImages';
import { Typography } from '@mui/material';
import { convertMinutesToHoursAndDays } from '../../../utils/minutesConvertor';
import { Incrementor, Button } from '@tt/fe-common';
import DeleteIcon from '../../../assets/icons/light/delete.svg';
import styles from '../new.module.scss';
import { SwipeableDrawer, useMediaQuery } from '@mui/material';
import TimeModify from './timeModify';
import { removeProduct, updateProduct } from 'store/reducer/productCart';
import { useDispatch, useSelector } from 'react-redux';

const Product = ({
  product,
  removeFromCart,
}) => {
  const [quantity, setQuantity] = useState(1);
  const [openChangeTime, toggleOpenChangeTime] = useState(false);
  const showDrawer = useMediaQuery('(max-width:576px)');
  const productsInCart = useSelector(state => state.cart.products);
  const dispatch = useDispatch();

  const showChangeTimeOption = (
    openChangeTime,
    toggleOpenChangeTime,
    product,
  ) => {
    return (
      <SwipeableDrawer
        open={openChangeTime && showDrawer}
        anchor="bottom"
        onClose={() => toggleOpenChangeTime(!openChangeTime)}
      >
        <TimeModify
          product={product}
          toggleOpenChangeTime={toggleOpenChangeTime}
        />
      </SwipeableDrawer>
    );
  };
  useEffect(() => {
    if (productsInCart?.length) {
      let [productToChange] = productsInCart.filter((addedProduct) => {
        return addedProduct.id === product.id
      });
      productToChange = {
        ...productToChange,
        quantity: quantity,
      }
      dispatch(updateProduct(productToChange));
    }
  }, [quantity]);

  return (
    <>
      <div className={styles.mainContainer}>
        <div className={styles.cardContainer} key={product}>
          <img src={`${product.imageURL ? product.imageURL : getRandomImage()}`} />
          <span>{convertMinutesToHoursAndDays(product.totalTime)}</span>
          <div className={styles.productContent}>
            <Typography variant="h5">
              <strong>{product.productName}</strong>
            </Typography>
            <div>
              {product?.processList?.map(({ processName }, index) => {
                const seperator =
                  index < product.processList.length - 1 ? styles.seperator : '';
                return (
                  <Typography variant="span" className={seperator}>
                    {processName}
                  </Typography>
                );
              })}
            </div>
            <div className={styles.cartActions}>
              <Incrementor value={quantity} updateFunc={setQuantity} />
              <img src={DeleteIcon} onClick={() => removeFromCart(product.id)} />
            </div>
            <div>
              <Button onClick={() => toggleOpenChangeTime(!openChangeTime)}>
                change time to complete
              </Button>
              {openChangeTime &&
                showChangeTimeOption(
                  openChangeTime,
                  toggleOpenChangeTime,
                  product
                )}
            </div>
          </div>

        </div>
        {(openChangeTime && !showDrawer) &&
          <div className={styles.cardContainerTwo}>
            <TimeModify
              product={product}
              toggleOpenChangeTime={toggleOpenChangeTime}
            />
          </div>
        }
      </div>
    </>
  );
};
export default function ProductCart() {
  const dispatch = useDispatch();
  const productsInCart = useSelector(state => state.cart.products);
  const removeFromCart = (id) => {
    dispatch(removeProduct(id));
  };
  return (
    <>
      {productsInCart.map((product) => {
        return (
          <Product
            product={product}
            removeFromCart={removeFromCart}
            key={product.id}
          />
        );
      })}
    </>
  );
}