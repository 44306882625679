import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  processInvolved: [],
  productToBeEdited: null
};

const newProductSlice = createSlice({
  name: "newProduct",
  initialState,
  reducers: {
    addProcess: (state, action) => {
      const { process, event } = action.payload;
      if (event) {
        state.processInvolved = [...state.processInvolved, process];
      }
      else {
        const filteredProcess = state.processInvolved?.filter(addedProcess => addedProcess.id !== process.id);
        state.processInvolved = [...filteredProcess];
      }
    },
    arrangeProcesses: (state, action) => {
      state.processInvolved = [...action.payload]
    },
    addNewProcess: (state, action) => {
      state.processInvolved = [...action.payload, ...state.processInvolved]
    },
    removeProcess: (state, action) => {
      const filteredProcess = state.processInvolved?.filter(addedProcess => addedProcess.id !== action.payload);
      state.processInvolved = [...filteredProcess];
    },
    setProductToBeEdited: (state, action) => {
      state.productToBeEdited = action.payload;
      state.processInvolved = action.payload.processList;
    },
    resetProcessToEdited: (state) => {
      state.productToBeEdited = null;
      state.processInvolved = [];
    }
  }
});

export const {
  addProcess,
  arrangeProcesses,
  addNewProcess,
  removeProcess,
  setProductToBeEdited,
  resetProcessToEdited
} = newProductSlice.actions;
export default newProductSlice.reducer;