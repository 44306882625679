
import { Formik, Form } from 'formik';
import { SimpleInput, Button } from '@tt/fe-common';
import { useEffect, useRef, useState, useMemo } from 'react';
import * as Yup from 'yup';
import { VisibilityOffIcon } from '@mui/icons-material/VisibilityOff';
import styles from './signup.module.scss';
import ErrorContainer from '../../utils/error';
import { useResetPasswordMutation, useUpdatePassowrdMutation } from '../../store/reducer/services/employee';
import {
  useLocation,
  useNavigate
} from "react-router-dom";
import { useSignoutMutation } from '../../store/reducer/services/signin';

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}
const ResetPassword = () => {

  const formRef = useRef();
  let query = useQuery();
  const [resetPassword, { isLoading }] = useResetPasswordMutation();
  const [updatePassowrd] = useUpdatePassowrdMutation();
  const navigate = useNavigate();
  const [removeUser] = useSignoutMutation();

  const initialValues = {
    password: '',
    confirmPassword: '',
  }

  const [passwordHasUppercase, setePasswordHasUppercase] = useState(false);
  const [passwordHasLowercase, setePasswordHasLowercase] = useState(false);
  const [passwordHasDigits, setPasswordHasDigits] = useState(false);
  const [passwordHasSpecialCharacter, setPasswordHasSpecialCharacter] = useState(false);
  const [passwordLengthValidation, setPasswordLengthValidation] = useState(false);

  const setPassword = (value) => {
    const uppercaseRegExp = /(?=.*?[A-Z])/;
    const lowercaseRegExp = /(?=.*?[a-z])/;
    const digitsRegExp = /(?=.*?[0-9])/;
    const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
    const minLengthRegExp = /.{8,}/;
    formRef.current.setFieldValue(
      'password', value,
    );
    const passwordLength = value.length;
    setePasswordHasUppercase(uppercaseRegExp.test(value));
    setePasswordHasLowercase(lowercaseRegExp.test(value));
    setPasswordHasDigits(digitsRegExp.test(value));
    setPasswordHasSpecialCharacter(specialCharRegExp.test(value));
    setPasswordLengthValidation(minLengthRegExp.test(value));
  }

  const validationSchema = Yup.object({
    password: Yup.string()
      .required('Required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
  })

  const handleSubmit = async (values) => {
    try {
      let payload = {
        ...values,
      }
      if (query.get('data')) {
        payload = {
          ...values,
          linkParam: query.get('data').replaceAll(" ", "+")
        }
        await resetPassword(payload).unwrap();
      } else {
        await updatePassowrd(payload).unwrap();
      }
      await removeUser();
      navigate('/signin');
    } catch (e) {
      console.log("Error", e);
    }
  }
  return (
    <section className="display-flex min-h-screen">
      <div className="hidden md:block bg-emerald-900 w-6/12 px-16">
        <img src="/icons/svgs/logo.svg" className="mt-16 " />
        <div className="display-flex justify-content-center items-center flex-col mt-16">
          <p className="text-2xl text-slate-50">Status Notified Easier</p>
          <p className="text-slate-50 mt-8 mb-32">Its free and easy</p>
        </div>
        <img src="/icons/svgs/signinillustration.svg" className="m-auto " />
      </div>
      <div className="w-full md:w-6/12 md:px-36 m-auto">
        <p className="font-bold text-2xl text-center mt-32">Reset Password</p>
        <div className="py-16 px-32">


          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            innerRef={formRef}
            enableReinitialize
            onSubmit={handleSubmit}
          >
            {({ errors, touched, values, handleChange, handleBlur }) => (
              <Form>
                <SimpleInput
                  className="mt-32"
                  label="Password"
                  size="full-width"
                  name="password"
                  type="password"
                  value={values.password}
                  variant='with-icon'
                  IconComponent={VisibilityOffIcon}
                  onBlur={handleBlur('password')}
                  handleChange={setPassword}
                />
                <div className="text-xs mt-16">
                  <div>
                    <span className={`${styles.dot} ${!passwordLengthValidation ? 'transition ease-in-out delay-150 bg-slate-300' : 'transition ease-in-out delay-150 bg-green-500'}`}></span>
                    <span>Must be 8 Character at least</span>
                  </div>
                  <div>
                    <span className={`${styles.dot} ${!(passwordHasUppercase && passwordHasLowercase) ? 'transition ease-in-out delay-150 bg-slate-300' : 'transition ease-in-out delay-150 bg-green-500'}`}></span>
                    <span>Should be a combination of upper and lower case </span>
                  </div>
                  <div>
                    <span className={`${styles.dot} ${!passwordHasDigits ? 'transition ease-in-out delay-150 bg-slate-300' : 'transition ease-in-out delay-150 bg-green-500'}`}></span>
                    <span>Must contain a number </span>
                  </div>
                  <div>
                    <span className={`${styles.dot} ${!passwordHasSpecialCharacter ? 'transition ease-in-out delay-150 bg-slate-300' : 'transition ease-in-out delay-150 bg-green-500'}`}></span>
                    <span>Must contain a special character </span>
                  </div>
                </div>
                {errors.password && touched.password ? (
                  <ErrorContainer>{errors.password}</ErrorContainer>
                ) : null}

                <SimpleInput
                  className="mt-32"
                  label="confirm Password"
                  size="full-width"
                  name="confirmPassword"
                  type="password"
                  value={values.confirmPassword}
                  variant='with-icon'
                  IconComponent={VisibilityOffIcon}
                  onBlur={handleBlur('confirmPassword')}
                  handleChange={handleChange('confirmPassword')}
                />
                {errors.confirmPassword && touched.confirmPassword ? (
                  <ErrorContainer>{errors.confirmPassword}</ErrorContainer>
                ) : null}

                <Button color='info' className='mt-32'
                  onClick={() => formRef.current.handleSubmit()
                  }>
                  Reset Password
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </div>

    </section>
  )
}

export default ResetPassword;