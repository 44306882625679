import React, { useState, useEffect } from 'react';
import './styles.scss';
import { useDispatch } from 'react-redux';
import { useMediaQuery, Drawer } from '@mui/material';
import { setHeader } from 'store/reducer/layout';
import { useGetCustomersQuery,  customerAPI, useDeleteCustomerMutation } from '../../store/reducer/services/customer';
import { SimpleInput } from '@tt/fe-common';
import { Search } from '@mui/icons-material';
import RemoveIcon from 'assets/icons/remove.svg'
import EditIcons from 'assets/icons/edit.svg'
import styles from './styles.module.scss';
import { setBottomSheetComponent } from 'store/reducer/layout';
import AddCustomer from 'pages/order/customer/new';

export default function Customer() {

  const { data, isFetching } = useGetCustomersQuery();
  const [customerList, setCustomerList] = useState([]);
  const dispatch = useDispatch();
  const isMobile = useMediaQuery('(max-width:576px)');
  const [customerToBeEdited, setCustomerToBeEdited] = useState(null);
  const [deleteCustomer] = useDeleteCustomerMutation();
  const [editCustomer, setEditCustomer] = useState(false);
  const { refetch } = customerAPI.endpoints.getCustomers.useQuerySubscription();

  console.log("customer list", customerList);


  useEffect(() => {
    dispatch(setHeader('Customers'));
    refetch();
  }, []);

  Customer.BottomDrawerChild = [
    () => <AddCustomer fromLayout={true} isEdit={true}  />
  ];

  useEffect(() => {
    setCustomerList(data);
  }, [data]);

  useEffect(() => {
    return () => {
      setCustomerList([]);
    }
  }, []);

  const removeCustomer = async (id) => {
    await deleteCustomer({ id }).unwrap();
  }

  const editSingleCustomer = (customer) => {
    setCustomerToBeEdited(customer);
    if (isMobile) {
      setTimeout(() => {
        dispatch(setBottomSheetComponent(0));
      }, 100);
    } else {
      setEditCustomer(true);
    }
  }

  const filterCustomer = (searchText) => {
    if (searchText) {
      const searchedCustomers = data.filter((customer) => {
        return customer.customerName
          .toLowerCase()
          .includes(searchText.toLowerCase());
      });
      setCustomerList(searchedCustomers);
    } else {
      setCustomerList(data);
    }
  }

  const renderCustomersList = () => {
    if (customerList?.length) {
      return customerList.map((customer) => {
        const { customerName, address, id } = customer
        return (
          <div className="flex my-16">
            <img src={RemoveIcon} alt="remove" className="mr-16 cursor-pointer" onClick={() => removeCustomer(id)} />
            <div className={`${styles.processContainer} py-16 w-full px-16 flex justify-between`}>
              <div>
                <div>
                  <span className="font-semibold text-base">{customerName}</span>
                </div>
                <div className="mt-8 text-sm">
                  <span className="font-normal">Address: </span>
                  <span className="font-medium">{`${address}`}</span>
                </div>
              </div>
              <div className={`${styles.editContainer} cursor-pointer flex 
              justify-around items-center font-medium text-sm`} onClick={() => editSingleCustomer(customer)}>
                <img src={EditIcons} />
                <span>Edit</span>
              </div>
            </div>
          </div>
        )
      })
    }
  }


  return (
    <>
      <div className="xs:w-full sm:w-9/12 md:w-2/4 lg:w-1/3 xl:w-1/3 2xl:w-1/3">
        <SimpleInput size="full-width"
          handleChange={filterCustomer}
          variant="with-icon"
          placeholder="Search Customers..."
          IconComponent={Search}
        />
      </div>
      <div className={styles.listContainer}>
        {renderCustomersList()}
      </div>
      <Drawer
        anchor="right"
        open={editCustomer}
        onClose={() => {
          setEditCustomer(false)
        }}
      >
        <div className={styles.modalContainer}>
          <AddCustomer setCreateNewCustomer={setEditCustomer} fromLayout={true}  />
        </div>
      </Drawer>
    </>
  );
}

Customer.fab = true;