import * as React from 'react';
import clsx from 'clsx';
import { styled } from '@mui/system';
import { useSwitch } from '@mui/core/SwitchUnstyled';


const SwitchRoot = styled('span')(
  ({ theme }) => `
    display: inline-block;
    position: relative;
    width: 72px;
    height: 36px;
    border-radius: ${theme.shape.borderRadius}px;
    background-color: ${theme.palette.cancel.main};
  `
);

const SwitchInput = styled('input')`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 1;
  margin: 0;
  cursor: pointer;
`;

const SwitchTrack = styled('span')`
  display: table;
  width: 100%;
  height: 100%;
`;
const SwitchTextOn = styled('span')(
  ({ theme }) => `
    position: relative;
    width: 36px;
    height: 36px;
    color: ${theme.palette.info.contrastText};
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    font-size: ${theme.typography.caption.fontSize};
    font-family: ${theme.typography.caption.fontFamily};
  `
);
const SwitchTextOff = styled(SwitchTextOn)`
  left: unset;
  right: 2px;
`;
const SwitchThumb = styled('span')(
  ({ theme }) => `
    position: absolute;
    display: block;
    background-color: transparent;
    width: 32px;
    height: 32px;
    border-radius: ${theme.shape.borderRadius}px;
    top: 2px;
    left: 2px;
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1);

    &:before {
      display: block;
      content: "";
      width: 100%;
      height: 100%;
      background-color: ${theme.palette.info.main};
      border-radius: ${theme.shape.borderRadius}px;
    }

    &.focusVisible {
      background-color: #79B;
    }

    &.checked {
      transform: translateX(36px);
    }
  `
);

export const SquareSwitch = (props) => {
  const { getInputProps, checked, disabled, focusVisible } = useSwitch(props);
  const stateClasses = {
    checked,
    disabled,
    focusVisible,
  };
  return (
    <SwitchRoot className={clsx(stateClasses)}>
      <SwitchTrack>
        <SwitchThumb className={clsx(stateClasses)} />
        <SwitchTextOn>AM</SwitchTextOn>
        <SwitchTextOff>PM</SwitchTextOff>
      </SwitchTrack>
      <SwitchInput {...getInputProps()} aria-label="Demo switch" />
    </SwitchRoot>
  );
}
