const toastrMessages = [
  {
    'url': '/api/employee/create',
    'method': 'post',
    'success': 'Agent Created',
    'error': 'Error in creating Agent'
  },
  {
    'url': '/api/employee',
    'method': 'put',
    'success': 'Agent updated successfully',
    'error': 'Error in updating Agent'
  },
  {
    'url': '/api/employee',
    'method': 'delete',
    'success': 'Agent removed successfully',
    'error': 'Error in removing Agent'
  },
  {
    'url': '/api/product/create',
    'method': 'post',
    'success': 'Product Created',
    'error': 'Error in creating product'
  },
  {
    'url': '/api/process/create',
    'method': 'post',
    'success': 'Process Created',
    'error': 'Error in creating process'
  }
]

export const canShowMessage = (requestMethod, requestURL) => {
  return toastrMessages.some(({ url, method }) => requestMethod === method && requestURL.includes(url));
}

export const getMessage = (requestMethod, requestURL, status) => {
  const [message] = toastrMessages.filter(({ url, method }) => requestMethod === method && requestURL.includes(url))
  return message[status];
}