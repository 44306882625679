import React, { useState, useEffect } from 'react';
import { useGetCustomersQuery } from 'store/reducer/services/customer';
import { SimpleInput, Button, ButtonGroup } from '@tt/fe-common';
import styles from './new.module.scss';
import { Search } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import { Typography } from '@mui/material';
import Avatar from 'components/commom/Avatar';
import backIcon from 'assets/icons/light/back.svg';

export default function ListCustomers({ toggleOpenListCustomers, addedCustomers, setAddedCustomers }) {
  const { data, isLoading } = useGetCustomersQuery();
  const [customersList, setCustomersList] = useState([]);
  const isCustomerAdded = (id) => {
    return addedCustomers.some((customer) => customer.id === id);
  }
  const addCustomer = (customer) => {
    if (!isCustomerAdded(customer.id)) {
      setAddedCustomers([customer]);
    }
    else {
      setAddedCustomers([]);
    }
  }

  useEffect(() => {
    if (!customersList?.length)
      setCustomersList(data);
  }, [data, customersList]);

  const filterProduct = (searchText) => {
    if (searchText) {
      const searchedCustomers = customersList.filter((customer) => {
        return customer.customerName
          .toLowerCase()
          .includes(searchText.toLowerCase());
      });
      console.log("searchedCustomers", searchedCustomers);
      setCustomersList(searchedCustomers);
    } else {
      setCustomersList(data);
    }
  }

  const renderNoData = () => {
    return (
      <div className={`text-2xl opacity-50 text-stone-500 flex items-center ${styles.noDataContainer} px-8`}>
        <span>No Customers Found. Please add customers to choose.</span>
      </div>
    )
  }
  const renderCustomers = () => {
    return customersList?.map((customer) => {
      return (<div key={customer.id}>
        <div className={styles.customerList} onClick={() => addCustomer(customer)}>
          <div className="mr-8 relative">
            <Avatar name={customer.customerName} size={64} radius={8} />
            {isCustomerAdded(customer.id) && <div className={styles.overlay}><CheckIcon /></div>}
          </div>
          <div>
            <Typography variant="h5"><strong>{
              customer.customerName}</strong></Typography>
            <div className="flex flex-col">
              <Typography variant="div">{customer.email}</Typography>
              <Typography variant="div">{customer.address}</Typography>
              <Typography variant="div">ph- {customer.phoneNumber}</Typography>
            </div>
          </div>
        </div>
      </div >)
    })
  }
  return (
    <div className={styles.listContainer}>
      <div className={styles.header}>
        <div className={styles.imgContainer}>
          <img src={backIcon} onClick={() => toggleOpenListCustomers(false)} className="cursor-pointer" />
        </div>
        <div className={styles.addHeadingContainer}>
          <span className={styles.customerHeading}>Choose Customer</span>
        </div>
      </div>
      <SimpleInput size="full-width"
        handleChange={filterProduct}
        variant="with-icon"
        placeholder="Search Customer..."
        IconComponent={Search}
      />
      <div className={styles.customers}>
        {data?.length > 0 ? renderCustomers() : renderNoData()}
      </div>
    </div>
  )
}
