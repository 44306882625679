import React, { useState, useEffect } from 'react';
import { Badge, Popover } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Select, SimpleInput, ButtonGroup, MultiSelect } from '@tt/fe-common';
import styles from './styles.module.scss';
import { useDispatch } from 'react-redux';
import { setCurrentPage } from 'store/reducer/orders';
import CloseIcon from '@mui/icons-material/Close';

export default function KfSearchV2({ searchFields, searchOperators, setSearchObject, searchValues }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [filterCount, setFilterCount] = useState(0);

  const [formValues, setFormValues] = useState([]);

  const dispatch = useDispatch();
  const init = () => {
    setFormValues([]);
    setFilterCount(0);
    searchFields.forEach(({ key }) => {
      const payload = {
        field: key,
        operator: null,
        criteria: null
      }
      setFormValues((prev) => [...prev, payload]);
    });
  }
  useEffect(() => {
    init();
  }, []);

  const clearFilter = () => {
    init();
    setAnchorEl(null);
    dispatch(setCurrentPage(1));
    setSearchObject([]);
  }

  useEffect(() => {
    return () => {
      clearFilter();
    };
  }, []);


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const applyFilter = () => {
    const validFilter = formValues.filter(({ operator, criteria }) => !!(operator && criteria) || (Array.isArray(criteria
    )));
    if (validFilter?.length) {
      setFilterCount(validFilter?.length);
      setSearchObject([...validFilter]);
    }
    handleClose();
  }

  const getFormValue = (key) => {
    return formValues.find(({ field }) => field === key)
  }

  const updateFormValues = (key, operator, fieldToBeUpdated, type) => {
    const payload = getFormValue(key);
    payload[fieldToBeUpdated] = type === 'number' ? +operator : operator;
    const index = formValues.findIndex(({ field }) => field === key);
    formValues[index] = payload;
    setFormValues([...formValues])
  }

  const onMultiSelect = (e, key, fieldToBeUpdated, operator) => {
    const payload = getFormValue(key);
    payload[fieldToBeUpdated] = e;
    payload.operator = operator;
    const index = formValues.findIndex(({ field }) => field === key);
    formValues[index] = payload;
    setFormValues([...formValues]);
  }

  const open = Boolean(anchorEl);
  const id = open ? 'filter-popover' : undefined;

  const renderFields = () => {
    return searchFields.map((field) => {
      const { key, name, type } = field;
      const searchOperator = searchOperators.find((operator) => operator.key === key);
      let { searchOperator: operators } = searchOperator;
      operators = operators.map((op) => {
        return {
          value: op,
          text: op
        }
      });
      if (type !== 'multiSelect') {
        return (
          <div key={key}>
            <span className="py-8 text-sm">{name}</span>
            <div className="flex grid grid-cols-2 gap-4">
              <Select
                value={getFormValue(key)?.operator || ''}
                className={`${styles.marginTop} max-h-16`}
                placeholder="Select a criteria..."
                handleChange={(e) => updateFormValues(key, e.target.value, 'operator')}
                data={operators}
              />
              <SimpleInput
                disabled={!getFormValue(key)?.operator}
                type={type}
                max={100}
                min={0}
                value={getFormValue(key)?.criteria || ''}
                handleChange={(e) => updateFormValues(key, e, 'criteria', type)}
              />
            </div>
          </div>
        )
      }
      else {
        const [options] = searchValues.filter((val) => val.key === key);
        const { searchOperator } = searchOperators.find((operator) => operator.key === key);
        return (
          <div key={key}>
            <span className="py-8 text-sm">{name}</span>
            <div className="flex grid grid-cols-1 gap-4"></div>
            <MultiSelect options={options.values}
              customCloseIcon={<CloseIcon style={{
                fontSize: 14,
                marginLeft: 5,
                color: "black"
              }} />}
              className={`${styles.customMultiSelect}`}
              emptyRecordMsg="No Values found"
              onSelect={(e) => onMultiSelect(e, key, 'criteria', searchOperator[0])}
              selectedValues={getFormValue(key)?.criteria || []}
              displayValue={field.displayName} />
          </div>
        )
      }
    });
  }
  return <div>
    <Badge badgeContent={filterCount} color="success"
      className="cursor-pointer"
      onClick={handleClick} aria-describedby={id}>
      <FilterListIcon color="action" />
    </Badge>
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <div className={`p-16 ${styles.container}`}>
        <p className="pb-16 text-center">Choose Search Criteria...</p>
        {renderFields()}
        <ButtonGroup
          classes="mt-16"
          primaryCta="Apply Filter"
          secondaryCta="Clear Filter"
          primaryFn={applyFilter}
          secondaryFn={clearFilter} />
      </div>
    </Popover>
  </div>
}