import React, { useState } from 'react';
import cx from 'classnames';
import { Formik, Form, Field, FieldArray, FieldInputProps } from 'formik';

import { FormControl, InputLabel, Button, Typography, Select, MenuItem, IconButton, FormHelperText } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import styles from './styles';
import styleClasses from './styles.module.scss';
import { SimpleInput } from '@tt/fe-common';

function AgentNew() {
  const classes = styles();
  const skillDs = {
    product: '',
    process: ''
  };
  const initialValues = {
    agentName: 'wolverine',
    email: '',
    skills: [{ ...skillDs }]
  };
  const onSubmit = async (values) => {
    console.log(values);
  };
  let skillHelpers;

  return (
    <div className={cx(classes.container, styleClasses.container)}>
      <Typography variant="h2" className={cx(styleClasses.heading)}>New Agent</Typography>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ errors, values, touched, setValues }) => (
          <Form className={classes.form}>
            <fieldset className={classes['primary-fields']}>
              <Typography variant="h4" className={styleClasses.subhead}>Agent info</Typography>
              <Field name="agentName">
                {({ field }) => (
                  <SimpleInput
                    {...field}
                    id="agent-name"
                    label="Agent name"
                    name="agentName"
                    size="full-width"
                    className="mb-20"
                    customHeight={48}
                    handleChange={() => { }}
                    placeholder="Enter name"
                  />
                )}
              </Field>
              <Field name="email">
                {({ field }) => (
                  <SimpleInput {...field}
                    id="agent-email"
                    name="email"
                    label="Email"
                    customHeight={36}
                    className="mb-20"
                    handleChange={() => { }}
                    placeholder="abc@example.com"
                  />
                )}
              </Field>
            </fieldset>
            <fieldset>
              <Typography variant="h4">Skills</Typography>
              <Typography variant="p" className={styleClasses.text}>Choose which process, in which product this agent will work on.</Typography>
              <FieldArray name="skills">
                {(helpers) => {
                  skillHelpers = helpers;
                  return (
                    values.skills.map((_, i) => {
                      return (
                        <div key={i} className={classes['skill-row']}>
                          <FormControl variant="standard">
                            <InputLabel>Process</InputLabel>
                            <Field name={`skills.${i}.process`}>
                              {({ field }) => (
                                <Select {...field}>
                                  {
                                    new Array(5).fill(0).map((_, j) => (
                                      <MenuItem key={j} value={j + 1}>{j + 1}</MenuItem>
                                    ))
                                  }
                                </Select>
                              )}
                            </Field>
                          </FormControl>
                          <FormControl variant="standard">
                            <InputLabel>Product</InputLabel>
                            <Field name={`skills.${i}.product`}>
                              {({ field }) => (
                                <Select {...field}>
                                  {
                                    new Array(5).fill(0).map((_, j) => (
                                      <MenuItem key={j} value={j + 1}>{j + 1}</MenuItem>
                                    ))
                                  }
                                </Select>
                              )}
                            </Field>
                          </FormControl>
                          <IconButton sx={{
                            padding: 3,
                            backgroundColor: 'primary.light',
                            borderRadius: 1
                          }} onClick={() => {
                            if (helpers.form.values.skills.length > 1) {
                              helpers.remove(i);
                            }
                          }}>
                            <DeleteIcon color="info" sx={{
                              fontSize: 24
                            }} />
                          </IconButton>
                        </div>
                      )
                    })
                  )
                }}
              </FieldArray>
              <Button color="info" onClick={e => {
                skillHelpers.push({ ...skillDs });
              }}>&#43; Add skill</Button>
            </fieldset>
            <div>
              <Button>Cancel</Button>
              <Button variant="contained"
                color="info" type="submit">Create Agent</Button>
            </div>
          </Form>
        )}
      </Formik >
    </div >
  );
}

export default AgentNew;
