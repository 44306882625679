import * as React from 'react';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { setToaster } from 'store/reducer/layout';
import { useDispatch } from 'react-redux';
import Slide from '@mui/material/Slide';

function TransitionRight(props) {
  return <Slide {...props} direction="right" />;
}

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function KfToaster({ openToaster, variant, message }) {

  const dispatch = useDispatch();
  const [state, setState] = React.useState({
    vertical: 'bottom',
    horizontal: 'right',
  });
  const { vertical, horizontal } = state;

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(setToaster(''))
  };

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <Snackbar anchorOrigin={{ vertical, horizontal }} open={openToaster} autoHideDuration={3000} onClose={handleClose} TransitionComponent={TransitionRight}>
        {variant === 'success' ? <Alert onClose={handleClose} severity={variant} sx={{ width: '100%' }}>
          {message}
        </Alert> : <Alert onClose={handleClose} severity={variant} sx={{ width: '100%' }}>
          {message}
        </Alert>}
      </Snackbar>
    </Stack>
  );
}
