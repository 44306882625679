import React, { useState, useEffect } from 'react';
import { Typography, Drawer } from '@mui/material';
import { Button } from '@tt/fe-common';
import AddAgent from './addAgent';
import KfTable from '../../../components/commom/KfTable';
import KfcPagination from '../../../components/commom/kfPagination';
import { useGetEmployeesListQuery, useDeleteEmployeeMutation, EmployeeAPI } from 'store/reducer/services/employee';
import DeleteDialog from "../../../components/modal/DeleteDialog";
import { useDispatch, useSelector } from 'react-redux';
import EditIcon from 'assets/icons/edit.svg'
import RemoveIcon from 'assets/icons/remove.svg'
import { setHeader, setBottomSheetComponent } from 'store/reducer/layout';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';
import { Dialog, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { getSubscriptionDetails } from '../../../store/reducer/subscription';
import styles from './agent.module.scss';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    maxWidth: 200
  },
}));

export default function Agents() {
  const [openNewAgentDrawer, setOpenNewAgentDrawer] = useState(false);
  const { data: employeeList } = useGetEmployeesListQuery();
  const [deleteEmployee] = useDeleteEmployeeMutation();
  const [isEdit, setIsEdit] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [skills, setSkills] = useState([{}]);
  const [id, setId] = useState('');
  const forMobile = useMediaQuery('(max-width:576px)');
  const [openSkillsModal, setOpenSkillsModal] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [agentToBeDeleted, setAgentToBeDeleted] = useState(null);
  const { agents, agentsConsumed } = useSelector((state) => state?.subscription)
  const deleteMessage = "Deleting this agent will remove all the existing orders' assigned to this agent. Do you still wanted to delete ?"


  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setHeader('Agents'));
    dispatch(getSubscriptionDetails());
  }, []);
  const handleDeleteDialogClose = () => {
    setOpenDeleteDialog(false);
  };

  const handleOpenDeleteDialog = (agent) => {
    setAgentToBeDeleted(agent);
    setOpenDeleteDialog(true);
  }

  const handleDelete = () => {
    deleteEmployee(agentToBeDeleted.id).then(() => {
      dispatch(EmployeeAPI.util.updateQueryData('getEmployeesList', undefined, (draftEmployees) => {
        return draftEmployees.filter(emp => {
          if (emp.id !== agentToBeDeleted.id) {
            return emp;
          }
        });
      }));
      handleDeleteDialogClose();
    })
  }

  const handleEdit = (id, name, email, skills) => {
    setOpenNewAgentDrawer(true)
    setSkills(skills)
    setIsEdit(true)
    setName(name)
    setEmail(email)
    setId(id)
  }

  const displaySkills = (row) => {
    const { skills } = row;
    const items = [];
    for (let i = 0; i < 3; i++) {
      if (skills.length <= i) {
        break;
      }
      items.push(<span>{` ${skills[i]?.processName}${i < 2 ? "," : ""} `}</span>)
    }
    if (skills.length > 3) {
      items.push(<span className={`cursor-pointer ${styles.link}`}
        onClick={() => {
          setSelectedEmployee(row);
          setOpenSkillsModal(true);
        }}
      >... + {skills.length - 3} more</span>)
    }
    return items;
  }

  const handleClose = () => {
    setOpenSkillsModal(false);
  };

  const displaySkillsInModal = () => {
    const skill = {};
    selectedEmployee.skills?.forEach((skillSet) => {
      const { productName, processName } = skillSet
      if (skill.hasOwnProperty(productName)) {
        skill[productName].push(processName);
      } else {
        skill[productName] = [processName];
      }
    });
    const products = Object.keys(skill);
    return products.map((productName) => {
      const processList = skill[productName];
      return (
        <>
          <div className={`mt-8 text-black font-medium ${styles.modalWidth}`}> {productName}</div >
          <ul className="ml-16">
            {processList.map((processName, index) => <li className="mt-8 font-normal">{`${index + 1}. ${processName}`}</li>)}
          </ul>
        </>
      )
    });
  }

  const columns = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Name',
    },
    {
      id: 'email',
      numeric: false,
      disablePadding: false,
      label: 'Email',
    },
    {
      id: 'skills',
      numeric: false,
      disablePadding: false,
      label: 'Skills',
      customComponent: (row) => {
        return <div className="font-medium">{displaySkills(row)}</div>
      }
    },
    {
      id: 'role',
      numeric: false,
      disablePadding: false,
      label: 'Role',
    },
    {
      id: 'actions',
      numeric: false,
      disablePadding: false,
      label: 'Actions',
      customComponent: (row) => {
        const { name, skills, email, id, role } = row;
        const pointerClass = role === 'ACCOUNT_ADMIN' ? "pointer-events-none" : "";
        return (<div className={`${styles.actionsContainer}`}>
          <img src={EditIcon}
            className={`${styles.images}`}
            onClick={() => handleEdit(id, name, email, skills)} className={styles.icon} />
          <img src={RemoveIcon}
            className={`${styles.images} ${styles.icon} ${pointerClass}`}
            onClick={
              () => {
                if (role !== 'ACCOUNT_ADMIN')
                  handleOpenDeleteDialog(row)
              }
            }
          /></div>)
      }
    }
  ];

  Agents.BottomDrawerChild = [
    () => (
      <AddAgent
        setOpenNewAgentDrawer={setOpenNewAgentDrawer}
        isEdit={isEdit}
        id={id}
        name={name}
        email={email}
        setIsEdit={setIsEdit}
        skillset={skills} />
    )
  ];
  return (
    <div>
      <div className="my-16 display-flex justify-content-space-between h-100">
        <Typography variant="h5">Manage your Employees by assigning them skills and adding them</Typography>
        {agentsConsumed >= agents ? <HtmlTooltip
          title={
            <>
              <span className="text-xs text-white">You have consumed all the agents. Please <Link to="/settings/plans-and-billing"
                className="underline decoration-1 text-sky-500">buy agents </Link>
                to add new ones to your account.</span>
            </>
          }
        >
          <div>
            <Button
              color="info"
              // setOpenNewAgentDrawer(true)
              disabled={agentsConsumed >= agents}
              onClick={() => forMobile ? dispatch(setBottomSheetComponent(0)) : setOpenNewAgentDrawer(true)}
            >
              Add New Agent
            </Button>
          </div>
        </HtmlTooltip> :
          <div>
            <Button
              color="info"
              // setOpenNewAgentDrawer(true)
              disabled={agentsConsumed >= agents}
              onClick={() => forMobile ? dispatch(setBottomSheetComponent(0)) : setOpenNewAgentDrawer(true)}
            >
              Add New Agent
            </Button>
          </div>}

      </div>
      <div>{(employeeList?.length) && <KfTable columns={columns} rows={employeeList}></KfTable>}</div>
      {/* <KfcPagination count={totalPages} setPage={setCurrentPage} /> */}
      <Drawer
        anchor="right"
        open={openNewAgentDrawer}
        onClose={() => {
          setIsEdit(false);
          setOpenNewAgentDrawer(false)
        }}
      >
        <AddAgent setOpenNewAgentDrawer={setOpenNewAgentDrawer} isEdit={isEdit} id={id} name={name}
          setIsEdit={setIsEdit}
          email={email} skillset={skills} />
      </Drawer>
      {
        selectedEmployee && <Dialog onClose={handleClose} open={openSkillsModal} fullScreen={fullScreen}>
          <DialogTitle id="skills-dialog-title" className="flex items-center justify-between">
            <span>{`${selectedEmployee.name}'s skill set`}</span>
            <HighlightOffRoundedIcon className="cursor-pointer text-gray-400" onClick={handleClose} />
          </DialogTitle>
          <DialogContent>
            <DialogContentText>{displaySkillsInModal()}</DialogContentText>
          </DialogContent>
        </Dialog>
      }
      <DeleteDialog
        open={openDeleteDialog}
        onClose={handleDeleteDialogClose}
        action={handleDelete}
        message={deleteMessage}
        title={`Are you sure you wanted to delete ${agentToBeDeleted?.name}`}
      />
    </div >
  );
}
Agents.fab = true;