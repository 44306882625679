import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { LEFT_ACTION, setHeader, setLeftAction } from 'store/reducer/layout';
import NotificationCard from './card';
import { getNotifications } from 'store/reducer/orders';
import styles from './styles.module.scss';

export default function Notifications() {
  const dispatch = useDispatch();
  const notifications = useSelector(state => state.orders.notifications);

  React.useEffect(() => {
    dispatch(setHeader('Notifications'));
    dispatch(setLeftAction(LEFT_ACTION.HAMBURGER));
    dispatch(getNotifications());
  }, []);

  return (
    <>
      <div className={styles.container}>
        {
          notifications.map((datum, i) => (
            <NotificationCard key={i} datum={datum} />
          ))
        }
      </div>
    </>
  );
}
