import React from 'react';
import { styled } from '@mui/system';
import { Select as MuiSelect, InputLabel, MenuItem, ListItemText } from '@mui/material';

/**
 * `props`:
 * @param {Object[]} data - array of select dropdown options
 * @param {string} data[].text - text to be displayed
 * @param {string} data[].value - value to be sent in form
 * @param {string} value - initial value to be selected by default
 * @param {string} id
 * @param {string} label
 */
function Select({ id = '', data = [], value = '', label, handleChange, className, placeholder, variant }) {
  const SelectStyle = styled(MuiSelect)`
    width: 100%;
  `;

  const Cont = styled('div')`
  `;

  /**
   * TODO: display placeholder
   */
  const printValues = () => {
    return data.map((elem) => {
      return <MenuItem key={elem.value} value={elem.value} variant={`${variant ? variant : 'filled'}`}>
        <ListItemText primary={elem.text} />
      </MenuItem>
    })
  }
  return (
    <Cont className={className}>
      {label && <InputLabel id={id}>{label}</InputLabel>}
      <SelectStyle
        variant="filled"
        labelId={id}
        value={value}
        placeholder={placeholder}
        label={label}
        onChange={handleChange}
      >
        {
          printValues()
        }
      </SelectStyle>
    </Cont>
  )
}

export { Select };
