import { styled } from '@mui/system';
import cx from 'classnames';
import { Link } from 'react-router-dom';

import styles from './card.module.scss';

export default function SettingCard({ bg, icon, label, link = '/' }) {

  const Content = styled(Link)(({ theme }) => `
    background-color: ${bg || theme.palette.success.main};
    border-radius: ${theme.shape.borderRadius}px;
    color: ${theme.palette.primary.contrastText};
  `)

  const LabelSpan = styled('span')(({ theme }) => `
    text-align: center;
    font-size: 14px;
    font-weight: 600;
  `)

  return (
    <div className={`${styles.card}`}>
      <Content to={link} className={cx(styles.inner, 'center')}>
        <img className={cx(styles.icon, 'mb-24')} src={icon} />
        <LabelSpan>{label}</LabelSpan>
      </Content>
    </div>
  );
}
