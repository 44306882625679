import React, { forwardRef, useState, useRef, useImperativeHandle, useEffect } from 'react';
import * as Yup from 'yup';
import { Formik, Form, FieldArray } from 'formik';
import { MenuItem, Select, useMediaQuery } from '@mui/material';
import ErrorContainer from '../../../utils/error';
import styles from './processList.module.scss';
import { SimpleInput, Button, ButtonGroup } from '@tt/fe-common';
import cx from 'classnames';
import { useDispatch } from 'react-redux';
import { setBottomSheetComponent } from 'store/reducer/layout';
import { useAddProcessMutation, processAPI, useUpdateProcessMutation } from '../../../store/reducer/services/process';
import DeleteIcon from '@mui/icons-material/Delete';
import { addNewProcess } from 'store/reducer/createProduct';
function NewProcess({ setProcessList, setProcessInvolved, fromLayout, setCreateNewProcess,
  isEdit, processToBeEdited
}, ref) {
  const dispatch = useDispatch();
  const [addProcess, { isLoading }] = useAddProcessMutation();
  const [updateProcess] = useUpdateProcessMutation();
  const isMobile = useMediaQuery('(max-width:576px)');
  const formRef = useRef();
  const ProcessSchema = Yup.object().shape({
    processName: Yup.string()
      .min(2, 'Process name is Too Short!')
      .max(40, 'Process name is Too Long!')
      .required('Process name is required'),
    timeSpan: Yup.number()
      .min(0.5, "Process timespan should be greater than 0.5")
      .required('Enter a valid time')
      .typeError('Please enter a number'),
    unit: Yup.string().oneOf(['hours', 'days', 'weeks']),
  });

  const createProcess = async () => {
    {
      formRef.current.validateForm();
      if (formRef.current.isValid) {
        let metaInformation = formRef.current.values.metaInformation.map(info => {
          let keys = Object.values(info);
          if (keys[0].length && keys[1].length) {
            return {
              [keys[0]]: keys[1]
            }
          }
        }).filter(info => {
          if (info) return info;
        });
        const payload = { ...formRef.current.values };
        if (metaInformation[0]) {
          payload.metaInformation = metaInformation;
        } else {
          delete payload.metaInformation;
        }
        try {
          if (!isEdit) {
            const response = await addProcess(payload).unwrap();
            if (!fromLayout) {
              setProcessList((prev) => [response, ...prev]);
              dispatch(addNewProcess([response]));
              dispatch(
                processAPI.util.updateQueryData('getAllProcess', undefined, (draftPosts) => {
                  draftPosts.push(response);
                })
              );
            } else {
              if (!isMobile)
                setCreateNewProcess(false);
              else
                dispatch(setBottomSheetComponent())
            }
          } else {
            const id = processToBeEdited?.id;
            await updateProcess({ id, payload }).unwrap();
            if (!isMobile)
              setCreateNewProcess(false);
            else
              dispatch(setBottomSheetComponent())
          }
        } catch (e) {
          console.log("ERROR IN CREATNG PROCESS", e);
          return false;
        }
      }
    }
  }

  useImperativeHandle(ref, () => ({
    createProcess
  }), []);

  const metaInformation = {
    property: '',
    value: [],
  };

  const getMetaInformation = () => {
    if (processToBeEdited?.metaInformation?.length) {
      const metaList = processToBeEdited?.metaInformation.map((meta) => {
        const [key] = Object.keys(meta);
        return {
          property: key,
          value: meta[key]
        }
      });
      return metaList;
    }
    else {
      return [{ metaInformation }]
    }
  }

  const initialValues = {
    processName: processToBeEdited?.processName ? processToBeEdited?.processName : '',
    timeSpan: processToBeEdited?.timeSpan ? processToBeEdited?.timeSpan : '',
    unit: processToBeEdited?.unit ? processToBeEdited?.unit : 'hours',
    metaInformation: getMetaInformation()
  }
  return (
    <div className={styles.container}>
      {fromLayout && <h3 className="mb-8">{`${!isEdit ? 'Create New' : 'Edit'}`} Process</h3>}
      <Formik
        initialValues={initialValues}
        validationSchema={ProcessSchema}
        innerRef={formRef}
      >
        {({ errors, touched, values, handleChange, handleBlur }) => (
          <Form className={`${fromLayout} ? ${styles.formContainer} : ""`}>
            <SimpleInput size="full-width"
              label="Process name"
              name="processName"
              value={values.processName}
              handleChange={handleChange('processName')}
              width="full"
              onBlur={handleBlur}
              className="mb-16"
            />
            {errors.processName && touched.processName ? (
              <ErrorContainer>{errors.processName}</ErrorContainer>
            ) : null}
            <SimpleInput size="full-width"
              label="Time to complete (hrs)"
              name="timeSpan"
              value={values.timeSpan}
              handleChange={handleChange('timeSpan')}
              width="full"
              onBlur={handleBlur}
              className="mb-16"
              type="number"
            >
              <Select
                labelId="segment-input"
                id="unit"
                name="unit"
              >
                <MenuItem value={'hours'}>hours</MenuItem>
                <MenuItem value={'days'}>days</MenuItem>
                <MenuItem value={'weeks'}>weeks</MenuItem>
              </Select>
            </SimpleInput>
            {errors.timeSpan && touched.timeSpan ? (
              <ErrorContainer>{errors.timeSpan}</ErrorContainer>
            ) : null}
            <FieldArray name="metaInformation"
              render={arrayHelpers => (
                <div className="mb-8">
                  {
                    values.metaInformation.map((metaInfo, index) => {
                      return (
                        <div className={styles.metainfo} key={index}>
                          <div className={styles.dflex}>
                            <SimpleInput
                              label="Property"
                              name="property"
                              value={metaInfo.property}
                              handleChange={handleChange(`metaInformation.${index}.property`)}
                              width="full"
                              placeholder="Eg Name"
                              onBlur={handleBlur}
                              className={cx(styles.property, 'mb-16')}
                            />
                            <DeleteIcon color="info" onClick={() => {
                              if (values.metaInformation.length === 1) {
                                arrayHelpers.replace(index, {
                                  property: '',
                                  value: '',
                                })
                              } else {
                                arrayHelpers.remove(index)
                              }
                            }} />
                          </div>
                          <SimpleInput
                            label="value"
                            name="value"
                            value={metaInfo.value}
                            handleChange={handleChange(`metaInformation.${index}.value`)}
                            width="full"
                            placeholder="Eg John"
                            onBlur={handleBlur}
                            className={cx('mb-16')}
                          />
                        </div>
                      )
                    })
                  }
                  <Button
                    onClick={() => arrayHelpers.push({ property: '', value: '' })}>
                    Add Information
                  </Button>
                </div>
              )}
            >
            </FieldArray>
          </Form>
        )}
      </Formik>
      {fromLayout && <ButtonGroup
        classes={styles.footer}
        primaryCta={isEdit ? "Save" : "Create"}
        secondaryCta="Cancel"
        primaryFn={() => { createProcess() }}
        secondaryFn={() => {
          if (!isMobile)
            setCreateNewProcess(false);
          else
            dispatch(setBottomSheetComponent())
        }} />}
    </div>
  )
}

export default forwardRef(NewProcess);