import React from 'react';
import { useState, useEffect } from 'react';
import { useGetCustomersQuery } from '../../store/reducer/services/customer';
import { useGetProductsQuery } from '../../store/reducer/services/product';
import { useGetEmployeesListQuery } from 'store/reducer/services/employee';
import KfSearchV2 from '../../components/commom/kfSearchV2';

export default function SearchComponent({ setSearchObject }) {
  const { data: customersList, isLoading } = useGetCustomersQuery();
  const { data: productsList } = useGetProductsQuery();
  const { data: employeeList, isSuccess } = useGetEmployeesListQuery();
  const searchFields = [
    {
      key: 'productName',
      name: 'Product Name',
      type: 'string',
    },
    {
      key: 'customerName',
      name: 'Customer Name',
      type: 'string',
    },
    {
      key: 'percentageCompleted',
      name: 'Precentage Completed',
      type: 'number',
    },
    {
      key: 'assignedTo.employeeId',
      name: 'Assigned To',
      type: 'multiSelect',
      displayName: "name"
    }
  ];

  const operators = ['EQUALS', 'NOT EQUALS', 'CONTAINS'];
  const numericOperator = ['LESSER', 'GREATER', 'EQUALS'];

  const searchOperators = [
    {
      key: 'productName',
      searchOperator: [...operators],
    },
    {
      key: 'customerName',
      searchOperator: [...operators],
    },
    {
      key: 'percentageCompleted',
      searchOperator: [...numericOperator],
    },
    {
      key: 'assignedTo.employeeId',
      searchOperator: ['IN']
    }
  ];

  const [searchValues, setSearchValues] = useState([
    {
      key: 'productName',
      values: [],
    },
    {
      key: 'customerName',
      values: [],
    },
    {
      key: 'percentageCompleted',
      values: [10, 25, 50, 75, 100],
    },
    {
      key: 'assignedTo.employeeId',
      values: []
    }
  ]);
  useEffect(() => {
    const productItems = productsList?.map((item) => item.productName);
    searchValues[0].values = productItems;
    setSearchValues(searchValues);
  }, [productsList]);
  useEffect(() => {
    const customerNames = customersList?.map((item) => item.customerName);
    searchValues[1].values = customerNames;
    setSearchValues(searchValues);
  }, [customersList]);
  useEffect(() => {
    if (employeeList?.length) {
      const agents = employeeList?.map((item) => {
        return {
          employeeId: item.id,
          name: item.name
        }
      });
      searchValues[3].values = agents;
      setSearchValues(searchValues);
    }
  }, [employeeList]);
  return (
    <div style={{ marginBottom: 30 }}>
      <KfSearchV2 searchFields={searchFields} searchOperators={searchOperators}
        setSearchObject={setSearchObject} searchValues={searchValues} />
    </div>
  );
}
