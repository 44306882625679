import React, { useState, useEffect } from 'react';
import { Button } from '@tt/fe-common';
import useRazorpay from "react-razorpay";
import { useCreateOrderMutation } from '../../../store/reducer/services/payment';
import { setHeader } from 'store/reducer/layout';
import { useDispatch } from 'react-redux';

export default function Payment() {
  const dispatch = useDispatch();
  const Razorpay = useRazorpay();
  useEffect(() => {
    dispatch(setHeader('Billing'));
  });
  const [createOrder] = useCreateOrderMutation();
  const makePayment = async () => {
    const order = await createOrder({ amount: 100 }).unwrap();
    const options = {
      key: "rzp_test_QdxtjsjAKApTuQ",
      amount: order.amount,
      currency: "INR",
      name: "Acme Corp",
      description: "Test Transaction",
      order_id: order.id,
      handler: (res) => {
        console.log(res);
      },
      prefill: {
        name: "Goutham Palani",
        email: "gouthamproject17@gmail.com",
        contact: "+918939600806",
      },
      notes: {
        address: "Test address",
      },
      theme: {
        color: "#3399cc",
      },
    };
    const rzpay = new Razorpay(options);
    rzpay.open();
  }
  return <div>
    <Button
      variant="contained"
      color="info"
      size="exact"
      onClick={makePayment}
    >PAY 100 </Button>
  </div>

}