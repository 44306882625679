import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../axiosUtility';

export const customerAPI = createApi({
  reducerPath: 'customer',
  baseQuery: axiosBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_HOST}/api/customer`,
  }),
  tagTypes: ['Customer'],
  endpoints: (build) => ({
    getCustomers: build.query({
      query: () => ({ url: '/list', method: 'GET' }),
      providesTags: (result, error, arg) =>
        result
          ? [...result.map(({ id }) => ({ type: 'Customer', id })), 'Customer']
          : ['Customer'],
    }),
    addCustomer: build.mutation({
      query: (body) => ({
        url: `/create`,
        method: 'POST',
        data: body,
      }),
      invalidatesTags: ['Customer'],
    }),
    updateCustomer: build.mutation({
      query: ({ id, ...body }) => ({
        url: `/${id}`,
        method: 'PUT',
        data: body['payload'],
      }),
      invalidatesTags: ['Customer'],
    }),
    deleteCustomer: build.mutation({
      query: ({ id }) => ({
        url: `/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Customer'],
    }),
    getCustomer: build.query({
      query: ({ id }) => ({
        url: `/${id}`,
        method: 'GET',
      }),
      providesTags: ['Customer'],
    }),
  }),
});

export const {
  useAddCustomerMutation,
  useGetCustomersQuery,
  useDeleteCustomerMutation,
} = customerAPI;
