import React, { useRef } from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { useDispatch } from 'react-redux';
import { SimpleInput, TextArea, ButtonGroup } from '@tt/fe-common';
import styles from './new.module.scss';
import ErrorContainer from 'utils/error';
import { useAddCustomerMutation } from 'store/reducer/services/customer';
import { setBottomSheetComponent } from 'store/reducer/layout';
import backIcon from 'assets/icons/light/back.svg';
import { useMediaQuery } from '@mui/material';

export default function AddCustomer({ setCreateNewCustomer, toggleOpenCreateCustomer, setAddedCustomers, fromLayout }) {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery('(max-width:576px)');
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  const CustomerSchema = Yup.object().shape({
    customerName: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
    address: Yup.string()
      .min(10, 'Too Short!')
      .max(35, 'Too Long!')
      .required('Required'),
    phoneNumber: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
    email: Yup.string().email('Must be a valid email').max(255).required('Email is required')
  });
  const formRef = useRef();
  const initialValues = () => {
    return {
      customerName: '',
      address: '',
      phoneNumber: '',
      email: '',
    }
  }

  const [addCustomer, { isLoading }] = useAddCustomerMutation();

  const createCustomer = async (values) => {
    const customer = await addCustomer(values).unwrap();
    if (!fromLayout) {
      setAddedCustomers([customer]);
      formRef.current.resetForm();
      toggleOpenCreateCustomer(false);
    }
    else {
      if (!isMobile)
        setCreateNewCustomer(false);
      else
        dispatch(setBottomSheetComponent())
    }

  }
  const cancel = () => {
    if (!fromLayout) {
      dispatch(setBottomSheetComponent());
      toggleOpenCreateCustomer(false);
    } else {
      if (!isMobile)
        setCreateNewCustomer(false);
      else
        dispatch(setBottomSheetComponent())
    }
  }
  return (
    <>
      <div className={styles.header}>
        <div className={styles.imgContainer}>
          <img src={backIcon} alt="Back" onClick={() => {
            dispatch(setBottomSheetComponent())
            toggleOpenCreateCustomer(false)
          }}
            className="cursor-pointer"
          />
        </div>
        <div className={styles.addHeadingContainer}>
          <span className={styles.addHeading}>Add Customer</span>
        </div>
      </div>
      <div className={styles.container}>
        <Formik className={styles.form}
          initialValues={initialValues()}
          validationSchema={CustomerSchema}
          innerRef={formRef}
          onSubmit={createCustomer}

        >
          {({ errors, touched, values, handleChange, handleBlur }) => (
            <Form className={styles.form}>
              <SimpleInput size="full-width"
                label="Customer name"
                handleChange={handleChange('customerName')}
                width="full"
                onBlur={handleBlur}
                className="mt-16"
              />
              {errors.customerName && touched.customerName ? (
                <ErrorContainer>{errors.customerName}</ErrorContainer>
              ) : null}
              <TextArea size="full-width" label="Address"
                handleChange={handleChange('address')}
                width="full"
                rows={7}
                value={values.address}
                onBlur={handleBlur}
                className="mt-16"
              >
              </TextArea>
              {errors.address && touched.address ? (
                <ErrorContainer>{errors.address}</ErrorContainer>
              ) : null}

              <SimpleInput size="full-width"
                label="Phone Number"
                handleChange={handleChange('phoneNumber')}
                width="full"
                onBlur={handleBlur}
                className="mt-16"
              />
              {errors.phoneNumber && touched.phoneNumber ? (
                <ErrorContainer>{errors.phoneNumber}</ErrorContainer>
              ) : null}
              <SimpleInput size="full-width"
                label="Email"
                handleChange={handleChange('email')}
                width="full"
                onBlur={handleBlur}
                className="mt-16"
              />
              {errors.email && touched.email ? (
                <ErrorContainer>{errors.email}</ErrorContainer>
              ) : null}
            </Form>
          )}
        </Formik>
        <ButtonGroup primaryCta="create"
          secondaryCta="Cancel"
          primaryFn={() => formRef.current.handleSubmit()}
          secondaryFn={cancel} />
      </div>
    </>

  )
}
