import React, { useEffect } from 'react';
import styles from './orders.module.scss';
import "../../pages/layout/output.css";
import { Card } from '@mui/material';
import { useLazyGetOrderQuery } from 'store/reducer/services/order';
import { useLazyGetProcessQuery } from "store/reducer/services/process";
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { useParams } from 'react-router-dom';
import moment from 'moment-timezone';
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import CircleOutlinedIcon from "@mui/icons-material/Circle"
import { useMediaQuery } from '@mui/material';
import Chip from "@mui/material/Chip";

export const ProcessStatus = Object.freeze({
  COMPLETED: "COMPLETED"
});

export const ProcessStatusNumbered = Object.freeze({
  YET_TO_START: 0,
  IN_PROGRESS: 1,
  ON_HOLD: 2,
  COMPLETED: 3
});

export const buildProcessStatusText = (status) => {
  let statusText;
  switch (status) {
    case ProcessStatus.COMPLETED:
    case ProcessStatusNumbered.COMPLETED:
      statusText = "Completed"
      break;
    case ProcessStatusNumbered.IN_PROGRESS:
      statusText = "Ongoing";
      break;
    case ProcessStatusNumbered.YET_TO_START:
      statusText = "Not picked up yet";
      break;
    case ProcessStatusNumbered.ON_HOLD:
      statusText = "On hold";
      break;
    default:
      statusText = status;
  }
  return <span className="block mb-2 text-xs text-gray-400">{statusText}</span>;
}

export const ProcessDescription = Object.freeze({
  DELAYED: "Delayed",
  ONTIME: "On time",
  OVERDUE: "Overdue",
})

export const buildProcessDescriptionChip = (processDescription) => {
  let color;
  switch (processDescription) {
    case ProcessDescription.DELAYED:
    case ProcessDescription.OVERDUE:
      color = "error";
      break;
    case ProcessDescription.ONTIME:
      color = "success";
      break;
    default:
      color = "info"
  }
  return <Chip label={processDescription} color={color} variant={"contained"} />
}

export const identifyProcessDesc = (isCompleted, isDelayed) => {
  if (isCompleted && isDelayed) return ProcessDescription.DELAYED;
  if (isCompleted && !isDelayed) return ProcessDescription.ONTIME;
  if (!isCompleted && isDelayed) return ProcessDescription.OVERDUE;
  return null;
}

const ProcessDateFormat = {
  DATE: "MMM DD, YYYY",
  TIME: "hh:mm A"
}

const TimeLineContentTemplate = ({ children, isLastProcess = false, isCompleted = false, }) => {
  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineDot color='primary' sx={{
          boxShadow: "none",

        }} >
          {(isCompleted && <CheckCircleIcon color='success' />) || <CircleOutlinedIcon color='action' />}
        </TimelineDot>
        {!isLastProcess && <TimelineConnector sx={{
          backgroundColor: (isCompleted && "green") || "gray"
        }} />}
      </TimelineSeparator>
      <TimelineContent>{children}</TimelineContent>
    </TimelineItem>
  )
}

const ProcessDateTime = ({ date, time }) => {
  return (
    <div>
      <span className={"font-semibold text-base"}>{date}</span>
      <time className="block mb-2 mt-2 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">{time}</time>
    </div>
  )
}

const ProcessCard = ({ processTitle, span, metaInfo, isMobile = false }) => {
  return (
    <Card variant="kfCard" sx={{
      margin: (isMobile && "1px") || "-1rem 2rem 2rem -7rem",
      maxWidth: "20rem"
    }}>
      <div className={styles.cardContainer}>
        <div className={styles.cardHeader}>
          <div className={styles.left}>
            <span className={"font-semibold text-base"}>{processTitle}</span>
          </div>
          <Chip label={span} color="success" variant="outlined" />
        </div>
        <div className={styles.cardBody}>
          <div className={styles.cards}>
            {metaInfo && metaInfo?.length > 0 && metaInfo?.map((value) => {
              return <span className={styles.value}>{Object.values(value)?.[0]}</span>
            })}
          </div>
        </div>
      </div>
    </Card>
  )
}

const ProcessStatusCust = ({ processDesc, processStatus }) => {
  return (
    <div>
      <span className="block mb-2 text-xs text-gray-400">{buildProcessStatusText(processStatus)}</span>
      {processDesc && buildProcessDescriptionChip(processDesc)}
    </div>
  )
}

const Process = ({ process, isMobile = false, isSignedIn = false }) => {
  const [getProcess, { data }] = useLazyGetProcessQuery()
  useEffect(() => {
    if (process?.id) {
      getProcess({ id: process?.id })
    }
  }, [getProcess, process?.id])

  return (
    <>
      <div className={`grid sm:grid-cols-1 md:grid-cols-3 xs:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 gap-1 w-${(isMobile && "full") || "9/12"}`}>
        <ProcessDateTime
          date={moment(process?.endTime)?.format(ProcessDateFormat.DATE)}
          time={moment(process?.endTime)?.format(ProcessDateFormat.TIME)} />
        <ProcessCard
          processTitle={process?.processName}
          span={`${process?.timeSpan} ${process?.unit}`}
          metaInfo={data?.metaInformation}
          isMobile={isMobile}
        />
        {isSignedIn && <ProcessStatusCust processDesc={identifyProcessDesc(process?.isCompleted, process?.isDelayed)} processStatus={process?.status} />}
      </div>
    </>
  )
}

export const OrderTimeline = ({ isSignedIn = false, data }) => {
  const isMobile = useMediaQuery('(max-width:1200px)');

  return (
    <>
      {(data && <Timeline sx={{
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0,
        },
      }} >
        {data?.processList && [...data?.processList]?.reverse()?.map((process, i) => {
          return (
            <TimeLineContentTemplate
            key={i}
              isLastProcess={i + 1 === data?.processList?.length}
              isCompleted={process?.isCompleted === true || process?.status === ProcessStatus.COMPLETED || process?.status === ProcessStatusNumbered.COMPLETED}
            >
              <Process process={process} isMobile={isMobile} isSignedIn={isSignedIn} />
            </TimeLineContentTemplate>
          );
        })}
      </Timeline>) || <></>}
    </>
  )
}


const Track = () => {
  const { orderId } = useParams();
  const [getOrder, { data }] = useLazyGetOrderQuery();

  useEffect(() => {
    if (orderId) {
      getOrder({ id: orderId });
    }
  }, [getOrder, orderId])

  return (
    <>
      <OrderTimeline isSignedIn={true} data={data} />
    </>
  )
}

export default Track
